import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as InfoActions from '../actions/info.actions';
import { GetContactUSUseCase } from '../../application/use-cases/get-contact-us.use-case';
import { GetAgenceUseCase } from '../../application/use-cases/get-agences.use-case';
import { CreateFeedbackUseCase } from '../../application/use-cases/create-feedback.use-case';

@Injectable()
export class InfoEffects {
	loadContactUs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InfoActions.loadContactUs),
			switchMap(() =>
				this.getContactUSUseCase.execute().pipe(
					map((contactUs) => {
						console.log('contactUs: ', contactUs);
						return InfoActions.loadContactUsSuccess({
							contactUs,
						});
					}),
					catchError((error) => {
						console.log('error: ', error);
						return of(
							InfoActions.loadContactUsFailure({
								error,
							})
						);
					})
				)
			)
		)
	);

	loadAgence$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InfoActions.loadAgence),
			switchMap(({ data }) =>
				this.getAgenceUseCase.execute(data).pipe(
					map((agences) => {
						return InfoActions.loadAgenceSuccess({
							agences,
						});
					}),
					catchError((error) => {
						return of(
							InfoActions.loadAgenceFailure({
								error,
							})
						);
					})
				)
			)
		)
	);
	createFeedback$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InfoActions.createFeedback),
			switchMap(({ data }) =>
				this.createFeedbackUseCase.execute(data).pipe(
					map((feedback) => {
						return InfoActions.createFeedbackSuccess({
							feedback,
						});
					}),
					catchError((error) => {
						return of(
							InfoActions.createFeedbackFailure({
								error,
							})
						);
					})
				)
			)
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly getContactUSUseCase: GetContactUSUseCase,
		private readonly getAgenceUseCase: GetAgenceUseCase,
		private readonly createFeedbackUseCase: CreateFeedbackUseCase
	) {}
}
