import { Observable } from 'rxjs';
import { ITermsConditionRepository } from '../../domain/repositories/terms-condition.repository';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetPostLoginMapper } from '../../application/mappers/get-post-login.mapper';
import {
	CustomerTNCRequestPDO,
	GetPostLoginRequestPDO,
	GetPostLoginResponsePDO,
} from '../../application/dtos/terms-condition.dto';
import { TermsConditionService } from '../../../core-banking/application/services/terms-condition/terms-condition.service';
import { AlreadySignedMapper } from '../../application/mappers/already-signed.mapper';
import { TermsAndConditionMapper } from '../../application/mappers/terms-and-condition.mapper';
import { AlreadySigned } from '../../domain/entities/already-signed.entity';
import { TermsAndCondition } from '../../domain/entities/terms-and-condition.entity';

@Injectable({
	providedIn: 'root',
})
export class TermsConditionRepositoryImpl implements ITermsConditionRepository {
	constructor(private readonly termsConditionService: TermsConditionService) {}

	getPostLogin(
		request: GetPostLoginRequestPDO
	): Observable<GetPostLoginResponsePDO> {
		return this.termsConditionService.getPostLogin(request).pipe(
			map((response) => {
				return GetPostLoginMapper.toDomain(response);
			})
		);
	}

	createCustomerTNCForLogin(
		request: CustomerTNCRequestPDO
	): Observable<AlreadySigned | TermsAndCondition[]> {
		return this.termsConditionService.createCustomerTNCForLogin(request).pipe(
			map((response) => {
				if ('customertermsandconditions' in response) {
					return response['customertermsandconditions'].map((item) =>
						TermsAndConditionMapper.toDomain(item)
					);
				}
				return AlreadySignedMapper.toDomain(response);
			})
		);
	}
}
