<aside
	[ngClass]="{ '-translate-x-full': !visible, 'translate-x-0': visible }"
	class="fixed top-0 left-0 z-40 h-screen transition-transform bg-white py-3 flex flex-col shadow-lg sm:px-5"
	appClickOutside
	(clickOutside)="onClickOutside()"
>
	<img
		routerLink="/accueil"
		src="assets/images/LOGO-BBGCI-NOIR.svg"
		alt="Bridge Bank Logo"
		class="h-auto w-full cursor-pointer"
	/>
	<nav class="flex-grow overflow-y-auto mt-5">
		<ul class="divide-y divide-dashed">
			<li
				*ngFor="let menuItem of menuItems; let i = index"
				[ngClass]="{
					'bg-gray-100 text-primary': activeLink === menuItem.link,
					'hover:bg-gray-100': activeLink !== menuItem.link
				}"
				class="mx-2 px-3 py-2 border-bottom-2"
				(click)="menuItem.link ? setActiveLink(menuItem.link) : openModal()"
				[tabindex]="0"
				(keydown)="menuItem.link && handleKeyDown($event, menuItem.link)"
			>
				<a
					[routerLink]="
						menuItem.name !== 'SIDEBAR.LANGUAGE' ? menuItem.link : null
					"
					class="flex items-center justify-between"
				>
					<div class="flex items-center">
						<button>
							<ng-icon
								[name]="menuItem.icon"
								size="1.3rem"
								class="mr-5 p-0 m-0"
								[ngClass]="{
									'icon-orange': activeLink === menuItem.link
								}"
							></ng-icon>
						</button>
						<span>{{ menuItem.name | translate }}</span>
					</div>
					<span
						*ngIf="menuItem.name === 'SIDEBAR.NOTIFICATION' && numberNotifications > 0"
						class="left-8 w-8 h-8 bg-primary border-2 border-white rounded-full flex items-center justify-center text-white text-xs"
					>
						{{ numberNotifications}}
					</span>
					<span
						*ngIf="menuItem.name === 'SIDEBAR.MESSAGE' && numberDiscussions > 0"
						class="left-8 w-8 h-8 bg-primary border-2 border-white rounded-full flex items-center justify-center text-white text-xs"
					>
						{{ numberDiscussions}}
					</span>
					<span
						*ngIf="menuItem.name === 'SIDEBAR.LANGUAGE'"
						class="left-8"
					>
						<select
							(change)="setLanguage($event)"
							class="b-none bg-none focus:outline-none"
						>
							<option
								*ngFor="let lang of languages"
								[selected]="lang.value == currentLang"
								[value]="lang.value"
								class="bg-white hover:bg-primary!important"
							>
								{{ lang.label | translate }}
							</option>
						</select>
					</span>
				</a>
			</li>
			<li
				class="flex items-center hover:bg-gray-100 click:text-primary mx-2 px-3 py-2"
			>
				<button (click)="openLogoutModal()">
					<ng-icon
						name="heroArrowRightStartOnRectangle"
						size="1.3rem"
						class="mr-5 p-0 m-0"
					></ng-icon>
					<span>{{ 'SIDEBAR.DISCONNECT' | translate }}</span>
				</button>
			</li>
		</ul>
	</nav>
	<div class="p-4 flex items-center justify-center gap-6 px-6 pb-4">
		<span class="font-normal uppercase text-sm">{{
			user?.firstName + ' ' + user?.lastName
		}}</span>
		<img
			class="w-10 h-10 rounded-full"
			[src]="userProfileImage || 'assets/images/user-picture.svg'"
			alt="Rounded avatar"
		/>
	</div>
</aside>
<div *ngIf="loading$ | async" class="flex justify-center my-4">
	<app-spinner-modal [show]="true"></app-spinner-modal>
</div>

<app-logout
	*ngIf="isLogoutModalOpen"
	[isModalOpen]="isLogoutModalOpen"
	(onClose)="closeLogoutModal()"
></app-logout>

<app-modal
	[isOpen]="isModalOpen"
	[title]="'Modal par défaut'"
	(close)="closeModal()"
>
	<ng-template #modalHeaderTitle>
		<h2 class="text-md font-normal">
			{{ 'RATING.TITLE' | translate }}
		</h2>
	</ng-template>

	<ng-template #modalHeaderActions>
		<button (click)="closeModal()" class="text-gray-600 hover:text-gray-800">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M6 18L18 6M6 6l12 12"
				/>
			</svg>
		</button>
	</ng-template>

	<form *ngIf="sendForm" [formGroup]="createFeedbackFormGroup">
		<div class="flex items-center">
			<app-star-rating
				id="rating"
				name="rating"
				formControlName="rating"
				(ratingChange)="setRating($event)"
				[(ngModel)]="userRating"
			></app-star-rating>
		</div>
		<app-textarea
			id="description"
			name="description"
			placeholder="{{ 'MESSAGES.CREATE_MESSAGE.DESCRIPTION' | translate }}"
			formControlName="description" 
			[rows]="4"
			[maxLength]="500"
			[autoResize]="false"
			variant="outlined"
			[showCharCount]="true"
			[disabled]="false"
		></app-textarea>
	</form>

	<div *ngIf="successForm">{{ 'RATING.THANKS' | translate }}</div>
	<div *ngIf="errorForm">{{ 'RATING.ERRORS' | translate }}</div>

	<ng-template #modalFooter>
		<div class="flex justify-between">
			<app-button
				label="{{ 'BUTTONS.CLOSE' | translate }}"
				(onClick)="closeModal()"
				severity="secondary"
			></app-button>
			<app-button
				*ngIf="sendForm"
				label="{{ 'BUTTONS.SUBMIT' | translate }}"
				type="primary"
				(click)="createFeedback()"
				(keyDown)="createFeedback()"
				[loading]="isLoading"
				[disabled]="createFeedbackFormGroup.invalid || isLoading"
			></app-button>
		</div>
	</ng-template>
</app-modal>
