import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { CoreBankingError } from '../../../domain/errors/core-banking-error';
import { LegalEntityListResponseDTO } from '../../dtos/company/legal-entity.dto';
import { ICompanyService } from '../../../domain/interfaces/company-service.interface';
import { CookieService } from 'ngx-cookie-service';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';

@Injectable({
	providedIn: 'root',
})
export class CompanyService implements ICompanyService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly cookieService: CookieService
	) {}

	getLegalEntities(): Observable<LegalEntityListResponseDTO> {
		return this.api
			.postWithBasicAuth<LegalEntityListResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.LEGAL_ENTITIES.GET_LEGAL_ENTITIES
			)
			.pipe(
				map((response) => {
					if (response.opstatus === 200 || response.opstatus === 0) {
						return response;
					} else {
						throw new CoreBankingError(
							'GET_LEGAL_ENTITIES_FAILED',
							'Failed to get legal entities',
							0,
							500,
							response
						);
					}
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_LEGAL_ENTITIES_FAILED',
						'Failed to get legal entities'
					)
				)
			);
	}

	updateCurrentLegalEntity(
		authToken?: string,
		legalEntityId?: string
	): Observable<boolean> {
		const currentLegalEntityId =
			this.cookieService.get('legal_entity') || legalEntityId;
		const currentAuthToken =
			this.cookieService.get('auth_token') || authToken;
		return this.api
			.postKony<any>(
				CORE_BANKING_API_ENDPOINTS.LEGAL_ENTITIES
					.UPDATE_CURRENT_LEGAL_ENTITIES,
				{ currentLegalEntityId: currentLegalEntityId },
				currentAuthToken
			)
			.pipe(
				map((response) => {
					if (response.opstatus === 200 || response.opstatus === 0) {
						return true;
					} else {
						throw new CoreBankingError(
							'UPDATE_CURRENT_LEGAL_ENTITY_FAILED',
							'Failed to update current legal entity',
							0,
							500,
							response
						);
					}
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'UPDATE_CURRENT_LEGAL_ENTITY_FAILED',
						'Failed to update current legal entity'
					)
				)
			);
	}
}
