<footer class="bg-secondary text-white py-4">
	<div class="container mx-auto px-4 py-2">
		<!-- Ligne 1 : Logo -->
		<div class="grid grid-cols-1 mb-6">
			<div class="flex justify-center md:justify-start">
				<a href="/home">
                    <img
                        src="assets/images/LOGO-BBGCI-BLANC.svg"
                        alt="Bridge Bank Logo"
                        class="h-24 w-auto"
                    />
                </a>
			</div>
		</div>

		<!-- Ligne 2 : Liens de navigation -->
		<div class="grid grid-cols-1 mb-6">
			<div
				class="flex flex-wrap justify-center md:justify-start md:space-y-0 md:space-x-10 space-x-10 text-center md:text-left"
			>
				<a href="#" class="hover:text-primary text-xs font-light">
					{{ 'FOOTER.YOUR_BANK' | translate }}
				</a>
				<a href="#" class="hover:text-primary text-xs font-light">
					{{ 'FOOTER.YOUR_BRANCHES' | translate }}
				</a>
				<a href="#" class="hover:text-primary text-xs font-light">
					{{ 'FOOTER.YOUR_PRODUCTS' | translate }}
				</a>
				<a href="#" class="hover:text-primary text-xs font-light">
					{{ 'FOOTER.ACTIVITY_REPORT' | translate }}
				</a>
				<a href="#" class="hover:text-primary text-xs font-light">
					{{ 'FOOTER.CONTACT_US' | translate }}
				</a>
			</div>
		</div>

		<!-- Ligne 3 : Liens légaux et copyright -->
		<div class="grid grid-cols-1 mb-6">
			<div
				class="flex flex-col md:flex-row justify-between items-center text-center md:text-left"
			>
				<!-- Section à gauche : Liens légaux et copyright -->
				<div class="mb-4 md:mb-0">
					<div class="flex flex-col md:flex-row md:space-x-4">
						<p class="text-xs">
							&copy; {{ currentYear }} myBridge Touch.
							{{ 'FOOTER.COPYRIGHT' | translate }}
						</p>
						<a href="/privacy-policy" class="hover:text-primary text-xs font-light"
							>Privacy</a
						>
						<a href="#" class="hover:text-primary text-xs font-light"
							>Terms Of Service</a
						>
						<a href="#" class="hover:text-primary text-xs font-light"
							>Cookies Policy</a
						>
					</div>
				</div>

				<!-- Section à droite : Icônes des stores et des réseaux sociaux -->
				<div class="flex flex-col items-center md:items-end space-y-4">
					<!-- Icônes des stores -->
					<div class="flex justify-center md:justify-start space-x-4">
						<img
							src="assets/images/google-play.svg"
							alt="Google Play Store"
							class="h-10 w-auto"
						/>
						<img
							src="assets/images/apple-store.svg"
							alt="Apple Store"
							class="h-10 w-auto"
						/>
						<img
							src="assets/images/facebook.svg"
							alt="Facebook"
							class="h-6 w-6"
						/>
						<img
							src="assets/images/twitter.svg"
							alt="Twitter"
							class="h-6 w-6"
						/>
						<img
							src="assets/images/linkedin.svg"
							alt="LinkedIn"
							class="h-6 w-6"
						/>
						<img
							src="assets/images/instagram.svg"
							alt="Instagram"
							class="h-6 w-6"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
