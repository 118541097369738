export class PartnerServiceField {
    constructor(
        public idField: number,
        public stepId: number,
        public entityIdService: number,
        public inputType: string,
        public fieldGroup: any[],
        public keyJson: string,
        public valueJson: any,
        public inputLabel: string,
        public fieldNum: number,
        public errorFieldRequiredMessage: any,
        public isReadOnly: boolean,
        public isRequired: boolean,
        public isDisabled: boolean
    ) {}
}