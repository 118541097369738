import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISettingsRepository } from '../../domain/repositories/settings.repository';
import { UserInfosDTO } from '../dtos/user-infos.dto';

@Injectable({
	providedIn: 'root',
})
export class GetUserUseCase {
	constructor(
		@Inject('ISettingsRepository')
		private readonly settingsRepository: ISettingsRepository
	) {}

	execute(): Observable<UserInfosDTO> {
		return this.settingsRepository.getUser();
	}
}
