import { Injectable } from '@angular/core';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { Observable } from 'rxjs';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { catchError, map } from 'rxjs/operators';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { ContactUs } from '../../../../info/domain/entities/contact-us.entity';
import { CoreBankingError } from '../../../domain/errors/core-banking-error';
import { CookieService } from 'ngx-cookie-service';
import {
	AgenceDTO,
	AgenceRequestDTO,
	AgenceResponseDTO,
	CreateFeedbackDTO,
	CreateFeedbackResponseDTO,
	FeedbackResponseDTO,
} from '../../dtos/info/info.dto';

@Injectable({
	providedIn: 'root',
})
export class InfoService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly cookieService: CookieService
	) {}

	getContactUs(): Observable<ContactUs> {
		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';
		return this.api
			.post<ContactUs>(
				`${CORE_BANKING_API_ENDPOINTS.INFO.BASE_URL}${CORE_BANKING_API_ENDPOINTS.INFO.GET_CONTACT_US}`,
				currentLegalEntityId
			)
			.pipe(
				map((response) => {
					console.log('response: ', response);
					if (response && !response.opstatus) {
						return response;
					} else if (response.opstatus) {
						throw new CoreBankingError(
							'CONTACT_US_ERROR',
							'Information invalide',
							0,
							400,
							response
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					console.log('error: ', error);
					return this.errorHandler.handleError(
						error,
						'CONTACT_US_FAILED',
						'Failed to get contact'
					);
				})
			);
	}

	getAgence(data: AgenceRequestDTO): Observable<AgenceDTO[]> {
		return this.api
			.post<AgenceResponseDTO>(
				`${CORE_BANKING_API_ENDPOINTS.INFO.GET_LIST}`,
				data
			)
			.pipe(
				map((response) => response.Locations),
				catchError((error) => {
					return this.errorHandler.handleError(
						error,
						'AGENCE_US_FAILED',
						'Failed to get contact'
					);
				})
			);
	}

	createFeedback(data: CreateFeedbackDTO): Observable<FeedbackResponseDTO[]> {
		return this.api
			.post<CreateFeedbackResponseDTO>(
				`${CORE_BANKING_API_ENDPOINTS.INFO.CREATE_FEEDBACK}`,
				data
			)
			.pipe(
				map((response) => response.feedback),
				catchError((error) => {
					return this.errorHandler.handleError(
						error,
						'CREATE_FEEDBACK_FAILED',
						'Failed to create feedback'
					);
				})
			);
	}
}
