import { Campaign } from "../../domain/entities/campaign.entity";
import { CampaignDTO } from "../dtos/campaign.dto";

export class CampaignMapper {
    static toEntity(dto: CampaignDTO): Campaign {
        return new Campaign(
            dto.placeholderCode,
            dto.campaignId,
            dto.campaignPlaceholderId,
            dto.imageURL,
            dto.destinationURL,
            dto.imageIndex
        )
    }
}