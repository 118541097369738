import {
	Directive,
	EventEmitter,
	HostListener,
	Output,
	ElementRef,
} from '@angular/core';

@Directive({
	selector: '[appClickOutside]',
	standalone: true,
})
export class ClickOutsideDirective {
	@Output() clickOutside = new EventEmitter<MouseEvent>();

	constructor(private readonly elementRef: ElementRef) {}

	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.clickOutside.emit(event);
		}
	}
}
