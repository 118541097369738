import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { TransferSicaStandingOrderRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class TransferSicaToExternalAccountStandingOrderUseCase {
	transferService = inject(ITransferRepository);

	execute(request: TransferSicaStandingOrderRequestDTO) {
		return this.transferService.createSicaStandingOrderTransfer(request);
	}
}
