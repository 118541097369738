import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { DashboardCurrencyListRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class FetchDashboardCurrencyListUseCase {
	transferService = inject(ITransferRepository);

	execute(request: DashboardCurrencyListRequestDTO) {
		return this.transferService.fetchDashboardCurrencyList(request);
	}
}
