import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import * as SettingsActions from './settings.actions';
import { GetUserProfileImageUseCase } from '../application/use-cases/get-user-profile-image.use-case';
import { UpdateUserProfileImageUseCase } from '../application/use-cases/update-user-profile-image.use-case';
import { DeleteUserProfileImageUseCase } from '../application/use-cases/delete-user-profile-image.use-case';
import { GetUserInfosUseCase } from '../application/use-cases/get-user-infos.use-case';
import { GetSecurityQuestionsUseCase } from '../application/use-cases/get-security-questions.use-case';
import { RequestUpdateSecurityQuestionsOTPUseCase } from '../application/use-cases/request-update-security-questions-OTP.use-case';
import { VerifyUpdateSecurityQuestionsOTPUseCase } from '../application/use-cases/verify-update-security-questions-OTP.use-case';
import { UpdateSecurityQuestionsUseCase } from '../application/use-cases/update-security-questions.use-case';
import { Logger } from '../../../shared/helpers/logger-helper';
import { UpdateUserStatusUseCase } from '../application/use-cases/update-user-status.use-case';
import { GetUserUseCase } from '../application/use-cases/get-user.use-case';
import { UpdatePasswordUseCase } from "../application/use-cases/update-password.use-case";
import { selectIsPassordUpdated, selectSettingsError } from "./settings.selectors";
import { Store } from "@ngrx/store";

@Injectable()
export class SettingsEffects {

	getUserInfos$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.getUserInfos),
			switchMap(() =>
				this.getUserInfosUseCase.execute().pipe(
					map((userInfos) =>{
                        console.log("userInfos :", userInfos)
                        return SettingsActions.getUserInfosSuccess({ userInfos })
                    }),
					catchError((error) =>
						of(SettingsActions.getUserInfosFailure({ error }))
					)
				)
			)
		)
	);

	getUserProfileImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.getUserProfileImage),
			switchMap(() =>
				this.getUserProfileImageUseCase.execute().pipe(
					map((userProfileImage) =>
						SettingsActions.getUserProfileImageSuccess({
							userProfileImage,
						})
					),
					catchError((error) =>
						of(SettingsActions.getUserProfileImageFailure({ error }))
					)
				)
			)
		)
	);

	updateUserProfileImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.updateUserProfileImage),
			switchMap(({ userProfileImage }) =>
				this.updateUserProfileImageUseCase.execute(userProfileImage).pipe(
					map(() => SettingsActions.updateUserProfileImageSuccess()),
					catchError((error) =>
						of(SettingsActions.updateUserProfileImageFailure({ error }))
					)
				)
			)
		)
	);

	deleteUserProfileImage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.deleteUserProfileImage),
			switchMap(() =>
				this.deleteUserProfileImageUseCase.execute().pipe(
					map(() => SettingsActions.deleteUserProfileImageSuccess()),
					catchError((error) =>
						of(SettingsActions.deleteUserProfileImageFailure({ error }))
					)
				)
			)
		)
	);

	getSecurityQuestions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.getSecurityQuestions),
			switchMap(() =>
				this.getSecurityQuestionsUseCase.execute().pipe(
					map((securityQuestions) =>
						SettingsActions.getSecurityQuestionsSuccess({
							securityQuestions,
						})
					),
					catchError((error) =>
						of(SettingsActions.getSecurityQuestionsFailure({ error }))
					)
				)
			)
		)
	);

	requestUpdateSecurityQuestionsOTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.requestUpdateSecurityQuestionsOTP),
			switchMap(({ request }) =>
				this.requestUpdateSecurityQuestionsOTPUseCase.execute(request).pipe(
					map((mfaAttributes) => {
						// Log the mfaAttributes for debugging
						Logger.info('MFA Attributes:', mfaAttributes);
						return SettingsActions.requestUpdateSecurityQuestionsOTPSuccess(
							{ mfaAttributes }
						);
					}),
					catchError((error) =>
						of(
							SettingsActions.requestUpdateSecurityQuestionsOTPFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	verifyUpdateSecurityQuestionsOTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.verifyUpdateSecurityQuestionsOTP),
			switchMap(({ request }) =>
				this.verifyUpdateSecurityQuestionsOTPUseCase.execute(request).pipe(
					map(({ success, isOtpVerified }) =>
						SettingsActions.verifyUpdateSecurityQuestionsOTPSuccess({
							isOtpVerified,
						})
					),
					catchError((error) =>
						of(
							SettingsActions.verifyUpdateSecurityQuestionsOTPFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	updateSecurityQuestions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.updateSecurityQuestions),
			switchMap(({ request }) =>
				this.updateSecurityQuestionsUseCase.execute(request).pipe(
					map(() => SettingsActions.updateSecurityQuestionsSuccess()),
					catchError((error) =>
						of(SettingsActions.updateSecurityQuestionsFailure({ error }))
					)
				)
			)
		)
	);

	getUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.getUser),
			switchMap(() =>
				this.getUserUseCase.execute().pipe(
					map((user) => SettingsActions.getUserSuccess({ user })),
					catchError((error) =>
						of(SettingsActions.getUserFailure({ error }))
					)
				)
			)
		)
	);

	updateUserStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SettingsActions.updateUserStatus),
			switchMap(({ request }) =>
				this.updateUserStatusUseCase.execute(request).pipe(
					map((reponse) =>
						SettingsActions.updateUserStatusSuccess({ update: reponse })
					),
					catchError((error) =>
						of(SettingsActions.updateUserStatusFailure({ error }))
					)
				)
			)
		)
	);

	updatePassword$ = createEffect(() => 
        this.actions$.pipe(
            ofType(SettingsActions.updatePassword),
            switchMap(({request}) =>
                this.updatePasswordUseCase.execute(request).pipe(
                    map((response) => {
                        console.log("updatePasswordUseCase : ", response)
                        return SettingsActions.updatePasswordSuccess({reponse: response})
                    }),
                    catchError((error) => {
                        return of(SettingsActions.updatePasswordFailure({ error }))}
                    )
                )
            )
        )
    );

    // Effet qui supprime l'erreur apres 5 secondes si elle existe
    // ou si le mot de passe a ete mis a jour avec succes
    clearErrorAfterDelay$ = createEffect(() =>
		this.actions$.pipe(
			// On ecoute les actions de type updatePasswordFailure ou updatePasswordSuccess
			ofType(
				SettingsActions.updatePasswordFailure,
				SettingsActions.updatePasswordSuccess,
			),
			// On attend 5 secondes avant de continuer
			delay(10000),
			// On prend le dernier erreur et le dernier etat de mise a jour du mot de passe
			withLatestFrom(
                this.store.select(selectSettingsError), 
                this.store.select(selectIsPassordUpdated)
            ),
			// Si il y a une erreur ou si le mot de passe a ete mis a jour avec succes
			// On envoie l'action clearSettingsError pour supprimer l'erreur
			mergeMap(([_, error, isPassordUpdated]) => {
				if (error || isPassordUpdated) {
					return of(SettingsActions.clearSettingsError());
				}
				// Si il n'y a pas d'erreur et que le mot de passe n'a pas ete mis a jour
				// On ne fait rien (NO_ACTION)
				return of({ type: 'NO_ACTION' });
			})
		)
	);

	constructor(
		private readonly actions$: Actions,
        private readonly getUserInfosUseCase: GetUserInfosUseCase,
        private readonly getUserProfileImageUseCase: GetUserProfileImageUseCase,
        private readonly updateUserProfileImageUseCase: UpdateUserProfileImageUseCase,
        private readonly deleteUserProfileImageUseCase: DeleteUserProfileImageUseCase,
        private readonly getSecurityQuestionsUseCase: GetSecurityQuestionsUseCase,
        private readonly requestUpdateSecurityQuestionsOTPUseCase: RequestUpdateSecurityQuestionsOTPUseCase,
        private readonly verifyUpdateSecurityQuestionsOTPUseCase: VerifyUpdateSecurityQuestionsOTPUseCase,
        private readonly updateSecurityQuestionsUseCase: UpdateSecurityQuestionsUseCase,
        private readonly updatePasswordUseCase: UpdatePasswordUseCase,
        private readonly store: Store,
		private readonly updateUserStatusUseCase: UpdateUserStatusUseCase,
		private readonly getUserUseCase: GetUserUseCase
	) {}
}
