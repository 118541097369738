import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { UpdatePasswordRequestDTO, UpdatepasswordResponseDTO } from "../../../core-banking/application/dtos/settings/security-question.dto";

@Injectable({
    providedIn: 'root'
})
export class UpdatePasswordUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(request: UpdatePasswordRequestDTO): Observable<UpdatepasswordResponseDTO> {
        return this.settingsRepository.updatePassword(request);
    }
}
