import {inject, Injectable} from "@angular/core";
import {ITransferRepository} from "../../domain/repositories/transfer.repository";
import { InternationalTransferRequestDTO } from "../dtos/transfer.dto";

@Injectable({ providedIn: 'root'})
export class TransferToInternationalAccountUseCase {
    transferService = inject(ITransferRepository);

    execute(request: InternationalTransferRequestDTO) {
        return this.transferService.transferToInternational(request);
    }
}