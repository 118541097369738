import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {provideHttpClient} from '@angular/common/http';
import {CoreBankingApiService} from "./infrastructure/api/core-banking-api.service";

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    provideHttpClient(),
    CoreBankingApiService,
  ]
})
export class CoreBankingModule { }
