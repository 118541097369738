import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { SecurityQuestionList } from "../../domain/entities/security-question.entity";

@Injectable({
    providedIn: 'root'
})
export class GetSecurityQuestionsUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(): Observable<SecurityQuestionList> {
        return this.settingsRepository.getSecurityQuestions();
    }
}
