import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationRepositoryImpl } from './infrastructure/repositories/notification.repository.impl';
import { GetNotificationsUseCase } from './application/use-cases/get-notifications.use-case';

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: 'INotificationRepository',
			useClass: NotificationRepositoryImpl,
		},
		GetNotificationsUseCase,
	],
})
export class CoreNotificationModule {}
