import { Injectable } from '@angular/core';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
	CoreBankingError,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { IFaqsService } from '../../../domain/interfaces/faqs-service.interface';
import { FaqsPDO, FaqsResponsePDO } from '../../dtos/faqs/faqs.dto';

@Injectable({
	providedIn: 'root',
})
export class FaqsService implements IFaqsService {
	private readonly BASE_URL =
		'/services/data/v1/ContentManagement/operations/Information/getFAQs';

	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getFaqs(): Observable<FaqsPDO[]> {
		return this.api.post<FaqsResponsePDO>(`${this.BASE_URL}`, {}).pipe(
			map((response) => {
				if (response.categories) {
					return response.categories;
				} else {
					throw new CoreBankingError(
						'GET_PRIVACY_POLICY_FAILED',
						'Failed to get privacy policy',
						0,
						500,
						response
					);
				}
			}),
			catchError((error) => {
				if (error instanceof UnauthorizedException) {
					return throwError(() => error);
				}
				if (error.httpStatusCode === 400 || error.dbpErrCode === '12001') {
					return throwError(() => new UnauthorizedException());
				}
				return this.errorHandler.handleError(
					error,
					'GET_PRIVACY_POLICY_FAILED',
					'Failed to get privacy policy'
				);
			})
		);
	}
}
