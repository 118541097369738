export class NotificationCount {
	constructor(public unreadNotificationCount: string) {}
}

export class NNotification {
	constructor(
		public isRead: string,
		public notificationId: string,
		public notificationSubject: string,
		public notificationText: string,
		public receivedDate : string,
		public userNotificationId : string,
		public notificationCategory: string
	) {}
}


export class Notificationcategory {
	constructor(
		public alertcategory_accountLevel: string,
		public alertcategory_Name: string,
		public alertcategory_status_id: string,
		public alertcategorytext_softdeleteflag: string,
		public Alerts_count: string,
		public alertcategory_companyLegalUnit: string,
		public alertcategory_softdeleteflag: string,
		public alertcategory_DisplaySequence: string,
		public Groups_count: string,
		public alertcategorytext_createdts: string,
		public categorySubscription: any,
		public alertcategorytext_LanguageCode: string,
		public alertcategory_id: string,
		public alertcategory_freqId: string,
		public alertcategorytext_synctimestamp: string,
		public alertcategory_freqTime: string,
		public alertcategorytext_lastmodifiedts: string,
		public alertcategorytext_DisplayName: string,
		public alertcategorytext_Description: string
	) {}
}
