import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthState } from '../../core/authentication/state/reducers/auth.reducer';
import { AccountState } from '../../core/account-management/state/account.reducer';
import { NotificationState } from '../../core/notification/state/reducers/notification.reducer';
import { TransactionState } from '../../core/transaction-management/state/transaction.reducers';

interface AppState {
	auth: AuthState;
	account: AccountState;
	notification: NotificationState;
	transaction: TransactionState;
}

export function localStorageSyncReducer(
	reducer: ActionReducer<any>
): ActionReducer<any> {
	return localStorageSync({
		keys: ['auth', 'account', 'notification', 'transaction'],
		rehydrate: true,
		removeOnUndefined: true,
		storageKeySerializer: (key) => `myApp_${key}`,
	})(reducer);
}

export function clearStateOnLogout(
	reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
	return function (state, action) {
		if (action.type === '[Auth] Logout') {
			state = undefined;
		}
		return reducer(state, action);
	};
}

export const metaReducers: MetaReducer<AppState>[] = [
	clearStateOnLogout,
	localStorageSyncReducer,
];
