import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { TrackDevicePDO } from '../../../core-banking/application/dtos/auth/track-device.dto';

@Injectable({
	providedIn: 'root',
})
export class TrackDeviceUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(): Observable<TrackDevicePDO> {
		return this.authRepository.trackDeviceRegistration();
	}
}


