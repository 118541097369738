import { IBeneficiaryRepository } from '../../domain/repositories/beneficiary.repository';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
	BeneficiaryResponseDTO,
	BeneficiaryUpSertRequestDTO,
} from '../dtos/beneficiary.dto';

@Injectable({
	providedIn: 'root',
})
export class CreateBeneficiaryUseCase {
	constructor(
		@Inject('IBeneficiaryRepository')
		private readonly beneficiaryRepository: IBeneficiaryRepository
	) {}

	execute(
		beneficiary: BeneficiaryUpSertRequestDTO
	): Observable<BeneficiaryResponseDTO> {
		return this.beneficiaryRepository.create(beneficiary);
	}
}
