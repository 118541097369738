import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { VerifyUserResponseDTO } from '../../../core-banking/application/dtos/auth/user-login.dto';

@Injectable({
	providedIn: 'root',
})
export class VerifyUserUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

  execute(credentials: { password: string }): Observable<VerifyUserResponseDTO> {
    return this.authRepository.verifyUser(credentials);
  }
}
