import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { RegisterUserDTO } from '../dtos/authentication.dto';

@Injectable({
	providedIn: 'root',
})
export class RegisterUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(userData: RegisterUserDTO): Observable<any> {
		return this.authRepository.register(userData);
	}
}
