import { inject, Injectable } from '@angular/core';
import { ITermsConditionRepository } from '../../domain/repositories/terms-condition.repository';
import { Observable } from 'rxjs';
import { GetPostLoginRequestPDO } from '../dtos/terms-condition.dto';
import { GetPostLogin } from '../../domain/entities/get-post-login.entity';

@Injectable({ providedIn: 'root' })
export class GetPostLoginUseCase {
	termsConditionService = inject(ITermsConditionRepository);

	execute(request: GetPostLoginRequestPDO): Observable<GetPostLogin> {
		return this.termsConditionService.getPostLogin(request);
	}
}
