import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-action-card',
	standalone: true,
	imports: [RouterModule, NgFor, TranslateModule],
	templateUrl: './action-card.component.html'
})
export class ActionCardComponent {
	@Input() items: { title: string; link: string }[] = [];
}
