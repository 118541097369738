import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-annonce-savoir-plus',
	standalone: true,
	templateUrl: './annonce-savoir-plus.component.html',
	imports: [CommonModule, TranslateModule],
})
export class AnnonceSavoirPlusComponent {}
