import { Injectable } from '@angular/core';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ISettingsService } from '../../../domain/interfaces/settings-service.interface';
import { catchError, map, Observable } from 'rxjs';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { UserProfileImageDTO } from '../../dtos/settings/user-profile-image.dto';
import { UserInfosDTO } from '../../dtos/settings/user-infos.dto';
import {
	GetSecurityQuestionsResponseDTO,
	RequestUpdateSecurityQuestionsOTPResponseDTO,
	RequestUpdateSecurityQuestionsOTPRequestDTO,
	UpdateSecurityQuestionsRequestDTO,
	VerifyUpdateSecurityQuestionsOTPRequestDTO,
	VerifyUpdateSecurityQuestionsOTPResponseDTO,
	MFAAttributesDTO,
	UpdateSecurityQuestionsResponseDTO,
	UpdatePasswordRequestDTO,
	UpdatepasswordResponseDTO,
	UpdateUserStatusRequestDTO,
} from '../../dtos/settings/security-question.dto';
import { Logger } from '../../../../../shared/helpers/logger-helper';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';

@Injectable({
	providedIn: 'root',
})
export class SettingsService implements ISettingsService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getUserInfos(): Observable<UserInfosDTO> {
		return this.api
			.get<UserInfosDTO>(CORE_BANKING_API_ENDPOINTS.SETTINGS.GET_USER_INFOS)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to get user infos');
				})
			);
	}

	getUserProfileImage(): Observable<UserProfileImageDTO> {
		return this.api
			.post<UserProfileImageDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.GET_USER_PROFILE_IMAGE,
				{}
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to get user profile image');
				})
			);
	}

	updateUserProfileImage(image: string): Observable<UserProfileImageDTO> {
		return this.api
			.post<UserProfileImageDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.UPDATE_USER_PROFILE_IMAGE,
				{ UserImage: image }
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to update user profile image');
				})
			);
	}

	deleteUserProfileImage(): Observable<UserProfileImageDTO> {
		return this.api
			.post<UserProfileImageDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.DELETE_USER_PROFILE_IMAGE,
				{}
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to delete user profile image');
				})
			);
	}

	getSecurityQuestions(): Observable<GetSecurityQuestionsResponseDTO> {
		return this.api
			.post<GetSecurityQuestionsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.GET_SECURITY_QUESTIONS,
				{}
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to get security questions');
				})
			);
	}

	requestUpdateSecurityQuestionsOTP(
		request: RequestUpdateSecurityQuestionsOTPRequestDTO
	): Observable<MFAAttributesDTO> {
		return this.api
			.post<RequestUpdateSecurityQuestionsOTPResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS
					.REQUEST_UPDATE_SECURITY_QUESTIONS_OTP,
				request
			)
			.pipe(
				map((response) => {
					if (response) {
						Logger.info(
							'requestUpdateSecurityQuestionsOTP MFA: ',
							response
						);
						return response.MFAAttributes;
					}
					throw new Error(
						'Failed to request update security questions OTP'
					);
				})
			);
	}

	verifyUpdateSecurityQuestionsOTP(
		request: VerifyUpdateSecurityQuestionsOTPRequestDTO
	): Observable<VerifyUpdateSecurityQuestionsOTPResponseDTO> {
		return this.api
			.post<any>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS
					.VERIFY_UPDATE_SECURITY_QUESTIONS_OTP,
				request
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error(
						'Failed to verify update security questions OTP'
					);
				})
			);
	}

	updateSecurityQuestions(
		request: UpdateSecurityQuestionsRequestDTO
	): Observable<UpdateSecurityQuestionsResponseDTO> {
		return this.api
			.post<any>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.UPDATE_SECURITY_QUESTIONS,
				request
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to update security questions');
				})
			);
	}

	getUser(): Observable<UserInfosDTO> {
		return this.api
			.get<UserInfosDTO>(CORE_BANKING_API_ENDPOINTS.SETTINGS.USERS)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to get user');
				})
			);
	}

	updateUserStatus(request: UpdateUserStatusRequestDTO): Observable<any> {
		return this.api
			.post<any>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.UPDATE_USER_STATUS,
				request
			)
			.pipe(
				map((response) => {
					if (response) {
						return response;
					}
					throw new Error('Failed to update user status');
				})
			);
	}

	/**
	 * @description Update password
	 * @param request The request object which contains the old and new passwords
	 * @returns The response object which contains the status of the operation
	 */
	updatedPassword(
		request: UpdatePasswordRequestDTO
	): Observable<UpdatepasswordResponseDTO> {
		return this.api
			.post<UpdatepasswordResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.SETTINGS.UPDATE_USER_PASSWORD,
				request
			)
			.pipe(
				map((response) => {
					// If the response is present, return the response
					if (response && response.opstatus === 0 && !response.errmsg) {
						return response;
					} else {
						this.errorHandler.handleError(
							response,
							'UPDATE_PASSWORD_FAILED',
							'Failed to update password'
						);
						throw new Error('Failed to update password');
					}
					// Otherwise, throw an error indicating failure to update password
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'UPDATE_PASSWORD_FAILED',
						'Failed to update password'
					)
				)
			);
	}
}
