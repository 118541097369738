import {inject, Injectable} from "@angular/core";
import {ITransferRepository} from "../../domain/repositories/transfer.repository";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root'})
export class ValidateTransferOtpUseCase {
    transferService = inject(ITransferRepository)

    execute(otp: string): Observable<boolean> {
        return this.transferService.validateTransferByOTP(otp)
    }
}