import { Inject, Injectable } from '@angular/core';
import { IFaqRepository } from '../../domain/repositories/faq.repositoty';
import { FaqCategoriesPDO } from '../dto/faqs.pdo';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GetFaqsUseCase {
	constructor(
		@Inject('IFaqRepository') private readonly faqRepository: IFaqRepository
	) {}

	execute(): Observable<FaqCategoriesPDO[]> {
		return this.faqRepository.getFaqs();
	}
}
