import { Transaction } from '../entities/transaction.entity';
import { Observable } from 'rxjs';
import {
	TransactionRequestDTO,
	SearchTransactionsRequestDTO,
} from '../../application/dtos/transaction.dto';
import { LoanTransaction } from '../entities/loan-transaction.entity';
import { SearchTransaction } from '../entities/search-transaction.entity';

export abstract class ITransactionRepository {
	abstract getUserScheduledTransactions(): Observable<Transaction[]>;
	abstract getRecentTransactions(
		request: TransactionRequestDTO
	): Observable<Transaction[]>;

	abstract getLoanTransactions(
		request: TransactionRequestDTO
	): Observable<LoanTransaction[]>;
	abstract searchTransactions(
		request: SearchTransactionsRequestDTO
	): Observable<SearchTransaction[]>;
}
