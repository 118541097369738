import { Injectable } from "@angular/core";
import { IApprovalRequestService } from "../../../domain/interfaces/transfer/approval-request-service.interface";
import { catchError, map, Observable } from "rxjs";
import { ApprovalRequestActionResponseDTO, ApprovalRequestDTO, GetAllPendingApprovalRequestDTO, GetAllPendingApprovalResponseDTO } from "../../dtos/transfer/approval-request.dto";
import { CoreBankingApiService } from "../../../infrastructure/api/core-banking-api.service";
import { CORE_BANKING_API_ENDPOINTS } from "../../../infrastructure/constants/api-endpoints";
import { ErrorHandlingService } from "../../../infrastructure/services/error-handling.service";

@Injectable({
    providedIn: "root",
})
export class ApprovalRequestService implements IApprovalRequestService {

    constructor(
		private readonly api: CoreBankingApiService,
        private readonly errorHandler: ErrorHandlingService
    ) { }

    getAllPendingApprovals(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequestDTO[]> {
        return this.api
			.post<GetAllPendingApprovalResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_ALL_PENDING_APPROVALS,
                request
			)
			.pipe(
				map((response) => response.records),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'GET_ALL_PENDING_APPROVALS_FAILED',
                        'Failed to get all pending approvals'
                    )
                )
			);
    }

    getApprovalHistory(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequestDTO[]> {
        return this.api
			.post<GetAllPendingApprovalResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_APPROVAL_HISTORY,
                request
			)
			.pipe(
				map((response) => response.records),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'GET_APPROVAL_HISTORY_FAILED',
                        'Failed to get approval history'
                    )
                )
			);
    }

    getAllPendingRequests(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequestDTO[]> {
        return this.api
			.post<GetAllPendingApprovalResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_ALL_PENDINGS_REQUESTS,
                request
			)
			.pipe(
				map((response) => response.records),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'GET_ALL_PENDINGS_REQUESTS_FAILED',
                        'Failed to get all pendings requests'
                    )
                )
			);
    }

    getRequestHistory(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequestDTO[]> {
        return this.api
			.post<GetAllPendingApprovalResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.GET_REQUEST_HISTORY,
                request
			)
			.pipe(
				map((response) => response.records),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'GET_REQUEST_HISTORY_FAILED',
                        'Failed to get request history'
                    )
                )
			);
    }

    approveRequest(requestId: string): Observable<ApprovalRequestActionResponseDTO> {
        return this.api
			.post<ApprovalRequestActionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.APPROVE_REQUEST,
                { requestId }
			)
			.pipe(
				map((response) => response),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'APPROVE_REQUEST_FAILED',
                        'Failed to approve request'
                    )
                )
			);
    }

    rejectRequest(requestId: string): Observable<ApprovalRequestActionResponseDTO> {
        return this.api
			.post<ApprovalRequestActionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.REJECT_REQUEST,
                { requestId }
			)
			.pipe(
				map((response) => response),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'REJECT_REQUEST_FAILED',
                        'Failed to reject request'
                    )
                )
			);
    }

    withdrawRequest(requestId: string): Observable<ApprovalRequestActionResponseDTO> {
        return this.api
			.post<ApprovalRequestActionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSFERS.WITHDRAW_REQUEST,
                { requestId }
			)
			.pipe(
				map((response) => response),
                catchError((error) => 
                   this.errorHandler.handleError(
                        error,
                        'WITHDRAW_REQUEST_FAILED',
                        'Failed to withdraw request'
                    )
                )
			);
    }
}