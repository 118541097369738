import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import {
	CoreBankingError,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { IMessageService } from '../../../domain/interfaces/message-service.interface';
import { GetMessagesResponseDTO } from '../../dtos/discussion/message.dto';
import { Logger } from '../../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class MessageService implements IMessageService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getMessagesByDiscussionId(
		discussionId: string
	): Observable<GetMessagesResponseDTO> {
		return this.api
			.post<GetMessagesResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.MESSAGES.GET_MESSAGES_BY_DISCUSSION_ID,
				{ request_id: discussionId }
			)
			.pipe(
				map((response) => {
					Logger.info('CoreBanking API getDiscussions: ', response);
					return response;
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'GET_MESSAGES_BY_DISCUSSION_ID_FAILED',
						'Failed to get messages by discussion id'
					);
				})
			);
	}

	private handleSpecificErrors(
		errorCode: string,
		errorDiscussion: string,
		response: any
	): never {
		const errorCodeStatusMap: { [key: string]: number } = {
			'20001': 400,
			'20541': 400,
			'21027': 400,
			'20131': 400,
		};

		const status = errorCodeStatusMap[errorCode.toString()];

		if (status) {
			throw new CoreBankingError(
				errorCode,
				errorDiscussion,
				0,
				status,
				response
			);
		}

		throw new Error(errorDiscussion);
	}
}
