import { Inject, Injectable } from '@angular/core';
import { ICampaignRepository } from '../../domain/repositories/campaign.repository';
import { GetCampaignsRequest } from '../dtos/campaign.dto';

@Injectable({ providedIn: 'root' })
export class GetPreLoginCampaignUseCase {
	constructor(@Inject('ICampaignRepository') private readonly campaignRepository: ICampaignRepository) {}

	execute(request: GetCampaignsRequest) {
		return this.campaignRepository.getPreLoginCampaigns(request);
	}
}
