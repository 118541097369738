import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { UserContract } from '../../domain/entities/user-contract.entity';

@Injectable({
	providedIn: 'root',
})
export class GetUserContractsUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(): Observable<UserContract[]> {
		return this.authRepository.getUserContracts();
	}
}
