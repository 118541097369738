import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GetPreLoginCampaignUseCase } from "../application/use-cases/get-prelogin-campaign.use-case";
import * as CampaignActions from './campaign.actions';
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class CampaignEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly getPreLoginCampaignsUseCase: GetPreLoginCampaignUseCase
    ) {}

    getPreLoginCampaigns$ = createEffect(() => this.actions$.pipe(
        ofType(CampaignActions.getPreLoginCampaignsAction),
        switchMap(({request}) => this.getPreLoginCampaignsUseCase.execute(request).pipe(
            map((campaigns) => CampaignActions.getPreLoginCampaignsActionSuccess({ campaigns })),
            catchError((error) => of(CampaignActions.getPreLoginCampaignsActionFailure({ error })))
        ))
    ));
}