import { createAction, props } from '@ngrx/store';
import { ContactUs } from '../../domain/entities/contact-us.entity';
import { Agence } from '../../domain/entities/agence.entity';
import {
	AgenceRequestDTO,
	CreateFeedbackDTO,
	FeedbackResponseDTO,
} from '../../application/dto/info.dto';

// Load Info contact us
export const loadContactUs = createAction('[Info] Load Contact us');
export const loadContactUsSuccess = createAction(
	'[Info] Load Contact us Success',
	props<{ contactUs: ContactUs }>()
);
export const loadContactUsFailure = createAction(
	'[Info] Load Contact us Failure',
	props<{ error: any }>()
);

// Load Info gence
export const loadAgence = createAction(
	'[Info] Load Agence',
	props<{ data: AgenceRequestDTO }>()
);
export const loadAgenceSuccess = createAction(
	'[Info] Load Agence Success',
	props<{ agences: Agence[] }>()
);
export const loadAgenceFailure = createAction(
	'[Info] Load Agence Failure',
	props<{ error: any }>()
);

export const createFeedback = createAction(
	'[Info] Create feedback',
	props<{ data: CreateFeedbackDTO }>()
);
export const createFeedbackSuccess = createAction(
	'[Info] Create feedback Success',
	props<{ feedback: FeedbackResponseDTO[] }>()
);
export const createFeedbackFailure = createAction(
	'[Info] Create feedback Failure',
	props<{ error: any }>()
);
