import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { VerifyOTPDTO } from '../dtos/authentication.dto';

@Injectable({
	providedIn: 'root',
})
export class VerifyOTPUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(data: VerifyOTPDTO): Observable<any> {
		return this.authRepository.verifyOTP(data);
	}
}
