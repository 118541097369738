import { Observable } from 'rxjs';
import { Account } from '../../domain/entities/account.entity';
import { inject, Injectable } from '@angular/core';
import { IAccountRepository } from '../../domain/repositories/account.repository';

@Injectable({
	providedIn: 'root',
})
export class GetAccountUseCase {
	private readonly accountRepository = inject(IAccountRepository);

	execute(): Observable<Account[]> {
		return this.accountRepository.getAccounts();
	}
}
