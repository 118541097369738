import { UemoaBank } from "../../domain/entities/uemoa-bank.entity";
import { UemoaBankDto } from "../dtos/uemoa-bank.dto";

export class UemoaBankMapper {
	static toDomain(bank: UemoaBankDto): UemoaBank {
		return new UemoaBank(
			bank.bankCode,
			bank.countryCode,
			bank.bankName
		);
	}
}
