import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransferInstructions } from '../../domain/entities/transfer-instructions.entity';

@Injectable({ providedIn: 'root' })
export class GetTransferInstructionsUseCase {
	constructor(private readonly transferRepository: ITransferRepository) {}

	execute(): Observable<TransferInstructions[]> {
		return this.transferRepository.getInstructions();
	}
}
