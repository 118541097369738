import { Inject, Injectable } from '@angular/core';
import { IMessageRepository } from '../../domain/repositories/message.repository';
import { Observable } from 'rxjs';
import { Message } from '../../domain/entities/message.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAllMessagesByDiscussionIdUseCase {
	constructor(
		@Inject('IMessageRepository')
		private readonly messageRepository: IMessageRepository
	) {}

	execute(discussionId: string): Observable<Message[]> {
		return this.messageRepository.getMessagesByDiscussionId(discussionId);
	}
}
