import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISettingsRepository } from '../../domain/repositories/settings.repository';
import {
	UpdateUserStatusRequestDTO,
	UpdateUserStatusResponseDTO,
} from '../dtos/security-question.dto';

@Injectable({
	providedIn: 'root',
})
export class UpdateUserStatusUseCase {
	constructor(
		@Inject('ISettingsRepository')
		private readonly settingsRepository: ISettingsRepository
	) {}

	execute(
		request: UpdateUserStatusRequestDTO
	): Observable<UpdateUserStatusResponseDTO> {
		return this.settingsRepository.updateUserStatus(request);
	}
}
