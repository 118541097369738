import { Injectable } from '@angular/core';
import { forkJoin, Observable, tap, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import {
	BeneficiaryDTO,
	BeneficiaryListResponseDTO,
	BeneficiaryRequestDTO,
	BeneficiaryResponseDTO,
	BeneficiaryType,
	DeleteBeneficiaryRequestDTO,
	GetBeneficiaryResponseDTO,
} from '../../dtos/beneficiary/beneficiary.dto';
import {
	PayeeNameRequestDTO,
	PayeeNameResponseDTO,
} from '../../dtos/beneficiary/payee-name.dto';
import { IBeneficiaryService } from '../../../domain/interfaces/beneficiary-service.interface';
import {
	BeneficiaryAlreadyExistsException,
	CoreBankingError,
	InvalidParametersException,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { CompanyService } from '../company/company.service';
import { TranslateService } from '@ngx-translate/core';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { Logger } from '../../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class BeneficiaryService implements IBeneficiaryService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly companyService: CompanyService,
		private readonly translate: TranslateService
	) {}

	createBeneficiary(
			request: BeneficiaryRequestDTO
		): Observable<BeneficiaryResponseDTO> {
			const { beneficiaryType, payload } = request;
			const dataPayload = { ...payload };
		
			try {
				this.validateBeneficiaryPayload(beneficiaryType, dataPayload);
				Logger.info('Core createBeneficiary dataPayload:', dataPayload);
			} catch (error) {
				return throwError(() => error);
			}
		
			return this.api.post<any>(
				CORE_BANKING_API_ENDPOINTS.BENEFICIARY.CREATE_BENEFICIARY,
				dataPayload
			).pipe(
				map((response) => {
					Logger.info('Core createBeneficiary response:', response);
					if (response.Id || response.referenceId) {
						return response;
					} else if (response.dbpErrCode && response.dbpErrMsg) {
						this.handleSpecificErrors(
							response.dbpErrCode,
							response.dbpErrMsg
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					Logger.info('Core createBeneficiary error:', error);
					if (error instanceof BeneficiaryAlreadyExistsException) {
						return throwError(() => error);
					}
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (error instanceof InvalidParametersException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'CREATE_BENEFICIARY_FAILED',
						'Failed to create beneficiary'
					);
				})
			);
	}
	  

	getBeneficiaries(): Observable<BeneficiaryListResponseDTO> {
		return this.api
			.post<BeneficiaryListResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.BENEFICIARY.GET_BENEFICIARIES_LIST,
				{}
			)
			.pipe(
				map((response) => {
					Logger.info('Core getBeneficiaries Beneficiaries:', response);
					if (response.opstatus === 200 || response.opstatus === 0) {
						return response;
					} else {
						throw new CoreBankingError(
							'GET_BENEFICIARIES_FAILED',
							'Failed to get beneficiaries list',
							0,
							500,
							response
						);
					}
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_BENEFICIARIES_FAILED',
						'Failed to get beneficiaries list'
					)
				)
			);
	}

	editBeneficiary(
		request: BeneficiaryRequestDTO
	): Observable<BeneficiaryResponseDTO> {
		const { authToken, legalEntityId, payload } = request;

		return this.companyService
			.updateCurrentLegalEntity(authToken, legalEntityId)
			.pipe(
				switchMap((response) => {
					Logger.info('Core updateCurrentLegalEntity response:', response);
					Logger.info('Core updateCurrentLegalEntity payload:', payload);
					return this.api.post<any>(
						CORE_BANKING_API_ENDPOINTS.BENEFICIARY.EDIT_BENEFICIARY,
						payload
					);
				}),
				map((response) => {
					Logger.info('Core editBeneficiary response:', response);
					if (response.Id) {
						return response;
					} else if (response.dbpErrCode && response.dbpErrMsg) {
						this.handleSpecificErrors(
							response.dbpErrCode,
							response.dbpErrMsg
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					if (
						error instanceof UnauthorizedException ||
						error instanceof InvalidParametersException
					) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'EDIT_BENEFICIARY_FAILED',
						'Failed to edit beneficiary'
					);
				})
			);
	}

	deleteBeneficiary(
		deleteRequest: DeleteBeneficiaryRequestDTO
	): Observable<any> {
		return forkJoin([
			this.companyService.updateCurrentLegalEntity(),
			//this.getPayeeName({accountNumber: deleteRequest.accountNumber}),
			this.getBeneficiaries(),
		]).pipe(
			tap(([updateLegalEntityResponse, beneficiariesResponse]) => {
				//Logger.info('Core updateCurrentLegalEntity response:', updateLegalEntityResponse);
				//Logger.info('Core getPayeeName response:', payeeNameResponse);
				//Logger.info('Core getBeneficiaries response:', beneficiariesResponse);
			}),
			switchMap(() =>
				this.api.post<any>(
					CORE_BANKING_API_ENDPOINTS.BENEFICIARY.DELETE_BENEFICIARY,
					deleteRequest
				)
			),
			map((response) => {
				Logger.info('Core deleteBeneficiary response:', response);
				if (response.Id && response.opstatus === 0) {
					return response;
				} else {
					throw new CoreBankingError(
						'DELETE_BENEFICIARY_FAILED',
						'Failed to delete beneficiary',
						0,
						500,
						response
					);
				}
			}),
			catchError((error) => {
				if (error.httpStatusCode === 400 || error.dbpErrCode === '12001') {
					return throwError(() => new UnauthorizedException());
				}
				return this.errorHandler.handleError(
					error,
					'DELETE_BENEFICIARY_FAILED',
					'Failed to delete beneficiary'
				);
			})
		);
	}

	getPayeeName(
		request: PayeeNameRequestDTO
	): Observable<PayeeNameResponseDTO> {
		return this.api
			.postHeaderKony<PayeeNameResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.BENEFICIARY.GET_BENEFICIARY_NAME,
				request
			)
			.pipe(
				catchError((error) => {
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_PAYEE_NAME_FAILED',
						'Failed to get payee name'
					);
				})
			);
	}

	getBeneficiary(
		request: PayeeNameRequestDTO
	): Observable<GetBeneficiaryResponseDTO> {
		return this.api
			.post<GetBeneficiaryResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.BENEFICIARY.GET_BENEFICIARY_NAME,
				request
			)
			.pipe(
				map((response) => {
					if (response?.dbpErrCode == '12000' && response?.dbpErrMsg) {
						throw new CoreBankingError(
							'GET_BENEFICIARY_FAILED',
							'Failed to get beneficiary',
							0,
							500,
							response
						);
					} else {
						return response;
					}
				}),
				catchError((error) => {
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_BENEFICIARY_FAILED',
						'Failed to get beneficiary'
					);
				})
			);
	}

	private updateInternalBeneficiaryPayload(payload: BeneficiaryDTO): void {
		payload.IBAN = payload.IBAN || payload.accountNumber;
		payload.state = '';
		payload.bankName = 'BRIDGE BANK';
		payload.isSameBankAccount = 'true';
		payload.isInternationalAccount = 'false';
		payload.swiftCode = '';
		payload.feature = '';
		payload.singleCustomer = 'true';
	}

	private validateBeneficiaryPayload(
		beneficiaryType: BeneficiaryType,
		payload: BeneficiaryDTO
	): void {
		if (!payload.accountNumber) {
			throw new InvalidParametersException(
				'accountNumber is required for a beneficiary'
			);
		}

		if (!payload.beneficiaryName) {
			throw new InvalidParametersException(
				'beneficiaryName is required for a beneficiary'
			);
		}

		switch (beneficiaryType) {
			case BeneficiaryType.internal:
				this.updateInternalBeneficiaryPayload(payload);
				break;
			case BeneficiaryType.external:
				this.updateExternalBeneficiaryPayload(payload);
				break;
			case BeneficiaryType.international:
				this.updateInternationalBeneficiaryPayload(payload);
				break;
		}
	}

	private updateExternalBeneficiaryPayload(payload: BeneficiaryDTO): void {
		if (!payload.swiftCode) {
			throw new InvalidParametersException(
				'swiftCode is required for an external beneficiary'
			);
		}
		if (!payload.bankName) {
			throw new InvalidParametersException(
				'bankName is required for an external beneficiary'
			);
		}

		payload.state = '';
		payload.isSameBankAccount = 'false';
		payload.isInternationalAccount = 'false';
		payload.sameBank = '';
		payload.otherBank = '';
		payload.feature = '';
		payload.isVerified = 'true';
		payload.singleCustomer = 'true';
	}

	private updateInternationalBeneficiaryPayload(
		payload: BeneficiaryDTO
	): void {
		if (!payload.IBAN) {
			throw new InvalidParametersException(
				'IBAN is required for an international beneficiary'
			);
		}
		if (!payload.swiftCode) {
			throw new InvalidParametersException(
				'swiftCode is required for an international beneficiary'
			);
		}

		payload.state = '';
		payload.isSameBankAccount = 'false';
		payload.isInternationalAccount = 'true';
		payload.sameBank = '';
		payload.otherBank = '';
		payload.feature = '';
		payload.isVerified = 'true';
		payload.singleCustomer = 'true';
	}

	private handleSpecificErrors(
		errorCode: string,
		errorMessage: string
	): never {
		switch (errorCode) {
			case '12062':
				throw new BeneficiaryAlreadyExistsException(
					this.translate.instant(
						'BENEFICIARY.ERRORS.BENEFICIARY_ALREADY_EXISTS'
					)
				);
			case '10710':
				throw new InvalidParametersException(errorMessage);
			case '10348':
				throw new InvalidParametersException(errorMessage);
			default:
				throw new Error(errorMessage);
		}
	}
}
