import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";

@Injectable({
    providedIn: 'root'
})
export class GetUserProfileImageUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(): Observable<string> {
        return this.settingsRepository.getUserProfileImage();
    }
}
