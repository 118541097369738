import { Injectable } from "@angular/core";
import { PermissionService } from "../services/permission..service";
import { FeatureCodes } from "./codes/features.codes";
import { PermissionCodes } from "./codes/permissions.code";

@Injectable({
    providedIn: 'root',
  })
  export class MessagePermissions {
    constructor(private readonly permissionService: PermissionService) {}
  
    // Méthode privée commune pour réduire la répétition
    private hasMessageAccess(requiredPermission: string): boolean {
        return (
            this.canManageMessage &&
            this.permissionService.hasPermission(requiredPermission)
        );
    }
  
    get canManageMessage(): boolean {
        return this.permissionService.hasFeature(FeatureCodes.MESSAGES);
    }
  
    get canCreateOrReplyMessage(): boolean {
        return this.hasMessageAccess(PermissionCodes.MESSAGES_CREATE_OR_REPLY);
    }
  
    get canDeleteMessage(): boolean {
        return this.hasMessageAccess(PermissionCodes.MESSAGES_DELETE);
    }
  
    get canViewMessage(): boolean {
        return this.hasMessageAccess(PermissionCodes.MESSAGES_VIEW);
    }
  }