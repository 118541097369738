import { Injectable, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthTokenService } from '../../core/authentication/application/services/auth-token.service';
import { Store } from '@ngrx/store';
import { logout } from '../../core/authentication/state/actions/auth.actions';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, OnInit {
	constructor(
		private readonly tokenService: AuthTokenService,
		private readonly router: Router,
		private readonly store: Store
	) {}

	ngOnInit(): void {
		if (!this.tokenService.isLogged()) {
			this.router.navigate(['/auth/login']);
		}
	}

	

	canActivate(): boolean {
		const isLogged = this.tokenService.isLogged();
		console.log('isLogged: ', isLogged);

		if (!isLogged) {
			console.log('Token is null, redirecting to login');
			this.tokenService.removeTokens();
			this.router.navigate(['/auth/login']);
			return false;
		}

		
		return true;
	}

	goToLogout(): void {
		this.store.dispatch(logout());
	}
}
