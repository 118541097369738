import { Injectable } from '@angular/core';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { IAuthService } from '../../../domain/interfaces/auth-service.interface';
import { SuccessResponse } from '../../../shared/types/api-responses';
import {
	TermsAndConditionsResponseDTO,
	UserRegistrationRequestDTO,
	UserRegistrationResponseDTO,
} from '../../dtos/auth/user-registration.dto';
import {
	ActivateAccountRequestDTO,
	ActivateAccountResponseDTO,
	ResendActivateCodeRequestDTO,
	ResendActivateCodeResponseDTO,
} from '../../dtos/auth/user-active-account.dto';
import {
	LoginCredentialsDTO,
	LoginResponseDTO,
	VerifyUserRequestDTO,
	VerifyUserResponseDTO,
} from '../../dtos/auth/user-login.dto';
import { CreatePasswordRequestDTO } from '../../dtos/auth/create-password.dto';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { UserContractsResponseDTO } from '../../dtos/auth/user-contract.dto';
import { CoreBankingError } from '../../../domain/errors/core-banking-error';
import {
	RequestResendOTPDTO,
	PasswordForgottenRequestDTO,
	RequestVerifyOTPDTO,
	ResetPasswordRequestDTO,
} from '../../dtos/auth/reset-password.dto';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { CookieService } from 'ngx-cookie-service';
import { CompanyService } from '../company/company.service';
import { Logger } from '../../../../../shared/helpers/logger-helper';
import {
	LanguageRequestDTO,
	LanguageResponsePDO,
} from '../../dtos/auth/default-language.dto';
import { TrackDevicePDO } from '../../dtos/auth/track-device.dto';

@Injectable({
	providedIn: 'root',
})
export class AuthService implements IAuthService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly companyService: CompanyService,
		private readonly cookieService: CookieService
	) {}

	login(credentials: LoginCredentialsDTO): Observable<LoginResponseDTO> {
		return this.api
			.login<LoginResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.LOGIN,
				credentials
			)
			.pipe(
				switchMap((loginResponse: LoginResponseDTO) =>
					this.handleLegalEntities(loginResponse)
				),
				catchError((error) => this.handleLoginError(error))
			);
	}

	private handleLegalEntities(
		loginResponse: LoginResponseDTO
	): Observable<LoginResponseDTO> {
		const claimsToken = loginResponse?.claims_token?.value;
		const legalEntityId =
			loginResponse?.profile?.user_attributes?.legalEntityId;

		return this.companyService
			.updateCurrentLegalEntity(claimsToken, legalEntityId)
			.pipe(
				switchMap(() =>
					this.getFeaturePermissions(loginResponse, claimsToken)
				)
			);
	}

	private getFeaturePermissions(
		loginResponse: LoginResponseDTO,
		claimsToken: string
	): Observable<LoginResponseDTO> {
		return this.api
			.postKony(
				CORE_BANKING_API_ENDPOINTS.AUTH.FEATURE_PERMISSIONS,
				null,
				claimsToken
			)
			.pipe(
				map((featurePermissionsResponse: any) => {
					loginResponse.features = featurePermissionsResponse.features;
					loginResponse.permissions =
						featurePermissionsResponse.permissions;
					return loginResponse;
				})
			);
	}

	private handleLoginError(error: any): Observable<never> {
		Logger.error('error?.response : ', error.error);
		if (error.status === 401) {
			if (error?.error?.details?.errcode === 10088) {
				throw new CoreBankingError(
					'USER_PROFILE_LOCKED',
					'Your profile is locked, reset the password to unlock',
					error.status,
					error.status,
					error
				);
			} else if (error?.error?.details?.errcode === 10135) {
				throw new CoreBankingError(
					'USER_PASSWORD_EXPIRED',
					'Your password has expired. Please reset it',
					error.status,
					error.status,
					error
				);
			} else if (error?.error?.details?.errcode === 10089) {
				throw new CoreBankingError(
					'E_BANKING_PROFILE_HAS_BEEN_SUSPENDED',
					'Sorry, your e-Banking profile has been suspended. Please contact us for more information and to enable your profile.',
					error.status,
					error.status,
					error
				);
			}
			throw new CoreBankingError(
				'USER_NOT_FOUND',
				"Échec de l'authentification",
				error.status,
				error.status,
				error
			);
		}

		if (error instanceof CoreBankingError) {
			return throwError(() => error);
		}

		return this.errorHandler.handleError(
			error,
			'LOGIN_FAILED',
			'Failed to login'
		);
	}

	register(
		user: UserRegistrationRequestDTO
	): Observable<UserRegistrationResponseDTO> {
		return this.api
			.postWithBasicAuth<UserRegistrationResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.REGISTER,
				user
			)
			.pipe(
				map((response) => {
					if (
						response.isUserExists === 'true' &&
						response.isActivationCodeSent === 'true'
					) {
						return response;
					} else if (response.isUserExists === 'false') {
						throw new CoreBankingError(
							'USER_NOT_EXIST',
							"L'utilisateur n'existe pas",
							0,
							404,
							response
						);
					} else if (
						response.isUserExists === 'true' &&
						response.isUserEnrolled === 'true'
					) {
						throw new CoreBankingError(
							'USER_ALREADY_ENROLLED',
							"L'utilisateur est déjà inscrit",
							0,
							409,
							response
						);
					} else {
						throw new CoreBankingError(
							'INVALID_REGISTRATION_RESPONSE',
							"La réponse d'enregistrement est invalide",
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'REGISTRATION_FAILED',
						'Failed to register user'
					);
				})
			);
	}

	activateAccount(
		data: ActivateAccountRequestDTO
	): Observable<ActivateAccountResponseDTO> {
		return this.api
			.postWithBasicAuth<ActivateAccountResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.ACTIVATE_ACCOUNT,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					}
					const errorMap: Record<
						number,
						{ code: string; message: string }
					> = {
						10741: {
							code: 'INVALID_ACTIVATION_CODE_VALIDATION',
							message: "Nombre d'essais de réinitialisation dépassé",
						},
						10747: {
							code: 'MAX_ATTEMPTS_REACHED',
							message: "Nombre d'essais de réinitialisation dépassé",
						},
					};

					const error =
						response.dbpErrCode && errorMap[response.dbpErrCode]
							? errorMap[response.dbpErrCode]
							: {
									code: 'USER_NOT_FOUND',
									message: 'Information invalide',
							  };

					throw new CoreBankingError(
						error.code,
						error.message,
						0,
						400,
						response
					);
				}),
				catchError((error) =>
					error instanceof CoreBankingError
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'ACTIVATION_FAILED',
								'Failed to activate account'
						  )
				)
			);
	}

	createPassword(data: CreatePasswordRequestDTO): Observable<SuccessResponse> {
		return this.api
			.postWithBasicAuth<SuccessResponse>(
				CORE_BANKING_API_ENDPOINTS.AUTH.CREATE_PASSWORD,
				data
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'CREATE_PASSWORD_FAILED',
						'Failed to create password'
					)
				)
			);
	}

	logout(): Observable<void> {
		return this.api
			.login<void>(CORE_BANKING_API_ENDPOINTS.AUTH.LOGOUT, {})
			.pipe(
				switchMap(() => {
					return this.clearSession().pipe(map(() => {}));
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'LOGOUT_FAILED',
						'Failed to logout'
					)
				)
			);
	}

	clearSession(): Observable<void> {
		return this.api
			.post<void>(CORE_BANKING_API_ENDPOINTS.AUTH.CLEAR_SESSION, {})
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'CLEAR_SESSION_FAILED',
						'Failed to clear session'
					)
				)
			);
	}

	getUserContracts(): Observable<UserContractsResponseDTO> {
		return this.api
			.post<UserContractsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.GET_USER_CONTRACTS,
				{}
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_USER_CONTRACTS_FAILED',
						'Failed to get user contracts'
					)
				)
			);
	}

	refreshToken(refreshToken: string | null): Observable<string> {
		return this.api
			.post<any>(CORE_BANKING_API_ENDPOINTS.AUTH.REFRESH_TOKEN, {
				refreshToken,
			})
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'REFRESH_TOKEN_FAILED',
						'Failed to refresh token'
					)
				)
			);
	}

	postVerifyCoreUser(data: PasswordForgottenRequestDTO): Observable<any> {
		return this.api
			.postWithBasicAuth<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.VERIFY_CORE_USER,
				data
			)
			.pipe(
				map((response) => {
					if (response && response.isUserExists === 'true') {
						return response;
					} else {
						throw new CoreBankingError(
							'USER_NOT_FOUND',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	requestResetPasswordOTP(data: RequestResendOTPDTO): Observable<any> {
		return this.api
			.postWithBasicAuth<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.REQUEST_RESET_PASSWORD_OTP,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else if (response.dbpErrCode === '10544') {
						throw new CoreBankingError(
							'MAX_RESEND_OTP_ATTEMPTS_REACHED',
							"Nombre d'essais de réinitialisation dépassé",
							0,
							429,
							response
						);
					} else {
						throw new CoreBankingError(
							'INVALID_INFORMATION',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	verifyOTP(data: RequestVerifyOTPDTO): Observable<any> {
		return this.api
			.postWithBasicAuth<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.VERIFY_OTP,
				data
			)
			.pipe(
				map((response) => {
					if (response && response.isOtpVerified === 'true') {
						return response;
					} else {
						throw new CoreBankingError(
							'INVALID_OTP_INFORMATION',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	resetPassword(data: ResetPasswordRequestDTO) {
		return this.api
			.postWithBasicAuth<any>(
				CORE_BANKING_API_ENDPOINTS.AUTH.RESET_PASSWORD,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else if (response.dbpErrCode === 10134) {
						throw new CoreBankingError(
							'PASSWORD_ALREADY_USED',
							'Password is already present in the previous 5 passwords',
							0,
							404,
							response
						);
					} else {
						throw new CoreBankingError(
							'RESET_PASSWORD_ERROR',
							'Information invalide',
							0,
							404,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESET_PASSWORD_ERROR',
						'Failed to verify core user'
					);
				})
			);
	}

	getTermsAndConditions(): Observable<TermsAndConditionsResponseDTO> {
		const currentLegalEntityId = this.cookieService.get('legal_entity') || '';
		return this.api
			.post<TermsAndConditionsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.USER.TERMS_AND_CONDITIONS,
				{
					languageCode: 'en-US',
					termsAndConditionsCode: 'Common_TnC',
					legalEntityId: currentLegalEntityId,
				}
			)
			.pipe(
				map((response) => {
					if (response && !response.dbpErrCode) {
						return response;
					} else {
						throw new CoreBankingError(
							'TERMS_AND_CONDITIONS_ERROR',
							'Information invalide',
							0,
							400,
							response
						);
					}
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_TERMS_AND_CONDITIONS_FAILED',
						'Failed to get terms and conditions'
					)
				)
			);
	}

	resendActivateCode(data: ResendActivateCodeRequestDTO) {
		return this.api
			.postWithBasicAuth<ResendActivateCodeResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.RESEND_ACTIVATE_CODE,
				data
			)
			.pipe(
				map((response) => {
					if (response && !response.errcode) {
						return response;
					} else {
						throw new CoreBankingError(
							'RESEND_ACTIVATE_CODE_ERROR',
							'Information invalide',
							0,
							404,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}
					return this.errorHandler.handleError(
						error,
						'RESEND_ACTIVATE_CODE_FAILED',
						'Failed to verify core user'
					);
				})
			);
	}

	verifyUser(
		credentials: VerifyUserRequestDTO
	): Observable<VerifyUserResponseDTO> {
		return this.api
			.post<VerifyUserResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.AUTH.VERFIFY_USER,
				credentials
			)
			.pipe(
				map((loginResponse) => {
					Logger.info('Verify User response : ', loginResponse);
					return loginResponse;
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}

					return this.errorHandler.handleError(
						error,
						'VERIFICATION_FAILED',
						'Failed to verify user'
					);
				})
			);
	}

	setDefaultLanguage(
		data: LanguageRequestDTO
	): Observable<LanguageResponsePDO> {
		return this.api
			.post<LanguageResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.USER.DEFAULT_LANGUAGE,
				data
			)
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}

					return this.errorHandler.handleError(
						error,
						'SETTING_DEFAULT_LANGUAGE_FAILED',
						'Failed to set default language'
					);
				})
			);
	}

	trackDeviceRegistration(): Observable<TrackDevicePDO> {
		return this.api
			.post<TrackDevicePDO>(CORE_BANKING_API_ENDPOINTS.AUTH.TRACK_DEVICE, {})
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((error) => {
					if (error instanceof CoreBankingError) {
						return throwError(() => error);
					}

					return this.errorHandler.handleError(
						error,
						'SETTING_TRACK_DEVICE_FAILED',
						'Failed track Device Registration'
					);
				})
			);
	}
}
