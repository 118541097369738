import { GetPostLogin } from "../../domain/entities/get-post-login.entity";
import {GetPostLoginResponsePDO} from "../dtos/terms-condition.dto";

export class GetPostLoginMapper {
    static toDomain(response: GetPostLoginResponsePDO): GetPostLogin {
        return new GetPostLogin(
            response.opstatus,
            response.alreadySigned,
            response.httpStatusCode
        );
            
            
    }

    toDTO(response: GetPostLogin): GetPostLoginResponsePDO {
        return <GetPostLoginResponsePDO>{
            opstatus: response.opstatus,
            alreadySigned: response.alreadySigned,
            httpStatusCode: response.httpStatusCode
        };
    }
}
