import { IBeneficiaryRepository } from '../../domain/repositories/beneficiary.repository';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { GetBeneficiaryResponseDTO } from '../dtos/beneficiary.dto';

@Injectable({
	providedIn: 'root',
})
export class GetBeneficiaryUseCase {
	constructor(
		@Inject('IBeneficiaryRepository')
		private readonly beneficiaryRepository: IBeneficiaryRepository
	) {}

	execute(accountNumber: string): Observable<GetBeneficiaryResponseDTO> {
		return this.beneficiaryRepository.getBeneficiary(accountNumber);
	}
}
