import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Store } from '@ngrx/store';
import { logout } from '../../../core/authentication/state/actions/auth.actions';

@Component({
	selector: 'app-logout',
	standalone: true,
	imports: [ConfirmModalComponent],
	templateUrl: './logout.component.html'
})
export class LogoutComponent {
	@ViewChild(ConfirmModalComponent) confirmModal!: ConfirmModalComponent;
	@Input() isModalOpen: boolean = false;
	@Output() onClose = new EventEmitter<void>();

	constructor(private readonly store: Store) {}

	close() {
		this.confirmModal.closeModal();
		this.onClose.emit();
	}

	logout() {
		this.store.dispatch(logout());
		this.onClose.emit();
	}
}
