export class PrivacyPolicy {
    constructor(
        public id : string,
        public Channel_id: string,
        public Status_id: string,
        public Description : string,
        public createdby: string,
        public modifiedby: string, 
        public softdeleteflag: boolean
    ) {}
}