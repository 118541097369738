import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { CoreBankingError } from '../../../domain/errors/core-banking-error';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { ICampaignService } from '../../../domain/interfaces/campaign-service.interface';
import { GetCampaignsRequest, GetCampaignsResponse } from '../../dtos/campaign/campaign.dto';

@Injectable({
	providedIn: 'root',
})
export class CampaignService implements ICampaignService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

    getPreLoginCampaigns(request: GetCampaignsRequest): Observable<GetCampaignsResponse> {
        return this.api
        .postt<GetCampaignsResponse>(
            CORE_BANKING_API_ENDPOINTS.CAMPAIGNS.GET_PRE_LOGIN_CAMPAIGNS,
            request
        )
        .pipe(
            map((response) => {
                if (response.CampaignSpecifications.length > 0) {
                    return response;
                } else {
                    throw new CoreBankingError(
                        'GET_LEGAL_ENTITIES_FAILED',
                        'Failed to get legal entities',
                        0,
                        500,
                        response
                    );
                }
            }),
            catchError((error) =>
                this.errorHandler.handleError(
                    error,
                    'GET_CAMPAIGNS_FAILED',
                    'Failed to get campaigns'
                )
            )
        );
    }

}
