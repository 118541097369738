import { Injectable } from '@angular/core';
import { IPrivacyPolicyService } from '../../../domain/interfaces/privacy-policy-service.interface';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { PrivacyPolicyDTO } from '../../dtos/privacy-policy/privacy-policy.dto';
import { catchError, map, Observable, throwError } from 'rxjs';
import {
	CoreBankingError,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';

@Injectable({
	providedIn: 'root',
})
export class PrivacyPolicyService implements IPrivacyPolicyService {
	private readonly BASE_URL =
		'/services/data/v1/ContentManagement/operations/Information/getPrivacyPolicy';

	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getPrivacyPolicy(): Observable<PrivacyPolicyDTO> {
		return this.api
			.postWithBasicAuth<PrivacyPolicyDTO>(`${this.BASE_URL}`, {})
			.pipe(
				map((response) => {
					if (response.records) {
						return response;
					} else {
						throw new CoreBankingError(
							'GET_PRIVACY_POLICY_FAILED',
							'Failed to get privacy policy',
							0,
							500,
							response
						);
					}
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_PRIVACY_POLICY_FAILED',
						'Failed to get privacy policy'
					);
				})
			);
	}
}
