import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRepositoryImpl } from './infrastrucrure/repositories/settings.repository.impl';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    { provide: 'ISettingsRepository', useClass: SettingsRepositoryImpl },
  ]
})
export class CoreSettingsModule { }
