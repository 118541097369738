import { IBeneficiaryRepository } from '../../domain/repositories/beneficiary.repository';
import { Beneficiary } from '../../domain/entities/beneficiary.entity';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { BeneficiaryResponseDTO } from '../dtos/beneficiary.dto';

@Injectable({
	providedIn: 'root',
})
export class DeleteBeneficiaryUseCase {
	constructor(
		@Inject('IBeneficiaryRepository')
		private readonly beneficiaryRepository: IBeneficiaryRepository
	) {}

	execute(beneficiary: Beneficiary): Observable<BeneficiaryResponseDTO> {
		return this.beneficiaryRepository.delete(beneficiary);
	}
}
