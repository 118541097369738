import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ITransactionService} from "../core-banking/domain/interfaces/transaction-service.interface";
import {TransactionService} from "../core-banking/application/services/transaction/transaction.service";
import {ITransactionRepository} from "./domain/repositories/transaction.repository";
import {TransactionRepositoryImpl} from "./infrastructure/repositories/transaction.repository.impl";
import {TransactionEffects} from "./state/effects/transaction.effects";
import {provideEffects} from "@ngrx/effects";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        {
            provide: ITransactionService,
            useClass: TransactionService
        },
        {
            provide: ITransactionRepository,
            useClass: TransactionRepositoryImpl
        },
        provideEffects(TransactionEffects)
    ]
})
export class CoreTransactionManagementModule { }
