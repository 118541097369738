import {inject, Injectable} from "@angular/core";
import {ITransferRepository} from "../../domain/repositories/transfer.repository";
import {TransferRequestDto} from "../dtos/transfer.dto";

@Injectable({ providedIn: 'root'})
export class TransferToIntraAccountUseCase {
    transferService = inject(ITransferRepository);

    execute(request: TransferRequestDto) {
        return this.transferService.transferToIntraAccount(request);
    }
}
