import { Inject, Injectable } from '@angular/core';
import { IDiscussionRepository } from '../../domain/repositories/discussion.repository';
import { Observable } from 'rxjs';
import { DiscussionResponseDTO } from '../dtos/discussion.dto';

@Injectable({
	providedIn: 'root',
})
export class ActivateDiscussionUseCase {
	constructor(
		@Inject('IDiscussionRepository')
		private readonly discussionRepository: IDiscussionRepository
	) {}

	execute(discussionId: string): Observable<DiscussionResponseDTO> {
		return this.discussionRepository.activateDiscussion(discussionId);
	}
}
