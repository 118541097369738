import {
	Component,
	Output,
	EventEmitter,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LogoutComponent } from '../../../components/logout/logout.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
	heroArrowRightEndOnRectangle,
	heroBars3,
	heroBell,
	heroCog8Tooth,
	heroEnvelope,
	heroUser,
} from '@ng-icons/heroicons/outline';
import { MessagePermissions } from '../../../../shared/permissions/message.permissions';
import { NotificationPermissions } from '../../../../shared/permissions/notification.permissions';
import { Store } from '@ngrx/store';
import { Logger } from '../../../../shared/helpers/logger-helper';
import { PermissionService } from '../../../../shared/services/permission..service';
import { SettingsPermissions } from '../../../../shared/permissions/settings.permissions';
import { BeneficiaryPermissions } from '../../../../shared/permissions/beneficiary.permissions';
import { TransferPermissions } from '../../../../shared/permissions/transfer.permissions';
import { filter, Subject, takeUntil } from 'rxjs';
import { selectUnreadDiscussionCount } from '../../../../core/message-management/state/selectors/discussion.selectors';
import { selectAllNotifications } from '../../../../core/notification/state/selectors/notification.selectors';

interface MenuItem {
	label: string;
	link?: string;
	icon?: string;
	activeLink?: boolean;
	subMenu?: MenuItem[];
}

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	standalone: true,
	imports: [
		NgClass,
		NgForOf,
		NgIf,
		RouterLink,
		TranslateModule,
		NgIconComponent,
		LogoutComponent,
	],
	providers: [
		provideIcons({
			heroUser,
			heroBars3,
			heroBell,
			heroEnvelope,
			heroCog8Tooth,
			heroArrowRightEndOnRectangle,
		}),
	],
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	isMenuOpen = false;
	isLogoutModalOpen = false;
	activeSubmenu: string | null = null;
	currentUrl: string = '';
	numberDiscussions: number = 0;
	numberNotifications: number = 0;
	@Output() menuClick = new EventEmitter<void>();
	menuItems: MenuItem[] = [
		{ label: 'HEADER.HOME', link: '/home' },
		{ label: 'HEADER.MY_ACCOUNT', link: '/account' },

		{ label: 'HEADER.BILL_PAYMENT', link: '/transfer/bill-payment' },
		// { label: "HEADER.BENEFICIARY", link: "/beneficiary" },
	];

	rightIcons: MenuItem[] = [
		{ label: 'Menu', icon: 'heroBars3', link: '' },
		// { label: "Profil", icon: "heroUser", link: "/settings" },
		// { label: "Paramètres", icon: "heroCog8Tooth", link: "/settings" },
		// { label: "Messages", icon: "heroEnvelope", link: "/message" },
		// { label: "Notifications", icon: "heroBell", link: "/message" },
		// { label: "Déconnexion", icon: "heroArrowRightEndOnRectangle", link: "" },
	];

	private readonly destroy$ = new Subject<void>();

	constructor(
		private readonly messagePermissions: MessagePermissions,
		private readonly notificationPermissions: NotificationPermissions,
		private readonly settingsPermissions: SettingsPermissions,
		private readonly beneficiaryPermissions: BeneficiaryPermissions,
		private readonly permissionsService: PermissionService,
		private readonly transferPermissions: TransferPermissions,
		private readonly router: Router,
		private readonly store: Store
	) {
		this.currentUrl = this.router.url;
	}

	ngOnInit(): void {
		this.subscribeToUnreadDiscussions();
		this.subscribeToNotifications();
		this.setupRightIcons();
		this.setupMenuItems();
		this.listenToRouteChanges();
	}

	private subscribeToUnreadDiscussions(): void {
		this.store.select(selectUnreadDiscussionCount).subscribe((count) => {
			this.numberDiscussions = Number(count);
			Logger.info('numberDiscussions', this.numberDiscussions);
		});
	}

	private subscribeToNotifications(): void {
		this.store.select(selectAllNotifications).subscribe((notification) => {
			this.numberNotifications = Number(
				notification.unreadNotificationCount
			);
			Logger.info('numberNotifications', this.numberNotifications);
		});
	}

	private setupRightIcons(): void {
		if (this.messagePermissions.canManageMessage) {
			this.rightIcons.push({
				label: 'HEADER.MESSAGES',
				icon: 'heroEnvelope',
				link: '/message',
			});
		}
		if (this.notificationPermissions.canManageNotification) {
			this.rightIcons.push({
				label: 'HEADER.NOTIFICATIONS',
				icon: 'heroBell',
				link: '/notification',
			});
		}
		if (this.settingsPermissions.canManageSettings) {
			this.rightIcons.push({
				label: 'HEADER.PARAMETRES',
				icon: 'heroCog8Tooth',
				link: '/settings',
			});
		}
		this.rightIcons.push({
			label: 'Déconnexion',
			icon: 'heroArrowRightEndOnRectangle',
			link: '',
		});
	}

	private setupMenuItems(): void {
		if (this.transferPermissions.canManageTransfer) {
			this.addTransferMenuItems();
		}
		if (this.beneficiaryPermissions.canManagePayee) {
			this.addBeneficiaryMenuItems();
		}
	}

	private addTransferMenuItems(): void {
		this.menuItems.push({
			label: 'HEADER.TRANSFERS',
			subMenu: [
				...(this.transferPermissions.canCreateTransfer
					? [
							{
								label: 'HEADER.NEW_TRANSFER',
								link: '/transfer',
							},
					  ]
					: []),
				{
					label: 'HEADER.PLANNED_TRANSFERS',
					link: '/transfer/planned',
				},
				{
					label: 'HEADER.HISTORY',
					link: '/transfer/history',
				},
				...(this.transferPermissions.canApproveTransfer
					? [
							{
								label: 'HEADER.REQUEST',
								subMenu: [
									{
										label: 'HEADER.REQUEST_LIST',
										link: '/transfer/request',
									},
									{
										label: 'HEADER.REQUEST_HISTORY',
										link: '/transfer/request/history',
									},
								],
							},
							{
								label: 'HEADER.APPROVAL',
								subMenu: [
									{
										label: 'HEADER.APPROVAL_LIST',
										link: '/transfer/approval',
									},
									{
										label: 'HEADER.APPROVAL_HISTORY',
										link: '/transfer/approval/history',
									},
								],
							},
					  ]
					: []),
			],
		});
	}

	private addBeneficiaryMenuItems(): void {
		this.menuItems.push({
			label: 'HEADER.BENEFICIARY',
			link: '/beneficiary',
			subMenu: [
				...(this.beneficiaryPermissions.canCreatePayee ||
				this.beneficiaryPermissions.canCreateCoporatePayee
					? [
							{
								label: 'HEADER.NEW_BENEFICIARY',
								link: '/beneficiary/new',
							},
					  ]
					: []),
				...(this.beneficiaryPermissions.canViewPayee ||
				this.beneficiaryPermissions.canViewCoporatePayee
					? [
							{
								label: 'HEADER.LIST_BENEFICIARY',
								link: '/beneficiary',
							},
					  ]
					: []),
				...(this.beneficiaryPermissions.canManagePayeeApproval
					? [
							{
								label: 'HEADER.REQUEST',
								subMenu: [
									{
										label: 'HEADER.REQUEST_LIST',
										link: '/beneficiary/request',
									},
									{
										label: 'HEADER.REQUEST_HISTORY',
										link: '/beneficiary/request/history',
									},
								],
							},
							{
								label: 'HEADER.APPROVAL',
								subMenu: [
									{
										label: 'HEADER.APPROVAL_LIST',
										link: '/beneficiary/approval',
									},
									{
										label: 'HEADER.APPROVAL_HISTORY',
										link: '/beneficiary/approval/history',
									},
								],
							},
					  ]
					: []),
			],
		});
	}

	private listenToRouteChanges(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			)
			.subscribe((event: NavigationEnd) => {
				this.currentUrl = event.urlAfterRedirects;
				this.activateMenu();
			});

		this.currentUrl = this.router.url;
		this.activateMenu();
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.activeSubmenu = null;
	}

	toggleSubmenu(label: string) {
		this.activeSubmenu = this.activeSubmenu === label ? null : label;
	}

	onIconClick(iconLabel: string) {
		if (iconLabel === 'Menu') {
			this.menuClick.emit();
		} else if (iconLabel === 'Déconnexion') {
			this.openLogoutModal();
		}
	}

	openLogoutModal() {
		this.isLogoutModalOpen = true;
	}
	closeLogoutModal() {
		this.isLogoutModalOpen = false;
		Logger.info('logout');
		this.permissionsService.clear();
	}

	activateMenu() {
		// 1. Réinitialiser tous les liens à false
		this.menuItems.forEach((menuItem) => {
			menuItem.activeLink = false; // Désactiver le lien principal
			menuItem.subMenu?.forEach((subItem) => {
				subItem.activeLink = false; // Désactiver tous les sous-liens
			});
		});

		// 2. Rechercher et activer le bon lien
		this.menuItems.forEach((menuItem) => {
			// Vérifier si un sous-lien correspond exactement à l'URL actuelle
			const foundSubItem = menuItem.subMenu?.find(
				(subItem) => subItem.link === this.currentUrl
			);
			if (foundSubItem) {
				foundSubItem.activeLink = true; // Activer le sous-lien
				menuItem.activeLink = true; // Activer le parent
			} else if (menuItem.link === this.currentUrl) {
				// Sinon, activer uniquement le lien principal
				menuItem.activeLink = true;
			}
		});
	}

	ngOnDestroy() {
		// Nettoyage pour éviter les fuites mémoire
		this.destroy$.next();
		this.destroy$.complete();
	}
}
