import { Message } from "../../domain/entities/message.entity";
import { MessageDTO } from "../dtos/message.dto";

export class MessageMapper {
    static toDTO(message: Message): MessageDTO {
        return {
            lastmodifiedts: message.lastmodifiedts,
            totalAttachments: message.totalAttachments,
            CustomerRequest_id: message.CustomerRequest_id,
            MessageDescription: message.MessageDescription,
            createdby_name: message.createdby_name,
            IsRead: message.IsRead,
            createdts: message.createdts,
            RepliedBy: message.RepliedBy,
            softdeleteflag: message.softdeleteflag,
            ReplySequence: message.ReplySequence,
            createdby: message.createdby,
            Message_id: message.Message_id,
            id: message.id,
            synctimestamp: message.synctimestamp,
        };
    }

    static toDomain(dto: MessageDTO): Message {
        return new Message(
            dto.lastmodifiedts,
            dto.totalAttachments,
            dto.CustomerRequest_id,
            dto.MessageDescription,
            dto.createdby_name,
            dto.IsRead,
            dto.createdts,
            dto.RepliedBy,
            dto.softdeleteflag,
            dto.ReplySequence,
            dto.createdby,
            dto.Message_id,
            dto.id,
            dto.synctimestamp,
        )
    }
}

