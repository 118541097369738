import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from './settings.reducers';

export const selectSettingsState =
	createFeatureSelector<SettingsState>('settings');

export const selectUserInfos = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.userInfos
);
export const selectUserImage = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.userProfileImage
);

export const selectUserImageLoading = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.loading
);

export const selectUserImageSuccess = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.success
);

export const selectSecurityQuestions = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.securityQuestions
);

export const selectMFAAttributes = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.mfaAttributes
);

export const selectIsOtpVerified = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.isOtpVerified
);

export const selectSettingsLoading = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.loading
);

export const selectIsSecurityQuestionsUpdated = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.isSecurityQuestionsUpdated
);

export const selectUser = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.user
);

export const selectUpdateUserStatus = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.update
);

export const selectIsPassordUpdated = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.isPassordUpdated
)

export const selectSettingsError = createSelector(
	selectSettingsState,
	(state: SettingsState) => state.error
)