import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FaqRepositoryImpl } from "./infrastructure/repositories/faq.repository.impl";
import { FaqEffects } from "./state/effects/faq.effects";
import { provideEffects } from "@ngrx/effects";


@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: 'IFaqRepository', useClass: FaqRepositoryImpl },
        provideEffects(FaqEffects)
    ]
})
export class CoreFaqModule { }