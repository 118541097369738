import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import  * as termsConditionAction from '../actions/terms-condition.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { GetPostLoginUseCase } from '../../application/use-cases/get-post-login.use-case';
import { CustomerSignUseCase } from '../../application/use-cases/customer-sign.use-case';

@Injectable()
export class TermsConditionEffects {
	getPostLoginAction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(termsConditionAction.getPostLoginAction),
			ofType(termsConditionAction.getFirstTimePostLoginAction),
			switchMap(({ request }) =>
				this.getPostLoginUseCase.execute(request).pipe(
					map((response) =>
						termsConditionAction.getPostLoginActionSuccess({response})
					),
					catchError((error) =>
						of(termsConditionAction.getPostLoginActionFailure({ error }))
					)
				)
			)
		);
	});

	createCustomerTNCAction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(termsConditionAction.createCustomerTNCAction),
			switchMap(({ request }) => {
				return this.CustomerSignUseCase.execute(request).pipe(
					map((response) =>
						termsConditionAction.createCustomerTNCActionSuccess({response})
					),
					catchError((error) =>
						of(termsConditionAction.createCustomerTNCActionFailure({ error }))
					)
				);
			})
		);
	});

	

	constructor(
		private readonly actions$: Actions,
		private readonly CustomerSignUseCase: CustomerSignUseCase,
		private readonly getPostLoginUseCase: GetPostLoginUseCase,
	) {}
}
