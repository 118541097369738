import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { MFAAttributesRequestDTO } from "../dtos/mfa-attributes.dto";

@Injectable({
    providedIn: 'root'
})
export class VerifyUpdateSecurityQuestionsOTPUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(request: MFAAttributesRequestDTO): Observable<{ success: string, isOtpVerified: string }> {
        return this.settingsRepository.verifyUpdateSecurityQuestionsOTP(request);
    }
}
