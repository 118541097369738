import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginUseCase } from './application/use-cases/login.usecase';
import { RegisterUseCase } from './application/use-cases/register.use-case';
import { LogoutUseCase } from './application/use-cases/logout.use-case';
import { AuthRepositoryImpl } from './infrastructure/repositories/auth.repository.impl';
import { ActivateAccountUseCase } from './application/use-cases/activate-account.use-case';
import { CreatePasswordUseCase } from './application/use-cases/create-password.use-case';
import { GetUserContractsUseCase } from './application/use-cases/get-user-contracts.use-case';
import { PasswordForgottenUseCase } from './application/use-cases/password-forgotten.use-case';
import { ResendOTPUseCase } from './application/use-cases/resend-otp.use-case';
import { ResetPasswordUseCase } from './application/use-cases/reset-password.use-case';
import { VerifyOTPUseCase } from './application/use-cases/verify-otp.use-case';
import { ResendActivateCodeUseCase } from './application/use-cases/resend-activate-code.use-case';
import { VerifyUserUseCase } from './application/use-cases/verify-user.use-case';
import { SetDefaultLanguageUseCase } from './application/use-cases/default-language.use-case';

@NgModule({
	imports: [CommonModule],
	providers: [
		{ provide: 'IAuthRepository', useClass: AuthRepositoryImpl },
		LoginUseCase,
		RegisterUseCase,
		LogoutUseCase,
		ActivateAccountUseCase,
		CreatePasswordUseCase,
		GetUserContractsUseCase,
		PasswordForgottenUseCase,
		ResendOTPUseCase,
		ResetPasswordUseCase,
		VerifyOTPUseCase,
		ResendActivateCodeUseCase,
		VerifyUserUseCase,
		SetDefaultLanguageUseCase
	],
})
export class CoreAuthenticationModule {}
