import { TransferDto } from '../dtos/transfer.dto';
import { Transfer } from '../../domain/entities/transfer.entity';

export class TransferMapper {
	static toDomain(transfer: TransferDto): Transfer {
		const transactionId = transfer.transactionId;
		const transactionReference = transfer.transactionReference ?? '';
		const debitAccount = transfer.debitAccount ?? '';
		const creditAccount = transfer.creditAccount ?? '';
		const transactionCurrency = transfer.transactionCurrency ?? '';
		const transactionAmount = transfer.transactionAmount;
		const paymentDetails = transfer.paymentDetails;
		const processingDate = transfer.processingDate ?? '';
		const debitValueDate = transfer.debitValueDate ?? '';
		const creditValueDate = transfer.creditValueDate ?? '';
		const debitAmount = transfer.debitAmount ?? '';
		const creditAmount = transfer.creditAmount ?? '';
		const waiveDebitCharges = transfer.waiveDebitCharges ?? '';
		const waiveCreditCharges = transfer.waiveCreditCharges ?? '';
		const beneficiaryName = transfer.beneficiaryName ?? '';
		const motifEconomique = transfer.motifEconomique ?? '';
		const bankCode = transfer.bankCode ?? '';
		const branchCode = transfer.branchCode ?? '';
		const beneficiaryRIB = transfer.beneficiaryRIB ?? '';
		const orderingCustomerBank = transfer.orderingCustomerBank ?? '';
		const commissionType = transfer.commissionType ?? '';
		const transactionType = transfer.transactionType ?? '';
		const totalCharge = transfer.totalCharge ?? '';
		const commissionAmount = transfer.commissionAmount ?? '';
		const status = transfer.status ?? '';

		return new Transfer(
			transactionId,
			transactionReference,
			debitAccount,
			creditAccount,
			transactionCurrency,
			transactionAmount,
			paymentDetails,
			processingDate,
			debitValueDate,
			creditValueDate,
			debitAmount,
			creditAmount,
			waiveDebitCharges,
			waiveCreditCharges,
			beneficiaryName,
			motifEconomique,
			bankCode,
			branchCode,
			beneficiaryRIB,
			orderingCustomerBank,
			commissionType,
			transactionType,
			totalCharge,
			commissionAmount,
			status
		);
	}
}