import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { RequestUpdateSecurityQuestionsOTPRequestDTO } from "../../../core-banking/application/dtos/settings/security-question.dto";
import { Logger } from "../../../../shared/helpers/logger-helper";
import { MFAAttributes } from "../../domain/entities/mfa-attributes.entity";

@Injectable({
    providedIn: 'root'
})
export class RequestUpdateSecurityQuestionsOTPUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(request: RequestUpdateSecurityQuestionsOTPRequestDTO): Observable<MFAAttributes> {
        const mfa  = this.settingsRepository.requestUpdateSecurityQuestionsOTP(request);
        Logger.debug('RequestUpdateSecurityQuestionsOTPUseCase MFAAttributes: ', mfa);
        return mfa;
    }
}
