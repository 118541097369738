import { Injectable } from '@angular/core';
import { PermissionCodes } from './codes/permissions.code';
import { FeatureCodes } from './codes/features.codes';
import { PermissionService } from '../services/permission..service';

@Injectable({
  providedIn: 'root',
})
export class NotificationPermissions {
    constructor(private readonly permissionService: PermissionService) {}

    get canManageNotification(): boolean {
        return this.permissionService.hasFeature(FeatureCodes.NOTIFICATION);
    }

    private hasNotificationAccess(requiredPermission: string): boolean {
        return (
            this.canManageNotification &&
            this.permissionService.hasPermission(requiredPermission)
        );
    }

    get canDeleteNotification(): boolean {
        return this.hasNotificationAccess(PermissionCodes.NOTIFICATION_DELETE);
    }
    get canUpdateNotification(): boolean {
        return this.hasNotificationAccess(PermissionCodes.NOTIFICATION_UPDATE);
    }
    get canViewNotification(): boolean {
        return this.hasNotificationAccess(PermissionCodes.NOTIFICATION_VIEW);    
    }
}
