import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { TermsAndConditions } from '../../domain/entities/terms-and-conditions.entity';

@Injectable({
	providedIn: 'root',
})
export class TermsAndConditionsUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(): Observable<TermsAndConditions> {
		return this.authRepository.termsAndConditions();
	}
}
