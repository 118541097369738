import { map, Observable } from 'rxjs';
import { FaqsService } from '../../../core-banking/application/services/faqs/faqs.service';
import { IFaqRepository } from '../../domain/repositories/faq.repositoty';
import { FaqCategory } from '../../domain/entities/faqs.entity';
import { Injectable } from '@angular/core';
import { FaqsMapper } from '../../application/mappers/faqs.mapper';

@Injectable({
	providedIn: 'root',
})
export class FaqRepositoryImpl implements IFaqRepository {
	constructor(private readonly faqService: FaqsService) {}

	getFaqs(): Observable<FaqCategory[]> {
		return this.faqService
			.getFaqs()
			.pipe(
				map((response) => response.map((pdo) => FaqsMapper.toDomain(pdo)))
			);
	}
}
