import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { LanguageRequestDTO, LanguageResponsePDO } from '../dtos/authentication.dto';

@Injectable({
	providedIn: 'root',
})
export class SetDefaultLanguageUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(data: LanguageRequestDTO): Observable<LanguageResponsePDO> {
		return this.authRepository.setDefaultLanguage(data);
	}
}
