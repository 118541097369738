import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransferHistory } from '../../domain/entities/transfer-history.entity';

@Injectable({ providedIn: 'root' })
export class GetTransferHistoryUseCase {
	constructor(private readonly transferRepository: ITransferRepository) {}

	execute(): Observable<TransferHistory[]> {
		return this.transferRepository.getTransferHistory();
	}
}
