import { Injectable } from "@angular/core";
import { GetAllPendingApprovalsUseCase } from "../../application/use-cases/approval-request/get-all-pending-approvals.use-case";
import { GetAllPendingRequestsUseCase } from "../../application/use-cases/approval-request/get-all-pending-requests.use-case";
import { GetApprovalHistoryUseCase } from "../../application/use-cases/approval-request/get-approval-history.use-case";
import { GetRequestHistoryUseCase } from "../../application/use-cases/approval-request/get-request-history.use-case";
import { ApproveRequestUseCase } from "../../application/use-cases/approval-request/approve-request.use-case";
import { RejectRequestUseCase } from "../../application/use-cases/approval-request/reject-request.use-case";
import { WithdrawRequestUseCase } from "../../application/use-cases/approval-request/withdraw-request.use-case";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import * as ApprovalRequestActions from '../actions/approval-request.action';

@Injectable()
export class ApprovalRequestEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly getAllPendingApprovalsUseCase: GetAllPendingApprovalsUseCase,
        private readonly getAllPendingRequestsUseCase: GetAllPendingRequestsUseCase,
        private readonly getApprovalHistoryUseCase: GetApprovalHistoryUseCase,
        private readonly getRequestHistoryUseCase: GetRequestHistoryUseCase,
        private readonly approveRequestUseCase: ApproveRequestUseCase,
        private readonly rejectRequestUseCase: RejectRequestUseCase,
        private readonly withdrawRequestUseCase: WithdrawRequestUseCase
    ) { }

    getAllPendingApprovals$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.getAllPendingApprovalsAction),
        switchMap(({ request }) => this.getAllPendingApprovalsUseCase.execute(request).pipe(
            map((approvals) => ApprovalRequestActions.getAllPendingApprovalsActionSuccess({ approvals })),
            catchError((error) => of(ApprovalRequestActions.getAllPendingApprovalsActionFailure({ error })))
        ))
    ))

    getAllPendingRequests$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.getAllPendingRequestsAction),
        switchMap(({ request }) => this.getAllPendingRequestsUseCase.execute(request).pipe(
            map((requests) => ApprovalRequestActions.getAllPendingRequestsActionSuccess({ requests })),
            catchError((error) => of(ApprovalRequestActions.getAllPendingRequestsActionFailure({ error })))
        ))
    ))

    getRequestHistory$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.getRequestHistoryAction),
        switchMap(({ request }) => this.getRequestHistoryUseCase.execute(request).pipe(
            map((requestHistory) => ApprovalRequestActions.getRequestHistoryActionSuccess({ requestHistory })),
            catchError((error) => of(ApprovalRequestActions.getRequestHistoryActionFailure({ error })))
        ))
    ))

    getApprovalHistory$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.getApprovalHistoryAction),
        switchMap(({ request }) => this.getApprovalHistoryUseCase.execute(request).pipe(
            map((approvalHistory) => ApprovalRequestActions.getApprovalHistoryActionSuccess({ approvalHistory })),
            catchError((error) => of(ApprovalRequestActions.getApprovalHistoryActionFailure({ error })))
        ))
    ))  

    approveRequest$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.approveRequestAction),
        switchMap(({ requestId }) => this.approveRequestUseCase.execute(requestId).pipe(
            map((actionResponse) => ApprovalRequestActions.approveRequestActionSuccess({ actionResponse })),
            catchError((error) => of(ApprovalRequestActions.approveRequestActionFailure({ error })))
        ))
    ))

    rejectRequest$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.rejectRequestAction),
        switchMap(({ requestId }) => this.rejectRequestUseCase.execute(requestId).pipe(
            map((actionResponse) => ApprovalRequestActions.rejectRequestActionSuccess({ actionResponse })),
            catchError((error) => of(ApprovalRequestActions.rejectRequestActionFailure({ error })))
        ))
    ))

    withdrawRequest$ = createEffect(() => this.actions$.pipe(
        ofType(ApprovalRequestActions.withdrawRequestAction),
        switchMap(({ requestId }) => this.withdrawRequestUseCase.execute(requestId).pipe(
            map((actionResponse) => ApprovalRequestActions.withdrawRequestActionSuccess({ actionResponse })),
            catchError((error) => of(ApprovalRequestActions.withdrawRequestActionFailure({ error })))
        ))
    ))
}