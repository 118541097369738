import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { INotificationRepository } from '../../domain/repositories/notification.repository';

@Injectable({
	providedIn: 'root',
})
export class NotificationCountUseCase {
	constructor(
		@Inject('INotificationRepository')
		private readonly notificationRepository: INotificationRepository
	) {}

	execute(): Observable<{unreadNotificationCount: string;}[]> {
		return this.notificationRepository.getNotificationCount();
	}
}
