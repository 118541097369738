import { createAction, props } from "@ngrx/store";
import { Partner } from "../../domain/entities/partner.entity";
import { PartnerService } from "../../domain/entities/partner-service.entity";
import { PartnerServiceField } from "../../domain/entities/partner-service-field.entity";

// Load dPartners
export const loadPartners = createAction('[Partner] Load Partners');
export const loadPartnersSuccess = createAction(
    '[Partner] Load Partners Success',
    props<{ partners: Partner[] }>()
);
export const loadPartnersFailure = createAction(
    '[Partner] Load Partners Failure',
    props<{ error: any }>()
);

// Load Services By Partner Id
export const loadServicesByPartnerId = createAction(
    "[Load Services By Partner Id] Load Services By Partner Id",
    props<{ partnerId: number }>()
);
export const loadServicesByPartnerIdSuccess = createAction(
    "[Load Services By Partner Id] Load Services By Partner Id Success",
    props<{ partnerServices: PartnerService[] }>()
);
export const loadServicesByPartnerIdFailure = createAction(
    "[Load Services By Partner Id] Load Services By Partner Id Failure",
    props<{ error: any }>()
);

// Initiate Partner Service
export const initiatePartnerService = createAction(
    "[Initiate Partner Service] Initiate Partner Service",
    props<{ serviceId: number, stepNum: number, payload: any }>()
);
export const initiatePartnerServiceSuccess = createAction(
    "[Initiate Partner Service] Initiate Partner Service Success",
    props<{ partnerServiceFields: PartnerServiceField[] }>()
);
export const initiatePartnerServiceFailure = createAction(
    "[Initiate Partner Service] Initiate Partner Service Failure",
    props<{ error: any }>()
);