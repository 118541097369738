export class UserInfos {
	constructor(
		public addressLine1: string,
		public alertsTurnedOn: string,
		public bankName: string,
		public dateOfBirth: string,
		public phone: string,
		public email: string,
		public city: string,
		public zipcode: string,
		public isPinSet: string,
		public lastlogintime: string,
		public userfirstname: string,
		public userId: string,
		public userlastname: string,
		public userName: string,
		public LastName: string,
		public isWireTransferActivated: string,
		public isWireTransferEligible: string,
		public showBillPayFromAccPopup: string,
		public isP2PActivated: string,
		public isBillPaySupported: string,
		public isBillPayActivated: string,
		public feedbackUserId: string,
		public isActivationLinkSent: string,
		public customerStatus: string,
		public isCustomerEnrolled: string,
		public isAssociated: string,
		public legalEntityId: string,
		public Addresses: Address[],
		public ContactNumbers: ContactNumber[],
		public EmailIds: EmailId[],
		public CoreCustomers: CoreCustomer[]
	) {}
}

export class Address {
	constructor(
		public Address_id: string,
		public AddressLine1: string,
		public ZipCode: string,
		public AddressType: string,
		public CityName: string,
		public City_id: string,
		public isPrimary: string
	) {}
}

export class ContactNumber {
	constructor(
		public id: string,
		public Type_id: string,
		public isPrimary: string,
		public Value: string,
		public phoneNumber: string,
		public Extension: string
	) {}
}

export class EmailId {
	constructor(
		public id: string,
		public Type_id: string,
		public isPrimary: string,
		public Value: string
	) {}
}

export class CoreCustomer {
	constructor(
		public coreCustomerID: string,
		public coreCustomerName: string,
		public isBusiness: string,
		public isPrimary: string,
		public contractId: string,
		public contractName: string
	) {}
}
