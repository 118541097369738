export class DomainError extends Error {
    constructor(
        public readonly code: string,
        message: string,
        public readonly status?: number,
        public readonly originalError?: any
    ) {
        super(message);
        this.name = this.constructor.name;
    }
}
