import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from '../../../core-banking/application/services/auth/auth.service';
import { AuthTokenService } from '../../application/services/auth-token.service';
import { Store } from '@ngrx/store';
import { logout } from '../../state/actions/auth.actions';
import { IdleService } from '../../../../shared/services/idle.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private readonly refreshTokenSubject: BehaviorSubject<any> =
		new BehaviorSubject<any>(null);

	constructor(
		private readonly tokenService: AuthTokenService,
		private readonly authService: AuthService,
		private readonly store: Store,
		private readonly idleService: IdleService
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const token = this.tokenService.getToken();
		const legalEntity = this.tokenService.getLegalEntity();
		if (token) {
			request = this.addToken(request, token);
		}
		if (
			legalEntity &&
			!request.url.includes('provider=DbxUserLogin') &&
			!request.url.includes('SecureMessaging') &&
			!request.url.includes('Notifications') &&
			!request.url.includes('Alerts/getCategories') &&
			!request.url.includes('Campaigns')
		) {
			request = this.addCompanyId(request, legalEntity);
		}

		if (legalEntity && request.url.includes('objects/UnreadNotifications')) {
			request = this.addPatchMethod(request);
		}

		return next.handle(request).pipe(
			catchError((error) => {
				//Deconnexion s'il y a une nouvelle connexion sur un autre devices pour la même personne
				if (
					error.status === 401 &&
					error.error.opstatus === 17005 &&
					error.error.mfcode == 'Auth-55'
				) {
					// Redirige vers la page de connexion
					this.idleService.showDisconnectionMessage();
					this.store.dispatch(logout());
				}

				return throwError(() => error);
			})
		);
	}

	private addToken(request: HttpRequest<any>, token: string) {
		return request.clone({
			setHeaders: {
				'Content-Type': 'application/json',
				'X-Kony-Authorization': `${token}`,
			},
		});
	}

	private addPatchMethod(request: HttpRequest<any>) {
		return request.clone({
			setHeaders: {
				'X-Kony-Reportingparams':
					'{"os":"127.0.0.0","dm":"","did":"C06A7067-2BD1-4CB2-95B9-8EFF4BFF22FB","ua":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36","aid":"OnlineBanking","aname":"OnlineBanking","chnl":"desktop","plat":"web","aver":"1.0.0","atype":"spa","stype":"b2c","kuid":"","mfaid":"f04b62f1-7adc-4ea4-a870-f1fa87d523c6","mfbaseid":"7bf99ab8-7232-45a4-a324-b23c3f0a7b28","mfaname":"DigitalBanking-Composite","sdkversion":"202404.0.0","sdktype":"js","fid":"frmEnrollNow","sessiontype":"I","clientUUID":"1723460594231-d8dc-7125-5e72","rsid":"1723460600199-c40f-f30a-3098","svcid":"ExternalUsers_1"}',
				'X-http-method-override': 'PATCH',
			},
		});
	}

	private addCompanyId(request: HttpRequest<any>, companyId: string) {
		return request.clone({
			setHeaders: {
				companyId: `${companyId}`,
			},
		});
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			const refreshToken = this.tokenService.getRefreshToken();
			return this.authService.refreshToken(refreshToken).pipe(
				switchMap((token: any) => {
					this.isRefreshing = false;
					this.refreshTokenSubject.next(token);
					return next.handle(this.addToken(request, token));
				}),
				catchError((err) => {
					this.isRefreshing = false;
					this.authService.logout();
					return throwError(() => err);
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((jwt) => {
					return next.handle(this.addToken(request, jwt));
				})
			);
		}
	}
}
