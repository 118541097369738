import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as BeneficiaryActions from '../actions/beneficiary.actions';
import { GetBeneficiariesUseCase } from '../../application/use-cases/get-beneficiaries.use-case';
import { CreateBeneficiaryUseCase } from '../../application/use-cases/create-beneficiary.use-case';
import { UpdateBeneficiaryUseCase } from '../../application/use-cases/update-beneficiary.use-case';
import { GetPayeeNameUseCase } from '../../application/use-cases/get-payee-name.use-case';
import { DeleteBeneficiaryUseCase } from '../../application/use-cases/delete-beneficiary.use-case';
import { GetBeneficiaryUseCase } from '../../application/use-cases/get-beneficiary.use-case';

@Injectable()
export class BeneficiaryEffects {
	loadBeneficiaries$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.loadBeneficiaries),
			switchMap(() =>
				this.getBeneficiariesUseCase.execute().pipe(
					map((beneficiaries) =>
						BeneficiaryActions.loadBeneficiariesSuccess({ beneficiaries })
					),
					catchError((error) =>
						of(BeneficiaryActions.loadBeneficiariesFailure({ error }))
					)
				)
			)
		)
	);

	createBeneficiary$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.createBeneficiary),
			mergeMap(({ beneficiary }) =>
				this.createBeneficiaryUseCase.execute(beneficiary).pipe(
					map((createdBeneficiary) =>
						BeneficiaryActions.createBeneficiarySuccess({
							beneficiary: createdBeneficiary,
						})
					),
					catchError((error) => {
						console.error('createBeneficiary$ :', error);
						return of(
							BeneficiaryActions.createBeneficiaryFailure({ error })
						);
					})
				)
			)
		)
	);

	updateBeneficiary$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.updateBeneficiary),
			mergeMap(({ beneficiary }) =>
				this.updateBeneficiaryUseCase.execute(beneficiary).pipe(
					switchMap((updatedBeneficiary) => [
						BeneficiaryActions.loadBeneficiaries(),
						BeneficiaryActions.updateBeneficiarySuccess({
							beneficiary: updatedBeneficiary,
						}),
					]),
					catchError((error) =>
						of(BeneficiaryActions.updateBeneficiaryFailure({ error }))
					)
				)
			)
		)
	);

	deleteBeneficiary$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.deleteBeneficiary),
			mergeMap(({ beneficiary }) =>
				this.deleteBeneficiaryUseCase.execute(beneficiary).pipe(
					map((deleteBeneficiary) =>
						BeneficiaryActions.deleteBeneficiarySuccess({
							beneficiary: deleteBeneficiary,
						})
					),
					catchError((error) =>
						of(BeneficiaryActions.deleteBeneficiaryFailure({ error }))
					)
				)
			)
		)
	);

	getPayeeName$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.getPayeeName),
			mergeMap(({ accountNumber }) =>
				this.getPayeeNameUseCase.execute(accountNumber).pipe(
					map((payeeName) =>
						BeneficiaryActions.getPayeeNameSuccess({
							accountNumber,
							payeeName,
						})
					),
					catchError((error) =>
						of(BeneficiaryActions.getPayeeNameFailure({ error }))
					)
				)
			)
		)
	);
	getBeneficiary$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BeneficiaryActions.getBeneficiary),
			mergeMap(({ accountNumber }) =>
				this.getBeneficiaryUseCase.execute(accountNumber).pipe(
					map((beneficiary) =>
						BeneficiaryActions.getBeneficiarySuccess({
							beneficiary,
						})
					),
					catchError((error) =>
						of(BeneficiaryActions.getPayeeNameFailure({ error }))
					)
				)
			)
		)
	);

	constructor(
		private readonly actions$: Actions,
		private readonly getBeneficiariesUseCase: GetBeneficiariesUseCase,
		private readonly createBeneficiaryUseCase: CreateBeneficiaryUseCase,
		private readonly updateBeneficiaryUseCase: UpdateBeneficiaryUseCase,
		private readonly deleteBeneficiaryUseCase: DeleteBeneficiaryUseCase,
		private readonly getPayeeNameUseCase: GetPayeeNameUseCase,
		private readonly getBeneficiaryUseCase: GetBeneficiaryUseCase
	) {}
}
