import { Logger } from "../../../../shared/helpers/logger-helper";
import { CommunicationDetail, CustomerCommunication, MFAAttributes } from "../../domain/entities/mfa-attributes.entity";
import { MFAAttributesDTO } from "../dtos/mfa-attributes.dto";

export class MFAAttributesMapper {
  static toEntity(dto: MFAAttributesDTO): MFAAttributes {
    Logger.info('MFAAttributesMapper toEntity dto', dto);
    const entity = new MFAAttributes(
        dto.securityKey,
        dto.sacMaxResendRequestsAllowed,
        dto.remainingResendAttempts,
        dto.sacCodeLength,
        dto.communicationType,
        dto.sacPreferenceCriteria,
        new CustomerCommunication(
          dto.customerCommunication.phone.map(
            (phone) => new CommunicationDetail(phone.masked, phone.unmasked, phone.referenceId)
          ),
          dto.customerCommunication.email.map(
            (email) => new CommunicationDetail(email.masked, email.unmasked, email.referenceId)
          )
        ),
        dto.isMFARequired,  // Convertir en boolean si nécessaire: dto.isMFARequired === 'true',
        dto.MFAType,
        dto.serviceKey,
        dto.successMessage
    );
    Logger.info('MFAAttributesMapper toEntity entity', entity);
    return entity;
  }

  static toDTO(entity: MFAAttributes): MFAAttributesDTO {
    const dto: MFAAttributesDTO = {
      securityKey: entity.securityKey,
      sacMaxResendRequestsAllowed: entity.sacMaxResendRequestsAllowed,
      remainingResendAttempts: entity.remainingResendAttempts,
      sacCodeLength: entity.sacCodeLength,
      communicationType: entity.communicationType,
      sacPreferenceCriteria: entity.sacPreferenceCriteria,
      customerCommunication: {
        phone: entity.customerCommunication.phone.map((phone) => ({
          masked: phone.masked,
          unmasked: phone.unmasked,
          referenceId: phone.referenceId,
        })),
        email: entity.customerCommunication.email.map((email) => ({
          masked: email.masked,
          unmasked: email.unmasked,
          referenceId: email.referenceId,
        })),
      },
      isMFARequired: entity.isMFARequired,  // Convertir en string si nécessaire: String(entity.isMFARequired),
      MFAType: entity.MFAType,
      serviceKey: entity.serviceKey,
      successMessage: entity.successMessage,
    };

    Logger.info('MFAAttributesMapper toDTO dto', dto);
    return dto;
  }
}