import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { EnvironmentService } from './shared/services/environment.service';
import { Logger } from './shared/helpers/logger-helper';
import { Store } from '@ngrx/store';
import { setDefaultLanguage as setDefaultLanguageAction } from './core/authentication/state/actions/auth.actions';
import { selectAuthState, selectUser } from './core/authentication/state/selectors/auth.selectors';
import { Subject, takeUntil } from 'rxjs';
import { PermissionService } from './shared/services/permission..service';
import { AuthTokenService } from './core/authentication/application/services/auth-token.service';
import { selectTermsConditionState } from './core/terms-condition/state/selectors/terms-condition.selectors';


@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'mybridge-touch-web';
	private readonly destroy$ = new Subject<void>();
	private isLangSet = false;

	constructor(
		private readonly translate: TranslateService,
		private readonly renderer: Renderer2,
		private readonly envService: EnvironmentService,
		private readonly store: Store,
		private readonly permissionService: PermissionService,
		private readonly tokenService: AuthTokenService,
		private readonly router: Router

	) {
		this.setDefaultLanguage();
	}

	ngOnInit() {
		this.loadGoogleMapsScript();
		const isLogged = this.tokenService.isLogged();

		this.store.select(selectUser).subscribe((user) => {
			if (user) {
				this.permissionService.setFeatures(user.features);
				this.permissionService.setPermissions(user.permissions);
			}
		})

		// Verification d'acceptation des conditions d'utilisation ou redirection
		if (isLogged ) {
			this.store
			.select(selectTermsConditionState)
			.pipe(takeUntil(this.destroy$))
			.subscribe((state) => {
				const allowStates = [
					'User already signed',
					'true'
				];

				if (
					!state.loading &&
					state.alreadySigned &&
					!allowStates.includes(state.alreadySigned)
				) {
					this.router.navigate(['/auth/self-enrollment/terms-conditions']);
				}
			});
		}

	}

	setDefaultLanguage() {
		const storedLang = localStorage.getItem('selectedLanguage');
		const browserLang = this.translate.getBrowserLang() ?? '';
		const supportedLangs = ['en', 'fr'];

		const defaultLang =
			storedLang ??
			(supportedLangs.includes(browserLang)
				? browserLang
				: environment.defaultLang);

		this.translate.setDefaultLang(defaultLang);
		const lang = defaultLang === 'fr' ? 'French' : 'US-English';
		this.setAPILanguage(lang);
	}

	setAPILanguage(lang: string) {
		this.store
			.select(selectAuthState)
			.pipe(takeUntil(this.destroy$))
			.subscribe((authstate) => {
				Logger.info('App auth :', authstate);
				if (
					authstate.isAuthenticated &&
					!authstate.isLanguageSet &&
					!this.isLangSet
				) {
					Logger.info('App this.isLangSet :', this.isLangSet);
					this.store.dispatch(
						setDefaultLanguageAction({
							data: {
								defaultLanguage: lang,
							},
						})
					);
					this.isLangSet = true;
				}
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private loadGoogleMapsScript(): void {
		const apiKey = this.envService.googleMapsApiKey;

		if (!apiKey) {
			Logger.error('Google Maps API key is missing.');
		}

		const script = this.renderer.createElement('script');
		const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&callback=initMap&v=weekly`;
		script.src = src;
		script.async = true;
		script.defer = true;

		this.renderer.appendChild(document.head, script);
	}
}
