import { NgForOf } from '@angular/common';
import {
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-star-rating',
	standalone: true,
	imports: [NgForOf],
	templateUrl: './star-rating.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StarRatingComponent),
			multi: true,
		},
	],
})
export class StarRatingComponent implements OnChanges {
	@Input() rating: number = 0;
	@Output() ratingChange = new EventEmitter<number>();

	stars: number[] = [1, 2, 3, 4, 5];

	setRating(value: number) {
		this.rating = value;
		this.ratingChange.emit(this.rating);
	}

	writeValue(value: number | null): void {
		if (value) {
			this.setValue(value);
		}
	}

	onChange = (_: any) => {};
	onTouch = () => {};

	private setValue(values: number): void {
		this.rating = values;
		this.onChange(this.rating);
		this.onTouch();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['rating']) {
			this.ratingChange.emit(this.rating);
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
}
