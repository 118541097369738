import { TransferInstructions } from "../../domain/entities/transfer-instructions.entity";
import { TransferInstructionsDTO } from "../dtos/transfer.dto";

export class TransferInstructionsMapper {
  static toDomain(transfer: TransferInstructionsDTO): TransferInstructions {
	const {
	  transactionId = '',
	  amount = '',
	  description = '',
	  frequencyEndDate = '',
	  frequencyStartDate = '',
	  frequencyType = '',
	  fromAccountName = '',
	  fromAccountNumber = '',
	  isScheduled = '',
	  toAccountName = '',
	  toAccountNumber = '',
	  transactionsNotes = '',
	  transactionType = '',
	  transactionCurrency = '',
	  payeeCurrency = '',
	  serviceName = '',
	  statusDescription = '',
	  pendingApproval,
	} = transfer;

	return new TransferInstructions(
	  transactionId,
	  amount,
	  description,
	  frequencyEndDate,
	  frequencyStartDate,
	  frequencyType,
	  fromAccountName,
	  fromAccountNumber,
	  isScheduled,
	  toAccountName,
	  toAccountNumber,
	  transactionsNotes,
	  transactionType,
	  transactionCurrency,
	  payeeCurrency,
	  serviceName,
	  statusDescription,
	  pendingApproval,
	);
  }
}
