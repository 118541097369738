import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HubPaymentApiService {
	private readonly apiUrl = environment.hubPaymentUrl;
	private readonly loginUrl = environment.hubPaymentLoginUrl;
	private readonly username = environment.hubPaymentUsername;
	private readonly password = environment.hubPaymentPassword;

	constructor(private readonly http: HttpClient) {}

	private getBasicAuthHeaders(): HttpHeaders {
		const basicAuthToken = btoa(`${this.username}:${this.password}`);
		return new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: `Basic ${basicAuthToken}`,
		});
	}

	private getAuthorizationHeaders(token?: string): HttpHeaders {
		const tokenValue =
			'eyJ4NXQiOiJOakU0WWpZek56WXlNVEJrTkdGaFpXWTJaREZtWVRZeU56Z3hNak15TlRRNFpqaGlaalpoT1EiLCJraWQiOiJORFF6WXpabU0ySXpaREl4WkdabE0yTmlOVFZpWmpabU5UVmpaVGM1TkdNNVl6RmxaRGd4TkRWbFpXRTVZMkl6TURnMU0yVTBZVEl4TmpSbFpEbGpaUV9SUzI1NiIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJhZG1pbiIsImF1dCI6IkFQUExJQ0FUSU9OIiwiYXVkIjoidFM2RU9Md1lCWlFjN2pyamVQZUVrRkRrTzZzYSIsIm5iZiI6MTcyODQwODI0MCwiYXpwIjoidFM2RU9Md1lCWlFjN2pyamVQZUVrRkRrTzZzYSIsInNjb3BlIjoiZGVmYXVsdCIsImlzcyI6Imh0dHBzOlwvXC9CQkdQUE1ETEFNRVhUMDE6OTQ0M1wvb2F1dGgyXC90b2tlbiIsImV4cCI6MTcyODQxMTg0MCwiaWF0IjoxNzI4NDA4MjQwLCJqdGkiOiIyODI4N2NkMS03ODExLTQ3MmMtYjgyMC00M2YxZjM5OTZiM2YifQ.WleMu4q7Dv2gNx-H_mRe6fPL_Js_DyM9Au4HdxlDa8k2nfJ1v8yfO4ol5spX1-MrTDuW04GoMOBnf7a_fX2591fqSLum3i-Lj9VbSQcwPCq1lqLDyYMIiUVh4k3xtcBMQNAoLHS2CxtSSLgS013IPiolvnJs4D53orNJ7nYVjnT6dEGSaS6q-1r7n-4dCDVWBjOA75_BY6jnmRSUR_4l20XsmOYdEPLTy9n8U405r7no06GincZU7s6NrCbpcjCy4IeMM38gv-jlBA-qc6Eirk9N80UpBJmkONgbAXThLGTz5xA3ihzHH-3WZqU-o6_fCdQ0j6wNFtYDX8X5kn9LxZ7LWuwOoRIC6mmH71y29aWZkVv1oA6vpZAiQSpoZlSai-y9UQB_6ymZP-Q86yiZv9-ToJGMR2v2JTTDQjug32oQ1S9mv-VocP7YXy_0cZfMxHjbyoESN0mgivyGMlVGmG2ZF-Oimt-U7kz5SB_D09445wezL3sjMePGbC0A2rdKIlbaS8Xxg7_W37gp7BxsHopNRxLSrNyaQ-ldFkmevSWgdCGtRobaMYFE2twZCYzIoghTuU1vUEqywoezvtXM2XobyNKvvDNJuKeMVBxVxewW54fdZY1Zmweh9FMig8DJ14KEO9HCLgTx9VUx9WsPKoQJ7TOXgpmd_2WWXv3fcOs';
		return new HttpHeaders({
			Authorization: `Bearer ${token ?? tokenValue}`,
		});
	}

	getAccessToken(): Observable<any> {
		const body = new HttpParams().set('grant_type', 'client_credentials');

		return this.http.post(this.loginUrl, body.toString(), {
			headers: this.getBasicAuthHeaders(),
		});
	}

	get<T>(endpoint: string): Observable<T> {
		return this.http.get<T>(`${this.apiUrl}${endpoint}`, {
			headers: this.getAuthorizationHeaders(),
		});
	}

	post<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getAuthorizationHeaders(),
		});
	}
}
