import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { CampaignEffects } from './state/campaign.effects';
import { CampaignRepositoryImpl } from './infrastructure/repositories/campaign.repository.impl';
import { CampaignService } from '../core-banking/application/services/campaign/campaign.service';

@NgModule({
	imports: [CommonModule],
	providers: [
		provideEffects(CampaignEffects),
		{ provide: 'ICampaignRepository', useClass: CampaignRepositoryImpl },
		{ provide: 'ICampaignService', useClass: CampaignService },
	],
})
export class CoreCampaignManagementModule {}
