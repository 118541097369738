import { Observable } from 'rxjs';
import { ITransactionRepository } from '../../domain/repositories/transaction.repository';
import { Transaction } from '../../domain/entities/transaction.entity';
import { inject, Injectable } from '@angular/core';
import { ITransactionService } from '../../../core-banking/domain/interfaces/transaction-service.interface';
import { map } from 'rxjs/operators';
import { TransactionMapper } from '../../application/mappers/transaction.mapper';
import {
	TransactionRequestDTO,
	SearchTransactionsRequestDTO,
} from '../../application/dtos/transaction.dto';
import { LoanTransaction } from '../../domain/entities/loan-transaction.entity';
import { SearchTransaction } from '../../domain/entities/search-transaction.entity';
import { SearchTransactionMapper } from '../../application/mappers/search-transaction.mapper';

@Injectable({
	providedIn: 'root',
})
export class TransactionRepositoryImpl implements ITransactionRepository {
	transactionService = inject(ITransactionService);

	getUserScheduledTransactions(): Observable<Transaction[]> {
		return this.transactionService.getUserScheduledTransactions().pipe(
			map(({ Transactions }) => {
				return Transactions.map((transaction) =>
					TransactionMapper.toDomain(transaction)
				);
			})
		);
	}

	getRecentTransactions(
		request: TransactionRequestDTO
	): Observable<Transaction[]> {
		return this.transactionService.getRecentTransactions(request).pipe(
			map(({ Transactions }) => {
				return Transactions.map((transaction) =>
					TransactionMapper.toDomain(transaction)
				);
			})
		);
	}

	getLoanTransactions(
		request: TransactionRequestDTO
	): Observable<LoanTransaction[]> {
		return this.transactionService.getLoanTransactions(request).pipe(
			map(({ Transactions }) => {
				return Transactions;
			})
		);
	}

	searchTransactions(
		request: SearchTransactionsRequestDTO
	): Observable<SearchTransaction[]> {
		return this.transactionService.searchTransactions(request).pipe(
			map(({ Transactions }) => {
				return Transactions.map((transaction) =>
					SearchTransactionMapper.toDomain(transaction)
				);
			})
		);
	}
}
