import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBillRepository } from '../../domain/repositories/bill.repository';
import { PartnerServiceField } from '../../domain/entities/partner-service-field.entity';

@Injectable({
	providedIn: 'root',
})
export class InitiatePartnerServiceUseCase {
	constructor(
		@Inject('IBillRepository')
		private readonly billRepository: IBillRepository
	) {}

	execute(
		serviceId: number,
		stepNum: number,
		payload: any
	): Observable<PartnerServiceField[]> {
		return this.billRepository.initiatePartnerService(
			serviceId,
			stepNum,
			payload
		);
	}
}
