import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';

@Injectable({ providedIn: 'root' })
export class GetBankDateUseCase {
	transferService = inject(ITransferRepository);

	execute() {
		return this.transferService.getBankDate();
	}
}
