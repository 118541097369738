import { map, Observable } from "rxjs";
import { GetCampaignsRequest } from "../../application/dtos/campaign.dto";
import { ICampaignRepository } from "../../domain/repositories/campaign.repository";
import { Inject, Injectable } from "@angular/core";
import { ICampaignService } from "../../../core-banking/domain/interfaces/campaign-service.interface";
import { Campaign } from "../../domain/entities/campaign.entity";
import { CampaignMapper } from "../../application/mappers/campaign.mapper";

@Injectable()
export class CampaignRepositoryImpl implements ICampaignRepository {

   constructor(@Inject('ICampaignService') private readonly campaignService: ICampaignService) {}

    getPreLoginCampaigns(request: GetCampaignsRequest): Observable<Campaign[]> {
        return this.campaignService.getPreLoginCampaigns(request).pipe(
            map((response) => 
                response.CampaignSpecifications.map((campaignDTO) => CampaignMapper.toEntity(campaignDTO))
            )
        );
    }
}