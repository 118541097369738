<div class="bg-white px-6 rounded-lg shadow">
	<div class="grid grid-rows divide-y">
		<div *ngFor="let item of items">
			<a [routerLink]="item.link" class="hover:text-primary">
				<div class="py-4 text-xs xl:text-sm font-medium">
					{{ item.title | translate }}
				</div>
			</a>
		</div>
	</div>
</div>
