import { Injectable } from '@angular/core';
import { IBillRepository } from '../../domain/repositories/bill.repository';
import { map, Observable } from 'rxjs';
import { Partner } from '../../domain/entities/partner.entity';
import { PartnerService } from '../../domain/entities/partner-service.entity';
import { HubPaymentService } from '../../../hub-payment/application/services/hub-payment.service';
import { PartnerMapper } from '../../application/mappers/partner.mapper';
import { PartnerServiceMapper } from '../../application/mappers/partner-service.mapper';
import { ThirdPartyRequestDTO } from '../../application/dtos/thirdPartyRequest.dto';
import { PartnerServiceField } from '../../domain/entities/partner-service-field.entity';
import { PartnerServiceFieldMapper } from '../../application/mappers/partner-service-field.mapper';
import { Logger } from '../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class BillRepositoryImpl implements IBillRepository {
	constructor(private readonly hubPaymentService: HubPaymentService) {}

	getAllPartners(): Observable<Partner[]> {
		return this.hubPaymentService
			.getThirdParties()
			.pipe(
				map((response) =>
					response.dataResponse.thirdParties.map((value) =>
						PartnerMapper.toDomain(value)
					)
				)
			);
	}

	getServicesByPartnerId(partnerId: number): Observable<PartnerService[]> {
		return this.hubPaymentService
			.getThirdPartiesServices(partnerId)
			.pipe(
				map((response) =>
					response.dataResponse.thirdPartiesService.map((value) =>
						PartnerServiceMapper.toDomain(value)
					)
				)
			);
	}

	initiatePartnerService(
		serviceId: number,
		stepNum: number,
		payload: any
	): Observable<PartnerServiceField[]> {
		const request: ThirdPartyRequestDTO = {
			serviceId,
			stepNum,
			dataRequest: payload,
		};
		return this.hubPaymentService
			.getBillDetailsBeforePay(request)
			.pipe(
				map((response) =>
					response.dataResponse.map((value) =>
						PartnerServiceFieldMapper.toDomain(value)
					)
				)
			);
	}

	validatePartnerService(
		partnerId: number,
		serviceId: number,
		payload: any
	): Observable<any> {
		const request: ThirdPartyRequestDTO = {
			partnerId,
			serviceId,
			dataRequest: payload,
		};
		return this.hubPaymentService
			.setPaiementThirdParties(request)
			.pipe(
				map((response) => Logger.info('validatePartnerService', response))
			);
	}
}
