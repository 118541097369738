import { Component } from '@angular/core';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { NgForOf, NgIf } from '@angular/common';
import { SidebarComponent } from '../../shared/components/sidebar/sidebar.component';

@Component({
	selector: 'app-home-layout',
	standalone: true,
	imports: [
		HeaderComponent,
		RouterOutlet,
		FooterComponent,
		NgForOf,
		SidebarComponent,
		NgIf,
	],
	templateUrl: './home-layout.component.html',
	styleUrl: './home-layout.component.css',
})
export class HomeLayoutComponent {
	user = { username: 'Ange BAGUI', picture: '' };

	sidebarVisible: boolean = false;
	showUserAccountSection: boolean = false;

	toggleSidebar() {
		this.sidebarVisible = !this.sidebarVisible;
	}

	closeSidebar() {
		this.sidebarVisible = false;
	}
}
