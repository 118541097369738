import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { INotificationRepository } from '../../domain/repositories/notification.repository';
import { NNotification } from '../../domain/entities/notification.entity';

@Injectable({
	providedIn: 'root',
})
export class NotificationListUseCase {
	constructor(
		@Inject('INotificationRepository')
		private readonly notificationRepository: INotificationRepository
	) {}

	execute(): Observable<NNotification[]> {
		return this.notificationRepository.notificationList();
	}
}
