import { PartnerServiceField } from '../../domain/entities/partner-service-field.entity';
import { ThirdPartyFieldDTO } from '../dtos/thirdPartyField.dto';

export class PartnerServiceFieldMapper {
	static toDTO(partnerServiceField: PartnerServiceField): ThirdPartyFieldDTO {
		return {
			idField: partnerServiceField.idField,
			stepId: partnerServiceField.stepId,
			entityIdService: partnerServiceField.entityIdService,
			inputType: partnerServiceField.inputType,
			fieldGroup: partnerServiceField.fieldGroup,
			keyJson: partnerServiceField.keyJson,
			valueJson: partnerServiceField.valueJson,
			inputLabel: partnerServiceField.inputLabel,
			fieldNum: partnerServiceField.fieldNum,
			errorFieldRequiredMessage:
				partnerServiceField.errorFieldRequiredMessage,
			isReadOnly: partnerServiceField.isReadOnly,
			isRequired: partnerServiceField.isRequired,
			isDisabled: partnerServiceField.isDisabled,
		};
	}
	static toDomain(dto: ThirdPartyFieldDTO): PartnerServiceField {
		return new PartnerServiceField(
			dto.idField,
			dto.stepId,
			dto.entityIdService,
			dto.inputType,
			dto.fieldGroup,
			dto.keyJson,
			dto.valueJson,
			dto.inputLabel,
			dto.fieldNum,
			dto.errorFieldRequiredMessage,
			dto.isReadOnly,
			dto.isRequired,
			dto.isDisabled
		);
	}
}
