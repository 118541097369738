import {
	LoginCredentialsDTO as CoreLoginCredentialsDTO,
	LoginResponseDTO as CoreLoginResponseDTO,
} from '../../../core-banking/application/dtos/auth/user-login.dto';
import {
	UserRegistrationRequestDTO as CoreRegisterUserDTO
} from '../../../core-banking/application/dtos/auth/user-registration.dto';
import {
	ActivateAccountDTO,
	CreatePasswordDTO,
	LoginCredentialsDTO,
	RegisterUserDTO,
	ResendOTPDTO,
	PasswordForgottenDTO,
	ResetPasswordDTO,
	VerifyOTPDTO,
} from '../dtos/authentication.dto';
import { AuthSession } from '../../domain/entities/auth-session.entity';
import { CreatePasswordRequestDTO as CoreCreatePasswordRequestDTO } from '../../../core-banking/application/dtos/auth/create-password.dto';
import { ActivateAccountRequestDTO as CoreActivateAccountRequestDTO } from '../../../core-banking/application/dtos/auth/user-active-account.dto';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';
import {
	RequestResendOTPDTO,
	PasswordForgottenRequestDTO,
	ResetPasswordRequestDTO,
	RequestVerifyOTPDTO,
} from '../../../core-banking/application/dtos/auth/reset-password.dto';

export class AuthMapper {

	static toAuthSessionDomain(coreResponse: CoreLoginResponseDTO): AuthSession {
		return new AuthSession(
			coreResponse.claims_token.value,
			coreResponse.refresh_token,
			{
				id: coreResponse.profile.userid,
				firstName: coreResponse.profile.user_attributes.FirstName,
				lastName: coreResponse.profile.user_attributes.LastName,
				legalEntityId: coreResponse.profile.user_attributes.legalEntityId!,
				features: parseStringToArray(coreResponse.features),
				permissions: parseStringToArray(coreResponse.permissions),
				customerId: coreResponse.profile.user_attributes.customer_id
			}
		);
	}

	static toLoginCredentialsDTO(
		request: LoginCredentialsDTO
	): CoreLoginCredentialsDTO {
		return {
			userid: request.userid,
			Password: request.password,
		};
	}


	static toRegisterUserDTO(request: RegisterUserDTO): CoreRegisterUserDTO {
		return {
			lastName: request.lastName,
			customerId: request.customerId,
			dateOfBirth: request.dateOfBirth,
			legalEntityId: request.legalEntityId,
		};
	}

	static toActivateAccountDTO(
		request: ActivateAccountDTO
	): CoreActivateAccountRequestDTO {
		return {
			UserName: request.username,
			activationCode: request.activationCode,
		};
	}

	static toCreatePasswordDTO(
		request: CreatePasswordDTO
	): CoreCreatePasswordRequestDTO {
		return {
			serviceKey: request.serviceKey,
			Password: request.password,
		};
	}

	static toLegalEntityDomain(coreResponse: any): LegalEntity {
		return new LegalEntity(
			coreResponse.id,
			coreResponse.companyName,
			coreResponse.typeId,
			coreResponse.language,
			coreResponse.shortName,
			coreResponse.parentId,
			coreResponse.baseCurrency,
			coreResponse.countryCode,
			coreResponse.effectiveDate,
			coreResponse.closeDate
		);
	}
	static toPasswordForgottenDTO(
		request: PasswordForgottenDTO
	): PasswordForgottenRequestDTO {
		return {
			MobilePhoneNumber: request.mobilePhoneNumber,
			Email: request.email,
			DateOfBirth: request.dateOfBirth,
			LegalEntityId: request.legalEntityId,
		};
	}

	static toResendOTPDTO(request: ResendOTPDTO): RequestResendOTPDTO {
		return {
			Phone: request.phone,
			Email: request.email,
			UserName: request.userName,
			MFAAttributes: {
				serviceKey: request.serviceKey,
			},
		};
	}

	static toResetPassword(request: ResetPasswordDTO): ResetPasswordRequestDTO {
		return {
			UserName: request.userName,
			serviceKey: request.serviceKey,
			Password: request.password,
		};
	}
	static toVerifyOTP(request: VerifyOTPDTO): RequestVerifyOTPDTO {
		return {
			MFAAttributes: {
				serviceKey: request.serviceKey,
				OTP: {
					otp: request.otp,
					securityKey: request.securityKey,
				},
			},
		};
	}
}

function parseStringToArray(input: string): string[] {
	try {
	  return JSON.parse(input) as string[];
	} catch (error) {
	  console.error("Erreur lors de la conversion en tableau : ", error);
	  return [];
	}
}
  
