export class Transfer {
    constructor(
        public id: string,
        public transactionReference: string,
        public debitAccount: string,
        public creditAccount: string,
        public transactionCurrency: string,
        public transactionAmount: string,
        public paymentDetails: string,
        public processingDate: string,
        public debitValueDate: string,
        public creditValueDate: string,
        public debitAmount: string,
        public creditAmount: string,
        public waiveDebitCharges: string,
        public waiveCreditCharges: string,
        public beneficiaryName: string,
        public motifEconomique: string,
        public bankCode: string,
        public branchCode: string,
        public beneficiaryRIB: string,
        public orderingCustomerBank: string,
        public commissionType: string,
        public transactionType: string,
        public totalCharge: string,
        public commissionAmount: string,
        public status: string
    ) {}
}
