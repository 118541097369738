export class Discussion {
    constructor(
        public id: string,
        public requestid: string,
        public customer_id: string,
        public username: string,
        public totalAttachments: string,
        public firstMessage: string,
        public requestcategory_id: string,
        public recentMsgDate: string,
        public priority: string,
        public isPriorityMessage: string, 
        public softdeleteflag: string,    
        public unreadmsgs: string,
        public priorityCount: string,
        public msgids: string,
        public requestsubject: string,
        public status_id: string,
        public totalmsgs: string,
        public requestCreatedDate: string,
        public statusIdentifier: string,
        public readmsgs: string,
        public messagedescription: string
    ) {}
}
   