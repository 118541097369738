import { Component, OnInit } from '@angular/core';
import { IdleService } from '../../../../shared/services/idle.service';
import { CommonModule, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'app-inactivity-message',
	standalone: true,
	imports: [CommonModule, NgIf, TranslateModule],
	templateUrl: './inactivity-message.component.html',
})
export class InactivityMessageComponent implements OnInit {
	inactivityMessage$ = this.idleService.getInactivityMessageState();
	disconnectionMessage$ = this.idleService.getDisconnectionMessage();

	showMessage = false;
	showDisconnectionMessage = false;

	constructor(private readonly idleService: IdleService) {}

	ngOnInit() {
		this.idleService.getInactivityMessageState().subscribe((state) => {
			this.showMessage = state;
		});

		this.idleService.getDisconnectionMessage().subscribe((state) => {
			this.showDisconnectionMessage = state;
		});
	}

	clearMessage(): void {
		this.idleService.clearMessage(); // Masquer le message
	}
}
