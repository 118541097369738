export class TransferInstructions {
	constructor(
		public id: string,
		public amount: string,
		public description: string,
		public frequencyEndDate: string,
		public frequencyStartDate: string,
		public frequencyType: string,
		public fromAccountName: string,
		public fromAccountNumber: string,
		public isScheduled: string,
		public toAccountName: string,
		public toAccountNumber: string,
		public transactionsNotes: string,
		public transactionType: string,
		public transactionCurrency: string,
		public payeeCurrency: string,
		public serviceName: string,
		public statusDescription: string,
		public pendingApproval: false
	) {}
}
