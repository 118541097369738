import { createReducer, on } from "@ngrx/store";
import { Message } from "../../domain/entities/message.entity";
import * as MessageActions from "../actions/message.actions";

export interface MessageState {
    messages: Message[];
    loading: boolean;
    error: any;
    response: any;
  }
  
export const initialState: MessageState = {
    messages: [],
    loading: false,
    error: null,
    response: null,
};

export const messageReducer = createReducer(
    initialState,

    on(MessageActions.loadMessages, (state) => ({
        ...state,
        loading: true,
    })),
    on(MessageActions.loadMessagesSuccess,
    (state, { messages }) => ({
        ...state,
        messages,
        loading: false,
        error: null,
    })
    ),
    on(MessageActions.loadMessagesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    })),
)  