import {inject, Injectable} from "@angular/core";
import {ITransactionRepository} from "../../domain/repositories/transaction.repository";
import {Observable} from "rxjs";
import {Transaction} from "../../domain/entities/transaction.entity";
import {TransactionRequestDTO} from "../dtos/transaction.dto";

@Injectable({ providedIn: 'root'})
export class GetRecentUserTransactionUseCase {
    transactionService = inject(ITransactionRepository)

    execute(request: TransactionRequestDTO): Observable<Transaction[]> {
        return this.transactionService.getRecentTransactions(request);
    }
}
