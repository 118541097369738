import { SecurityQuestion, SecurityQuestionList } from "../../domain/entities/security-question.entity";
import { GetSecurityQuestionsResponseDTO } from "../dtos/security-question.dto";

export class SecurityQuestionMapper {
    static toSecurityQuestionList(dto: GetSecurityQuestionsResponseDTO): SecurityQuestionList {
      const securityQuestions = dto.records.map(record => {
        return new SecurityQuestion(
            record.lastmodifiedts,
            record.SecurityQuestion_Status,
            record.UserCount,
            record.SecurityQuestion_id,
            record.softdeleteflag,
            record.SecurityQuestion
        );
      });
  
      return new SecurityQuestionList(dto.serviceKey, securityQuestions);
    }
}
  