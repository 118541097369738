import { map, Observable } from "rxjs";
import { GetAllPendingApprovalRequestDTO } from "../../application/dtos/approval-request.dto";
import { ApprovalRequest, ApprovalRequestAction } from "../../domain/entities/approval-request.entity";
import { IApprovalRequestRepository } from "../../domain/repositories/approval-request.repository";
import { IApprovalRequestService } from "../../../core-banking/domain/interfaces/transfer/approval-request-service.interface";
import { Inject, Injectable } from "@angular/core";
import { ApprovalRequestMapper } from "../../application/mappers/approval-request.mapper";

@Injectable()
export class ApprovalRequestRepositoryImpl implements IApprovalRequestRepository {

   constructor(@Inject('IApprovalRequestService') private readonly approvalRequestService: IApprovalRequestService) {}

    getAllPendingApprovals(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequest[]> {
        return this.approvalRequestService.getAllPendingApprovals(request).pipe(
            map((response) =>
                response.map((approvalRequestDTO) => ApprovalRequestMapper.toApprovalRequestEntity(approvalRequestDTO))
            )
        );
    }

    getApprovalHistory(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequest[]> {
       return this.approvalRequestService.getApprovalHistory(request).pipe(
            map((response) =>
                response.map((approvalRequestDTO) => ApprovalRequestMapper.toApprovalRequestEntity(approvalRequestDTO))
            )
        );
    }

    getAllPendingRequests(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequest[]> {
       return this.approvalRequestService.getAllPendingRequests(request).pipe(
            map((response) =>
                response.map((approvalRequestDTO) => ApprovalRequestMapper.toApprovalRequestEntity(approvalRequestDTO))
            )
        );
    }

    getRequestHistory(request: GetAllPendingApprovalRequestDTO): Observable<ApprovalRequest[]> {
       return this.approvalRequestService.getRequestHistory(request).pipe(
            map((response) =>
                response.map((approvalRequestDTO) => ApprovalRequestMapper.toApprovalRequestEntity(approvalRequestDTO))
            )
        );
    }

    approveRequest(requestId: string): Observable<ApprovalRequestAction> {
        return this.approvalRequestService.approveRequest(requestId).pipe(
            map((response) => ApprovalRequestMapper.toApprovalRequestActionEntity(response))
        );
    }

    rejectRequest(requestId: string): Observable<ApprovalRequestAction> {
         return this.approvalRequestService.rejectRequest(requestId).pipe(
            map((response) => ApprovalRequestMapper.toApprovalRequestActionEntity(response))
        );
    }

    withdrawRequest(requestId: string): Observable<ApprovalRequestAction> {
         return this.approvalRequestService.withdrawRequest(requestId).pipe(
            map((response) => ApprovalRequestMapper.toApprovalRequestActionEntity(response))
        );
    }
}