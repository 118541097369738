import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { INotificationRepository } from '../../domain/repositories/notification.repository';

@Injectable({
	providedIn: 'root',
})
export class UserNotificationUseCase {
	constructor(
		@Inject('INotificationRepository')
		private readonly notificationRepository: INotificationRepository
	) {}

	execute(
		notificationId: string
	): Observable<{
		totalRecords: number;
		updatedRecords: number;
		notificationId: any;
	}> {
		return this.notificationRepository.unreadNotification(notificationId);
	}
}
