import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { ActivateAccountDTO } from '../dtos/authentication.dto';

@Injectable({
	providedIn: 'root',
})
export class ActivateAccountUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(activationData: ActivateAccountDTO): Observable<string> {
		return this.authRepository.activateAccount(activationData);
	}
}
