export class SecurityQuestionList{
    constructor(
        public serviceKey: string,
        public securityQuestions: SecurityQuestion[]
    ){}   
}
export class SecurityQuestion {
    constructor(
        public lastmodifiedts: string,
        public SecurityQuestion_Status: string,
        public UserCount: string,
        public SecurityQuestion_id: string,
        public softdeleteflag: string,
        public SecurityQuestion: string
    ) { }
}
