import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TermsConditionService} from "../core-banking/application/services/terms-condition/terms-condition.service";
import {ITermsConditionRepository} from "./domain/repositories/terms-condition.repository";
import {TermsConditionRepositoryImpl} from "./infrastructure/repositories/transaction.repository.impl";
import {TermsConditionEffects} from "./state/effects/terms-condition.effects";
import {provideEffects} from "@ngrx/effects";

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        {
            provide: 'ITermsConditionService',
            useClass: TermsConditionService
        },
        {
            provide: ITermsConditionRepository,
            useClass: TermsConditionRepositoryImpl
        },
        provideEffects(TermsConditionEffects)
    ]
})
export class CoreTermsConditionModule { }
