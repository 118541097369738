import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { BaseCurrencyRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class FetchBaseCurrencyUseCase {
	transferService = inject(ITransferRepository);

	execute(request: BaseCurrencyRequestDTO) {
		return this.transferService.fetchBaseCurrency(request);
	}
}
