import { RtgsTransferDTO } from '../dtos/transfer.dto';
import { RtgsTransfer } from '../../domain/entities/rtgs-transfer.entity';

export class RtgsTransferMapper {
	static toDomain(transfer: RtgsTransferDTO): RtgsTransfer {
		return new RtgsTransfer(
			transfer.transactionId,
			transfer.transactionReference || '',
			transfer.debitAccount || '',
			transfer.beneficiaryAccount || '',
			transfer.beneficiaryName || '',
			transfer.transactionAmount,
			transfer.motifEconomique,
			transfer.paymentDetails,
			transfer.processingDate || '',
			transfer.debitAmount || '',
			transfer.creditAmount || '',
			transfer.status || ''
		);
	}
}
