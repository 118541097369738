import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as DiscussionActions from '../actions/discussion.actions';
import { GetDiscussionCategoriesUseCase } from '../../application/use-cases/get-discussion-categories.use-case';
import { CreateDiscussionUseCase } from '../../application/use-cases/create-discussion.use-case';
import { GetAllDiscussionsUseCase } from '../../application/use-cases/get-all-discussions.use-case';
import { DeactivateDiscussionUseCase } from '../../application/use-cases/deactivate-discussion.use-case';
import { DeleteDiscussionUseCase } from '../../application/use-cases/delete-discussion.use-case';
import { ActivateDiscussionUseCase } from '../../application/use-cases/activate-discussion.use-case';
import { GetUnreadDiscussionCountUseCase } from '../../application/use-cases/get-unread-discussion-count.use-case';

@Injectable()
export class DiscussionEffects {
	loadDiscussionCatgeories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.loadDiscussionCategories),
			switchMap(() =>
				this.getDiscussionCategoriesUseCase.execute().pipe(
					map((categories) =>
						DiscussionActions.loadDiscussionCategoriesSuccess({
							categories,
						})
					),
					catchError((error) =>
						of(
							DiscussionActions.loadDiscussionCategoriesFailure({
								error,
							})
						)
					)
				)
			)
		)
	);

	createDiscussion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.createDiscussion),
			mergeMap(({ discussion }) =>
				this.createDiscussionUseCase.execute(discussion).pipe(
					mergeMap((createdDiscussion) => [
						DiscussionActions.loadDiscussions(),
						DiscussionActions.createDiscussionSuccess({
							discussion: createdDiscussion,
						}),
					]),
					catchError((error) =>
						of(DiscussionActions.createDiscussionFailure({ error }))
					)
				)
			)
		)
	);

	loadDiscussions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.loadDiscussions),
			switchMap(() =>
				this.getDiscussionsUseCase.execute().pipe(
					map((discussions) =>
						DiscussionActions.loadDiscussionsSuccess({ discussions })
					),
					catchError((error) =>
						of(DiscussionActions.loadDiscussionsFailure({ error }))
					)
				)
			)
		)
	);

	activateDiscussion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.activateDiscussion),
			mergeMap(({ discussionId }) =>
				this.activateDiscussionUseCase.execute(discussionId).pipe(
					switchMap(() => [
						DiscussionActions.loadDiscussions(),
						DiscussionActions.activateDiscussionSuccess(),
					]),
					catchError((error) =>
						of(DiscussionActions.activateDiscussionFailure({ error }))
					)
				)
			)
		)
	);

	deactivateDiscussion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.deactivateDiscussion),
			mergeMap(({ discussionId }) =>
				this.deactivateDiscussionUseCase.execute(discussionId).pipe(
					switchMap(() => [
						DiscussionActions.loadDiscussions(),
						DiscussionActions.deactivateDiscussionSuccess(),
					]),
					catchError((error) =>
						of(DiscussionActions.deactivateDiscussionFailure({ error }))
					)
				)
			)
		)
	);

	deleteDiscussion$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.deleteDiscussion),
			mergeMap(({ discussionId }) =>
				this.deleteDiscussionUseCase.execute(discussionId).pipe(
					map(() =>
						DiscussionActions.deleteDiscussionSuccess({ discussionId })
					),
					catchError((error) =>
						of(DiscussionActions.deleteDiscussionFailure({ error }))
					)
				)
			)
		)
	);

	getUnreadDiscussions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DiscussionActions.getUnreadDiscussionCount),
			switchMap(() =>
				this.getUnreadDiscussionsUseCase.execute().pipe(
					map((count) =>
						DiscussionActions.getUnreadDiscussionCountSuccess({ count }) 
					),
					catchError((error) =>
						of(DiscussionActions.getUnreadDiscussionCountFailure({ error }))
					)
				)
			)
		)
	)

	constructor(
		private readonly actions$: Actions,
		private readonly getDiscussionCategoriesUseCase: GetDiscussionCategoriesUseCase,
		private readonly createDiscussionUseCase: CreateDiscussionUseCase,
		private readonly getDiscussionsUseCase: GetAllDiscussionsUseCase,
		private readonly deactivateDiscussionUseCase: DeactivateDiscussionUseCase,
		private readonly activateDiscussionUseCase: ActivateDiscussionUseCase,
		private readonly deleteDiscussionUseCase: DeleteDiscussionUseCase,
		private readonly getUnreadDiscussionsUseCase: GetUnreadDiscussionCountUseCase
	) {}
}
