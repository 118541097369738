import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IInfoRepository } from '../../domain/repositories/info.repository';
import { CreateFeedbackDTO, FeedbackResponseDTO } from '../dto/info.dto';

@Injectable({
	providedIn: 'root',
})
export class CreateFeedbackUseCase {
	constructor(
		@Inject('IInfoRepository')
		private readonly infoRepository: IInfoRepository
	) {}

	execute(data: CreateFeedbackDTO): Observable<FeedbackResponseDTO[]> {
		return this.infoRepository.createFeedback(data);
	}
}
