import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { logout } from '../../core/authentication/state/actions/auth.actions';
import { Logger } from '../helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class IdleService {
	private readonly idleTimeout = 5 * 60 * 1000; // 5 minutes en millisecondes
	private readonly userActivity$: Observable<any>;
	private readonly stopWatching$ = new Subject<void>(); // Observable pour arrêter la surveillance
	private readonly inactivityMessage$ = new BehaviorSubject<boolean>(false); // Gère l'état du message
	private readonly disconnectionMessage$ = new BehaviorSubject<boolean>(false);

	constructor(private readonly store: Store) {
		// Créer un Observable qui surveille les événements d'interaction de l'utilisateur
		this.userActivity$ = merge(
			fromEvent(document, 'mousemove'),
			fromEvent(document, 'keydown'),
			fromEvent(document, 'click'),
			fromEvent(document, 'touchstart') // Pour appareils tactiles
		);
	}
	
	startWatching() {
		Logger.warn('Début de la surveillance de lactivité.', 5);
		this.userActivity$
			.pipe(
				switchMap(() => {
					// Réinitialise le timer à chaque interaction
					return timer(this.idleTimeout);
				}),
				takeUntil(this.stopWatching$) // Arrêter l'écoute quand stopWatching$ émet
			)
			.subscribe(() => {
				// Lorsque le timer atteint 5 minutes sans activité, on déclenche la déconnexion
				this.logout();
			});
	}

	private logout() {
		Logger.info('Utilisateur inactif depuis 5 minutes, déconnexion.');
		// this.authTokenService.removeTokens();
		this.store.dispatch(logout());
		// this.router.navigate(['/auth/login']);
		// Stopper la surveillance de l'activité
		this.stopWatching$.next(); // Déclenchement de takeUntil pour arrêter l'écoute
		this.stopWatching$.complete(); // Fermer le Subject
		this.inactivityMessage$.next(true); // Affiche le message
	}

	// Observable pour exposer l'état du message
	getInactivityMessageState(): Observable<boolean> {
		return this.inactivityMessage$.asObservable();
	}

	// Méthode pour récupérer l'état du message
	getDisconnectionMessage(): Observable<boolean> {
		return this.disconnectionMessage$.asObservable();
	}

	clearMessage() {
		Logger.warn('Clearing inactivity message.');
		this.inactivityMessage$.next(false); // Masque le message
		this.disconnectionMessage$.next(false);
	}

	 // Méthode pour mettre à jour le message
	showDisconnectionMessage() {
		this.disconnectionMessage$.next(true);
	}
}
