import { createAction, props } from '@ngrx/store';
import { NNotification, Notificationcategory, NotificationCount } from '../../domain/entities/notification.entity';

// Load Notifications
export const loadNotifications = createAction(
	'[Notification] Load Notifications'
);
export const loadNotificationsSuccess = createAction(
	'[Notification] Load Notifications Success',
	props<{ notifications: NotificationCount }>()
);
export const loadNotificationsFailure = createAction(
	'[Notification] Load Notifications Failure',
	props<{ error: any }>()
);

// Get Notification Categories
export const notificationCategory = createAction(
	'[Notification] Get Notification Categories',
)

export const notificationCategorySuccess = createAction(
	'[Notification] Get Notification Categories Success',
	props<{ categories: Notificationcategory[] }>()
)

export const notificationCategoryFailure = createAction(
	'[Notification] Get Notification Categories Failure',
	props<{ error: any }>()
)

// Load Notifications List
export const notificationList = createAction(
	'[Notification] Load Notifications List',
)

export const notificationListSuccess = createAction(
	'[Notification] Load Notifications List Success',
	props<{ notifications: NNotification[] }>()
)

export const notificationListFailure = createAction(
	'[Notification] Load Notifications List Failure',
	props<{ error: any }>()
)


// Get Notification Count
export const notificationCount = createAction(
	'[Notification] Get Notification Count',
)

export const notificationCountSuccess = createAction(
	'[Notification] Get Notification Count Success',
	props<{ counts: {unreadNotificationCount: string;}[] }>()
)

export const notificationCountFailure = createAction(
	'[Notification] Get Notification Count Failure',
	props<{ error: any }>()
)


// Read Notification
export const readNotification = createAction(
	'[Notification] Read Notification',
	props<{ notificationId: string }>()
)

export const readNotificationSuccess = createAction(
	'[Notification] Read Notification Success',
	props<{ response: { totalRecords: number;updatedRecords: number;notificationId: any;}}>()
)

export const readNotificationFailure = createAction(
	'[Notification] Read Notification Failure',
	props<{ error: any }>()
)


