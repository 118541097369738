export class MFAAttributes {
    constructor(
        public securityKey: string,
        public sacMaxResendRequestsAllowed: string,
        public remainingResendAttempts: string,
        public sacCodeLength: string,
        public communicationType: string,
        public sacPreferenceCriteria: string,
        public customerCommunication: CustomerCommunication,
        public isMFARequired: string,  // Convertir en boolean si besoin
        public MFAType: string,
        public serviceKey: string,
        public successMessage: string
    ) {}
}
  
export class CustomerCommunication {
    constructor(
        public phone: CommunicationDetail[],
        public email: CommunicationDetail[]
    ) {}
}
  
export class CommunicationDetail {
    constructor(
        public masked: string,
        public unmasked: string,
        public referenceId: string
    ) {}
}