import { map, Observable, of } from 'rxjs';
import { Transfer } from '../../domain/entities/transfer.entity';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { inject } from '@angular/core';
import {
	InternationalTransferRequestDTO,
	TransferRequestDto,
	TransferStandingOrderRequestDTO,
	TransferSicaStandingOrderRequestDTO,
	TransferInternationalStandingOrderRequestDTO,
	TransferDeleteRequestDTO,
	TransferDeleteResponseDTO,
	BaseCurrencyRequestDTO,
	BaseCurrencyResponseDTO,
	DashboardCurrencyListRequestDTO,
	DashboardCurrencyListResponseDTO,
	CurrencyRatesRequestDTO,
	CurrencyRatesResponseDTO,
} from '../../application/dtos/transfer.dto';
import { TransferMapper } from '../../application/mappers/transfer.mapper';
import { BankDate } from '../../domain/entities/bank-date.entity';
import { BankDateMapper } from '../../application/mappers/bank-date.mapper';
import { TransferService } from '../../../core-banking/application/services/transfer/transfer.service';
import { UemoaBank } from '../../domain/entities/uemoa-bank.entity';
import { UemoaBankMapper } from '../../application/mappers/uemoa-bank.mapper';
import {
	RtgsTransferRequestDTO,
	SikaTransferRequestDTO,
} from '../../../core-banking/application/dtos/transfer/transfer.dto';
import { RtgsTransfer } from '../../domain/entities/rtgs-transfer.entity';
import { RtgsTransferMapper } from '../../application/mappers/rtgs-transfer.mapper';
import { TransferStandingOrderMapper } from '../../application/mappers/transfer-standing-order.mapper';
import { TransferStandingOrder } from '../../domain/entities/transfer-standing-order.entity';
import { TransferHistory } from '../../domain/entities/transfer-history.entity';
import { TransferHistoryMapper } from '../../application/mappers/transfer-history.mapper';
import { TransferInstructions } from '../../domain/entities/transfer-instructions.entity';
import { TransferInstructionsMapper } from '../../application/mappers/transfer-instructions.mapper';

export class TransferRepositoryImpl implements ITransferRepository {
	tranferService = inject(TransferService);

	transferToOwnerAccount(request: TransferRequestDto): Observable<Transfer> {
		return this.tranferService.createOwnAccountTransfer(request).pipe(
			map((transfer) => {
				return TransferMapper.toDomain(transfer);
			})
		);
	}

	transferToIntraAccount(transfer: TransferRequestDto): Observable<Transfer> {
		return this.tranferService.createIntraBankTransfer(transfer).pipe(
			map((transfer) => {
				return TransferMapper.toDomain(transfer);
			})
		);
	}

	sikaTransfer(request: SikaTransferRequestDTO): Observable<Transfer> {
		return this.tranferService.createSikaTransfer(request).pipe(
			map((transfer) => {
				return TransferMapper.toDomain(transfer);
			})
		);
	}

	rtgsTransfer(request: RtgsTransferRequestDTO): Observable<RtgsTransfer> {
		return this.tranferService.createRtgsTransfer(request).pipe(
			map((transfer) => {
				return RtgsTransferMapper.toDomain(transfer);
			})
		);
	}

	transferToInternational(
		request: InternationalTransferRequestDTO
	): Observable<Transfer> {
		return this.tranferService.createInternationalTransfer(request).pipe(
			map((transfer) => {
				return TransferMapper.toDomain(transfer);
			})
		);
	}

	transferToExternalBank(): Observable<Transfer> {
		throw new Error('Method not implemented.');
	}

	recurringTransfer(): Observable<Transfer> {
		throw new Error('Method not implemented.');
	}

	validateTransferByOTP(code: string): Observable<boolean> {
		return of(true);
	}

	getTransferHistory(): Observable<TransferHistory[]> {
		return this.tranferService.getTransferHistory().pipe(
			map((transfers) => {
				return transfers.map((transfer) =>
					TransferHistoryMapper.toDomain(transfer)
				);
			})
		);
	}

	getBankDate(): Observable<BankDate[]> {
		return this.tranferService.getBankDate().pipe(
			map(({ date }) => {
				if (date.length > 0) {
					return date.map((dt) => BankDateMapper.toDomain(dt));
				}
				return date;
			})
		);
	}

	getUemoaBanks(): Observable<UemoaBank[]> {
		return this.tranferService.getUemoaBanks().pipe(
			map(({ UemoaBanks }) => {
				return UemoaBanks.map((bank) => UemoaBankMapper.toDomain(bank));
			})
		);
	}

	createOwnAccountStandingOrder(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrder> {
		return this.tranferService.createOwnAccountStandingOrder(request).pipe(
			map((transfer) => {
				return TransferStandingOrderMapper.toDomain(transfer);
			})
		);
	}
	createIntraBankStandingOrderTransfer(
		request: TransferStandingOrderRequestDTO
	): Observable<TransferStandingOrder> {
		return this.tranferService
			.createIntraBankStandingOrderTransfer(request)
			.pipe(
				map((transfer) => {
					return TransferStandingOrderMapper.toDomain(transfer);
				})
			);
	}
	createSicaStandingOrderTransfer(
		request: TransferSicaStandingOrderRequestDTO
	): Observable<TransferStandingOrder> {
		return this.tranferService.createSicaStandingOrderTransfer(request).pipe(
			map((transfer) => {
				return TransferStandingOrderMapper.toDomain(transfer);
			})
		);
	}
	createInternationalStandingOrder(
		request: TransferInternationalStandingOrderRequestDTO
	): Observable<TransferStandingOrder> {
		return this.tranferService.createInternationalStandingOrder(request).pipe(
			map((transfer) => {
				return TransferStandingOrderMapper.toDomain(transfer);
			})
		);
	}

	getInstructions(): Observable<TransferInstructions[]> {
		return this.tranferService.getInstructions().pipe(
			map((transfers) => {
				return transfers.map((transfer) =>
					TransferInstructionsMapper.toDomain(transfer)
				);
			})
		);
	}

	transferToOwnAccountsDelete(
		request: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.tranferService
			.transferToOwnAccountsDelete(request)
			.pipe(map((transfer) => transfer));
	}
	intraBankAccFundTransferDelete(
		request: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.tranferService
			.intraBankAccFundTransferDelete(request)
			.pipe(map((transfer) => transfer));
	}
	interBankFundTransferDelete(
		request: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.tranferService
			.interBankFundTransferDelete(request)
			.pipe(map((transfer) => transfer));
	}
	internationalFundTransferDelete(
		request: TransferDeleteRequestDTO
	): Observable<TransferDeleteResponseDTO> {
		return this.tranferService
			.internationalFundTransferDelete(request)
			.pipe(map((transfer) => transfer));
	}

	fetchBaseCurrency(
		request: BaseCurrencyRequestDTO
	): Observable<BaseCurrencyResponseDTO> {
		return this.tranferService
			.fetchBaseCurrency(request)
			.pipe(map((transfer) => transfer));
	}
	fetchDashboardCurrencyList(
		request: DashboardCurrencyListRequestDTO
	): Observable<DashboardCurrencyListResponseDTO> {
		return this.tranferService
			.fetchDashboardCurrencyList(request)
			.pipe(map((transfer) => transfer));
	}
	fetchCurrencyRates(
		request: CurrencyRatesRequestDTO
	): Observable<CurrencyRatesResponseDTO> {
		return this.tranferService
			.fetchCurrencyRates(request)
			.pipe(map((transfer) => transfer));
	}
}
