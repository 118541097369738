import {ITransferRepository} from "../../domain/repositories/transfer.repository";
import {inject, Injectable} from "@angular/core";

@Injectable({ providedIn: 'root'})
export class RecurringTransferUseCase {
    transferRepository = inject(ITransferRepository);

    execute() {
        return this.transferRepository.recurringTransfer();
    }
}