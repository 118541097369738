import { inject, Injectable } from '@angular/core';
import { ITermsConditionRepository } from '../../domain/repositories/terms-condition.repository';
import { Observable } from 'rxjs';
import { CustomerTNCRequestPDO } from '../dtos/terms-condition.dto';
import { AlreadySigned } from '../../domain/entities/already-signed.entity';
import { TermsAndCondition } from '../../domain/entities/terms-and-condition.entity';

@Injectable({ providedIn: 'root' })
export class CustomerSignUseCase {
	termsConditionService = inject(ITermsConditionRepository);

	execute(request: CustomerTNCRequestPDO): Observable<AlreadySigned | TermsAndCondition[]> {
		return this.termsConditionService.createCustomerTNCForLogin(request);
	}
}
