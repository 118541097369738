import { Inject, Injectable } from '@angular/core';
import { IDiscussionRepository } from '../../domain/repositories/discussion.repository';
import { Observable } from 'rxjs';
import { Discussion } from '../../domain/entities/discussion.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAllDiscussionsUseCase {
	constructor(
		@Inject('IDiscussionRepository')
		private readonly discussionRepository: IDiscussionRepository
	) {}

	execute(): Observable<Discussion[]> {
		return this.discussionRepository.getDiscussions();
	}
}
