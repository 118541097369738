<div class="flex flex-col items-center justify-center h-screen">
	<h1 class="text-4xl font-bold mb-4">401</h1>
	<p class="text-xl mb-8">You are not authorized to access this page.</p>
	<button
		(click)="navigateHome()"
		class="bg-primary text-white px-4 py-2 rounded"
	>
		Go Home
	</button>
</div>
