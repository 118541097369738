import { createAction, props } from '@ngrx/store';
import { CustomerTNCRequestPDO, GetPostLoginRequestPDO } from '../../application/dtos/terms-condition.dto';
import { GetPostLogin } from '../../domain/entities/get-post-login.entity';
import { TermsAndCondition } from '../../domain/entities/terms-and-condition.entity';
import { AlreadySigned } from '../../domain/entities/already-signed.entity';

export const getFirstTimePostLoginAction = createAction(
	"[termsConduction] Get Post Login",
	props<{ request: GetPostLoginRequestPDO}>()
);

export const getPostLoginAction = createAction(
	"[termsConduction] Get Post Login",
	props<{ request: GetPostLoginRequestPDO}>()
);


export const getPostLoginActionSuccess = createAction(
	"[termsConduction] Get Post Login Success",
	props<{ response: GetPostLogin }>()
);

export const getPostLoginActionFailure = createAction(
	"[termsConduction] Get Post Login Failure",
	props<{ error: any}>()
);


export const createCustomerTNCAction = createAction(
	"[termsConduction] create customer TNC",
	props<{ request: CustomerTNCRequestPDO}>()
);


export const createCustomerTNCActionSuccess = createAction(
	"[termsConduction] create customer TNC Success",
	props<{ response: AlreadySigned | TermsAndCondition[] }>()
);

export const createCustomerTNCActionFailure = createAction(
	"[termsConduction] create customer TNC Failure",
	props<{ error: any}>()
);