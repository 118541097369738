import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IAccountRepository } from '../../domain/repositories/account.repository';

@Injectable({
	providedIn: 'root',
})
export class GenerateReleveRibAccountUseCase {
	private readonly accountRepository = inject(IAccountRepository);

	execute(data: { fileId: string; fileName: string }): Observable<any> {
		return this.accountRepository.generateReleveRibAccount(data);
	}
}
