import { Inject, Injectable } from '@angular/core';
import { IApprovalRequestRepository} from '../../../domain/repositories/approval-request.repository';

@Injectable({ providedIn: 'root' })
export class WithdrawRequestUseCase {
	constructor(@Inject('IApprovalRequestRepository') private readonly approvalRequestRepository: IApprovalRequestRepository) {}

	execute(requestId: string) {
		return this.approvalRequestRepository.withdrawRequest(requestId);
	}
}
