import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { TransferDeleteRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class InternationalFundTransferDeleteUseCase {
	transferService = inject(ITransferRepository);

	execute(request: TransferDeleteRequestDTO) {
		return this.transferService.internationalFundTransferDelete(request);
	}
}
