<header
	class="bg-black text-white shadow-lg px-3 md:px-6 lg:px-7 xl:px-20 2xl:px-28 py-5"
>
	<div class="flex justify-between items-center">
		<!-- Logo -->
		<div class="flex items-center">
			<a href="/home">
				<img
					src="assets/images/logo_bbgci.png"
					alt="Bridge Bank Logo"
					class="w-auto h-5 sm:h-8 md:h-5 lg:h-8"
				/>
			</a>
		</div>

		<!-- Navigation -->
		<nav
			class="hidden md:flex space-x-5 xl:space-x-8 2xl:space-x-12 -mt-2 z-10"
		>
			<ng-container *ngFor="let item of menuItems">
				<a
					*ngIf="!item.subMenu"
					[href]="item.link"
					replaceUrl="true"
					class="hover:text-primary text-xs xl:text-sm 2xl:text-base"
					[ngClass]="{'text-primary': item?.activeLink}"
					>{{ item.label | translate }}</a
				>
				<div *ngIf="item.subMenu" class="relative group">
					<a
						[routerLink]="item.link"
						replaceUrl="true"
						class="flex items-center hover:text-primary text-xs xl:text-sm 2xl:text-base gap-x-1"
						[ngClass]="{'text-primary': item?.activeLink}"
						replaceUrl="true"
					>
						{{ item.label | translate }}
						<svg
							class="h-5 w-5 flex-none text-white hover:text-primary"
							viewBox="0 0 20 20"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
								clip-rule="evenodd"
							/>
						</svg>
					</a>
					<div
						class="absolute left-0 top-full pt-2 w-48 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out"
					>
						<div
							class="rounded-xs shadow-lg bg-white ring-1 ring-black ring-opacity-5"
						>
							<div class="py-0 z-10">
								<ng-container *ngFor="let subItem of item.subMenu">
									<a
										*ngIf="!subItem.subMenu"
										[routerLink]="subItem.link"
										replaceUrl="true"
										[ngClass]="{'text-primary': subItem?.activeLink}"
										class="block px-4 py-3 text-sm text-gray-700 hover:bg-primary hover:text-white"
									>
										{{ subItem.label | translate }}
									</a>
									<div
										*ngIf="subItem.subMenu"
										class="relative group/sub"
									>
										<a
											[routerLink]="subItem.link"
											replaceUrl="true"
											class="flex items-center justify-between px-4 py-3 text-sm text-gray-700 hover:bg-primary hover:text-white"
											[ngClass]="{'text-primary': subItem?.activeLink}"
										>
											{{ subItem.label | translate }}
											<svg
												class="h-5 w-5 flex-none text-gray-400"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
													clip-rule="evenodd"
												/>
											</svg>
										</a>
										<div
											class="absolute left-full top-0 pt-2 pl-2 w-48 opacity-0 invisible group-hover/sub:opacity-100 group-hover/sub:visible transition-all duration-300 ease-in-out"
										>
											<div
												class="rounded-xs shadow-lg bg-white ring-1 ring-black ring-opacity-5"
											>
												<div class="py-0">
													<a
														*ngFor="
															let nestedItem of subItem.subMenu
														"
														[routerLink]="nestedItem.link"
														replaceUrl="true"
														[ngClass]="{'text-primary': nestedItem?.activeLink}"
														class="block px-4 py-3 text-sm text-gray-700 hover:bg-primary hover:text-white"
													>
														{{ nestedItem.label | translate }}
													</a>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</nav>

		<!-- Icons -->
		<div class="hidden lg:flex items-center space-x-4">
			<button
				(click)="onIconClick(icon.label)"
				*ngFor="let icon of rightIcons"
				class="hover:text-orange-500 cursor-pointer"
				[routerLink]="icon.link ? icon.link : null"
				replaceUrl="true"
			>
				<div class="relative inline-block">
					<!-- Icône via ng-icon -->
					<ng-icon [name]="icon.icon" size="24" class="text-white"></ng-icon>
				
					<!-- Point rouge (Badge) -->
					<span
						*ngIf="(icon.label === 'HEADER.MESSAGES' && numberDiscussions > 0) || (icon.label === 'HEADER.NOTIFICATIONS' && numberNotifications > 0)"
						class="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full border-2 border-white"
					></span>
				</div>	
				
			</button>		
		</div>

		<!-- Mobile menu button -->
		<div class="lg:hidden">
			<button
				(click)="onIconClick('Menu')"
				class="mobile-menu-button focus:outline-none focus:bg-gray-700"
			>
				<img src="assets/icons/menu.svg" alt="Menu" class="h-6 w-6" />
			</button>
		</div>
	</div>

	<!-- Mobile Menu -->
	<div [ngClass]="{ hidden: !isMenuOpen }" class="md:hidden">
		<ng-container *ngFor="let item of menuItems">
			<a
				*ngIf="!item.subMenu"
				[routerLink]="item.link"
				replaceUrl="true"
				class="block py-2 px-4 text-sm hover:bg-gray-700"
				>{{ item.label | translate }}</a
			>
			<div *ngIf="item.subMenu" class="relative">
				<button
					(click)="toggleSubmenu(item.label)"
					class="flex items-center w-full text-left py-2 px-4 text-sm hover:bg-gray-700"
				>
					{{ item.label | translate }}
					<svg
						class="h-5 w-5 flex-none text-white"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
					>
						<path
							fill-rule="evenodd"
							d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
							clip-rule="evenodd"
						/>
					</svg>
				</button>
				<div *ngIf="activeSubmenu === item.label" class="bg-gray-800">
					<a
						*ngFor="let subItem of item.subMenu"
						[routerLink]="subItem.link"
						replaceUrl="true"
						class="block py-2 px-8 text-sm hover:bg-gray-700"
					>
						{{ subItem.label | translate }}
					</a>
				</div>
			</div>
		</ng-container>
		<button
			class="block py-2 px-4 text-sm hover:bg-gray-700"
			(click)="openLogoutModal()"
		>
			{{ 'LOGOUT' | translate }}
		</button>
	</div>
</header>

<app-logout
	[isModalOpen]="isLogoutModalOpen"
	(onClose)="closeLogoutModal()"
></app-logout>
