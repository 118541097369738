import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { GetCountPDO, MessagesNotificationResponseDTO, NotificationCategoryResponse, NotificationListResponsePDO, UnreadResponsePDO } from '../../dtos/notification/notification.dto';
import { INotificationService } from '../../../domain/interfaces/notifications-service.interface';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { AuthTokenService } from '../../../../authentication/application/services/auth-token.service';
import { Logger } from '../../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class NotificationService implements INotificationService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly tokenService: AuthTokenService,
	) {}

	getMessagesNotifications(): Observable<MessagesNotificationResponseDTO> {
		return this.api
			.post<MessagesNotificationResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.GET_NOTIFICATIONS,
				{}
			)
			.pipe(
				map((response) => {
					Logger.info("getMessagesNotifications : ", response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_MESSAGES_NOTIFICATIONS_FAILED',
						'Failed to get messages notifications'
					)
				)
			);
	}

	notificationList(): Observable<NotificationListResponsePDO> {
		return this.api
			.get<NotificationListResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.NOTIFICATION_LIST)
			.pipe(
				map((response) => {
					Logger.info("notificationList : ", response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_MESSAGES_NOTIFICATIONS_FAILED',
						'Failed to get notifications list'
					)
				)
			);
	}

	getNotificationCategories(): Observable<NotificationCategoryResponse> {
		return this.api
			.post<NotificationCategoryResponse>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.GET_CATEGORIES,
				{}
			)
			.pipe(
				map((response) => {
					Logger.info("getNotificationCategories: ", response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_MESSAGES_NOTIFICATIONS_FAILED',
						'Failed to get notifications categories'
					)
				)
			);
	}

	getNotificationCount(): Observable<GetCountPDO> {
		return this.api
			.post<GetCountPDO>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.GET_COUNT,
				{}
			)
			.pipe(
				map((response) => {
					Logger.info("getNotificationCount : ", response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_MESSAGES_NOTIFICATIONS_FAILED',
						'Failed to get notifications count'
					)
				)
			);
	}

	unreadNotification(notificationId: string): Observable<UnreadResponsePDO> {
		return this.api
			.post<UnreadResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.NOTIFICATIONS.UNREAD,
				{
					"userNotificationId": `${notificationId}`
				}
			)
			.pipe(
				map((response) => {
					Logger.info("unreadNotification : ", response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'READ_NOTIFICATIONS_FAILED',
						'Failed to read notification'
					)
				)
			);
	}
}
