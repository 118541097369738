import { TransferHistory } from '../../domain/entities/transfer-history.entity';
import { TransferHistoryDTO } from '../dtos/transfer.dto';

export class TransferHistoryMapper {
	static toDomain(transfer: TransferHistoryDTO): TransferHistory {
		return new TransferHistory(
			transfer.transactionId,
			transfer.transactionReference,
			transfer.debitAccount || '',
			transfer.debitAccountName || '',
			transfer.transactionAmount || '',
			transfer.transactionCurrency || '',
			transfer.executionDate || '',
			transfer.beneficiaryName || '',
			transfer.beneficiaryAccount || '',
			transfer.orderingCustomerId || '',
			transfer.orderingCustomerName || '',
			transfer.paymentTypeId || '',
			transfer.paymentType || '',
			transfer.paymentDetails || '',
			transfer.currentStatus || ''
		);
	}
}
