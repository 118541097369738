import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Logger } from '../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class CoreBankingApiService {
	private readonly apiUrl = environment.apiUrl;
	private readonly loginUrl = environment.loginUrl;
	private readonly apiKey = environment.apiKey;
	private readonly appSecret = environment.appSecret;
	private readonly apiInfo = environment.apiInfo;

	constructor(private readonly http: HttpClient) {
		Logger.info('CoreBankingApiService created');
		Logger.info('API URL:', this.apiUrl);
		Logger.info('Login URL:', this.loginUrl);
	}

	private getHeaders(
		customHeaders: { [key: string]: string } = {}
	): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Kony-App-Key': this.apiKey,
			'X-Kony-App-Secret': this.appSecret,
			'X-Kony-ReportingParams': '{"aid":"OnlineBanking"}',
			// 'Access-Control-Allow-Origin': '*',
			// ...customHeaders,
		});
	}

	private getBasicAuthHeaders(): HttpHeaders {
		const basicAuthToken = btoa(`${this.apiKey}:${this.appSecret}`);
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Basic ${basicAuthToken}`,
		});
	}

	private getHeaderKonyAuthorization(token?: string): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Kony-Authorization': `${token}`,
		});
	}

	get<T>(endpoint: string, params?: any): Observable<T> {
		return this.http.get<T>(`${this.apiUrl}${endpoint}`, {
			headers: this.getHeaders(),
			params: new HttpParams({ fromObject: params }),
		});
	}

	postHeaderKony<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaderKonyAuthorization(),
		});
	}

	post<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
		});
	}

	postWithBasicAuth<T>(endpoint: string, data?: any): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getBasicAuthHeaders(),
		});
	}

	createOwnAccountTransfer<T>(
		endpoint: string,
		companyId: string,
		data?: any
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders({ companyId }),
		});
	}

	postKony<T>(endpoint: string, data?: any, token?: string): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaderKonyAuthorization(token),
		});
	}

	login<T>(endpoint: string, data: any): Observable<T> {
		return this.http.post<T>(`${this.loginUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
		});
	}

	put<T>(endpoint: string, data: any): Observable<T> {
		return this.http.put<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: this.getHeaders(),
		});
	}

	delete<T>(endpoint: string): Observable<T> {
		return this.http.delete<T>(`${this.apiUrl}${endpoint}`, {
			headers: this.getHeaders(),
		});
	}

	getAgence<T>(
		endpoint: string,
		companyId: string,
		params?: any
	): Observable<T> {
		return this.http.get<T>(`${this.apiInfo}${endpoint}`, {
			headers: this.getHeaders({ companyId }),
			params: new HttpParams({ fromObject: params }),
		});
	}

	generateFile(
		endpoint: string,
		params?: any,
		headers?: any
	): Observable<Blob> {
		return this.http.get<Blob>(`${this.apiUrl}${endpoint}`, {
			headers: this.getHeadersForGeneraleFile(headers),
			params: new HttpParams({ fromObject: params }),
			responseType: 'blob' as 'json',
		});
	}

	private getHeadersForGeneraleFile(
		customHeaders: { [key: string]: string } = {}
	): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Kony-App-Key': this.apiKey,
			'X-Kony-App-Secret': this.appSecret,
			'X-Kony-ReportingParams': '{"aid":"OnlineBanking"}',
			...customHeaders,
		});
	}

	postt<T>(endpoint: string, data?: any): Observable<T> {
		const basicAuthToken = btoa(`${this.apiKey}:${this.appSecret}`);

		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'X-Kony-Reportingparams':
					'{"os":"127.0.0.0","dm":"","did":"C06A7067-2BD1-4CB2-95B9-8EFF4BFF22FB","ua":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36","aid":"OnlineBanking","aname":"OnlineBanking","chnl":"desktop","plat":"web","aver":"1.0.0","atype":"spa","stype":"b2c","kuid":"","mfaid":"f04b62f1-7adc-4ea4-a870-f1fa87d523c6","mfbaseid":"7bf99ab8-7232-45a4-a324-b23c3f0a7b28","mfaname":"DigitalBanking-Composite","sdkversion":"202404.0.0","sdktype":"js","fid":"frmEnrollNow","sessiontype":"I","clientUUID":"1723460594231-d8dc-7125-5e72","rsid":"1723460600199-c40f-f30a-3098","svcid":"ExternalUsers_1"}',
				'X-Kony-App-Key': '190b1572c3a002e4afd2d96d735a425d',
				'X-Kony-App-Secret': 'a220d6fd896a64cd696dad067835b145',
				Authorization: `Basic ${basicAuthToken}`,
			}),
		});
	}

	postTermCond<T>(
		endpoint: string,
		data?: any,
		token?: string
	): Observable<T> {
		return this.http.post<T>(`${this.apiUrl}${endpoint}`, data, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'X-Kony-Reportingparams':
					'{"os":"127.0.0.0","dm":"","did":"C06A7067-2BD1-4CB2-95B9-8EFF4BFF22FB","ua":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36","aid":"OnlineBanking","aname":"OnlineBanking","chnl":"desktop","plat":"web","aver":"1.0.0","atype":"spa","stype":"b2c","kuid":"","mfaid":"f04b62f1-7adc-4ea4-a870-f1fa87d523c6","mfbaseid":"7bf99ab8-7232-45a4-a324-b23c3f0a7b28","mfaname":"DigitalBanking-Composite","sdkversion":"202404.0.0","sdktype":"js","fid":"frmEnrollNow","sessiontype":"I","clientUUID":"1723460594231-d8dc-7125-5e72","rsid":"1723460600199-c40f-f30a-3098","svcid":"ExternalUsers_1"}',
				'X-Kony-Authorization': `${token}`,
			}),
		});
	}
}
