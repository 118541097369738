import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillRepositoryImpl } from './infrastructure/repositories/bill.repository.impl';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        { provide: 'IBillRepository', useClass: BillRepositoryImpl },
       
    ]
})
export class CoreBillModule { }
