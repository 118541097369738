import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NNotificationMapper, NotificationCategoryMapper, NotificationMapper } from '../../application/mappers/notification.mapper';
import { INotificationRepository } from '../../domain/repositories/notification.repository';
import { NotificationService } from '../../../core-banking/application/services/notification/notification.service';
import { NNotification, Notificationcategory, NotificationCount } from '../../domain/entities/notification.entity';
import { CoreBankingError } from '../../../core-banking/domain/errors/core-banking-error';
import { NotificationError } from '../../domain/entities/error.entity';
import { adaptCoreBankingError } from '../adapters/error-adapter';

@Injectable({
	providedIn: 'root',
})
export class NotificationRepositoryImpl implements INotificationRepository {
	constructor(private readonly notificationService: NotificationService) {}

	getAll(): Observable<NotificationCount> {
		return this.notificationService.getMessagesNotifications().pipe(
			map((response) =>
				NotificationMapper.toDomain(response.notifications[0])
			),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}

	notificationCategory(): Observable<Notificationcategory[]>{
		return this.notificationService.getNotificationCategories().pipe(
			map((response) =>{
				return response.records.map((notif) =>
					NotificationCategoryMapper.toDomain(notif)
				);
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}

	notificationList(): Observable<NNotification[]>{
		return this.notificationService.notificationList().pipe(
			map((response) =>{
				return response.records.map((notif) =>
					NNotificationMapper.toDomain(notif)
				);
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}

	getNotificationCount(): Observable<{unreadNotificationCount: string;}[]>{
		return this.notificationService.getNotificationCount().pipe(
			map((response) =>{
				return response.UnreadNotifications
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}

	unreadNotification(notificationId: string): Observable<{ totalRecords: number;updatedRecords: number;notificationId: any;}>{
		return this.notificationService.unreadNotification(notificationId).pipe(
			map((response) =>{
				return { totalRecords: response.totalRecords,updatedRecords: response.updatedRecords,notificationId: response.notificationId}
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() =>
						NotificationError.fromError(adaptedError)
					);
				}
				return throwError(() => NotificationError.UNKNOWN_ERROR);
			})
		);
	}
}
