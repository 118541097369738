import { AlreadySigned } from "../../domain/entities/already-signed.entity";
import { AlreadySignedResponsePDO } from "../dtos/terms-condition.dto";

export class AlreadySignedMapper {
    static toDomain(response: AlreadySignedResponsePDO): AlreadySigned {
        return new AlreadySigned(
            response.dbpErrCode,
            response.opstatus,
            response.dbpErrMsg,
            response.httpStatusCode
        );
            
            
    }

    toDTO(response: AlreadySigned): AlreadySignedResponsePDO {
        return <AlreadySignedResponsePDO>{
            dbpErrCode: response.dbpErrCode,
            opstatus: response.opstatus,
            dbpErrMsg: response.dbpErrMsg,
            httpStatusCode :response.httpStatusCode
        };
    }
}
