import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IBillRepository } from '../../domain/repositories/bill.repository';
import { Partner } from '../../domain/entities/partner.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAllPartnersUseCase {
	constructor(
		@Inject('IBillRepository')
		private readonly billRepository: IBillRepository
	) {}

	execute(): Observable<Partner[]> {
		return this.billRepository.getAllPartners();
	}
}
