import {
	BeneficiaryDTO,
	BeneficiaryResponseDTO,
} from '../dtos/beneficiary.dto';
import { Beneficiary } from '../../domain/entities/beneficiary.entity';

export class BeneficiaryMapper {
	static toDTO(beneficiary: Beneficiary): BeneficiaryDTO {
		return {
			Id: beneficiary.id,
			accountNumber: beneficiary.accountNumber,
			bankName: beneficiary.bankName,
			nickName: beneficiary.nickName,
			isSameBankAccount: beneficiary.isSameBankAccount,
			isInternationalAccount: beneficiary.isInternationalAccount,
			feature: beneficiary.feature,
			IBAN: beneficiary.IBAN,
			beneficiaryName: beneficiary.beneficiaryName,
			addressLine1: beneficiary.addressLine1,
			addressLine2: beneficiary.addressLine2,
			city: beneficiary.city,
			zipcode: beneficiary.zipcode,
			country: beneficiary.country,
			email: beneficiary.email,
			state: beneficiary.state,
			swiftCode: beneficiary.swiftCode,
			sameBank: beneficiary.sameBank,
			otherBank: beneficiary.otherBank,
			phoneNumber: beneficiary.phoneNumber,
			isVerified: beneficiary.isVerified,
			singleCustomer: beneficiary.singleCustomer,
			cif: beneficiary.cif,
			payeeStatus: beneficiary.payeeStatus,
			payeeId: beneficiary.payeeId,
		};
	}

	static upSertResponseDTO(data: any): BeneficiaryResponseDTO {
		return {
			id: data.Id,
			payeeVerificationName: data.payeeVerificationName,
			referenceId: data.referenceId,
		};
	}

	static toDomain(dto: BeneficiaryDTO): Beneficiary {
		return new Beneficiary(
			dto.Id,
			dto.accountNumber,
			dto.bankName,
			dto.nickName,
			dto.isSameBankAccount,
			dto.isInternationalAccount,
			dto.feature,
			dto.IBAN,
			dto.beneficiaryName,
			dto.addressLine1,
			dto.addressLine2,
			dto.city,
			dto.zipcode,
			dto.country,
			dto.email,
			dto.phoneNumber,
			dto.state,
			dto.swiftCode,
			dto.sameBank,
			dto.otherBank,
			dto.isVerified,
			dto.singleCustomer,
			dto.cif,
			dto.payeeStatus,
			dto.payeeId
		);
	}
}
