import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';

import {
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { Logger } from '../../../../../shared/helpers/logger-helper';
import { ITermsConditionService } from '../../../domain/interfaces/terms-condition-service.interface';
import { ApiResponsePDO, CustomerTNCRequestPDO, GetPostLoginRequestPDO, GetPostLoginResponsePDO } from '../../dtos/terms-condifition/terms-condifition.dto';

@Injectable({
	providedIn: 'root',
})
export class TermsConditionService implements ITermsConditionService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getPostLogin(request: GetPostLoginRequestPDO) : Observable<GetPostLoginResponsePDO> {
		const token = request?.token;
		let basePostLoginRequest;
		if(!token) {
			basePostLoginRequest  = this.api.post<GetPostLoginResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.USER.GET_POST_LOGIN,
				request
			)
		}else{
			basePostLoginRequest  = this.api.postTermCond<GetPostLoginResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.USER.GET_POST_LOGIN,
				request,
				token
			)
		}
		return basePostLoginRequest
			.pipe(
                map((response) => {
					Logger.info('GetPostLogin response', response);
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_USER_SCHEDULED_TERMSANDCONDITIONS_FAILED',
						'Failed to get create Customer TNC For Login'
					)
				)
			);
	}

	createCustomerTNCForLogin(request: CustomerTNCRequestPDO) : Observable<ApiResponsePDO> {
		return this.api
			.post<ApiResponsePDO>(
				CORE_BANKING_API_ENDPOINTS.USER.CUSTOMER_TNC_FOR_LOGIN,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('getCreateCustomerTNCForLogins response', response);
					return response;
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_RECENT_TRANSACTIONS_FAILED',
						'Failed to get recent transactions'
					);
				})
			);
	}

}
