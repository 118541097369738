import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { UpdateSecurityQuestionsRequestDTO } from "../dtos/security-question.dto";

@Injectable({
    providedIn: 'root'
})
export class UpdateSecurityQuestionsUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(request: UpdateSecurityQuestionsRequestDTO): Observable<{ questionsMapped: string, status: string}> {
        return this.settingsRepository.updateSecurityQuestions(request);
    }
}
