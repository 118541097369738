import { Injectable } from '@angular/core';
import { ISettingsRepository } from '../../domain/repositories/settings.repository';
import { map, Observable } from 'rxjs';
import { SettingsService } from '../../../core-banking/application/services/settings/settings.service';
import { UserInfosMapper } from '../../application/mappers/user-infos.mapper';
import { UserInfos } from '../../domain/entities/user-infos.entity';
import { SecurityQuestionList } from '../../domain/entities/security-question.entity';
import { SecurityQuestionMapper } from '../../application/mappers/security-question.mapper';
import { RequestUpdateSecurityQuestionsOTPRequestDTO, UpdatePasswordRequestDTO, UpdatepasswordResponseDTO } from '../../../core-banking/application/dtos/settings/security-question.dto';
import { MFAAttributesMapper } from '../../application/mappers/mfa-attributes.mapper';
import { MFAAttributesRequestDTO } from '../../application/dtos/mfa-attributes.dto';
import {
	UpdateSecurityQuestionsRequestDTO,
	UpdateUserStatusRequestDTO,
	UpdateUserStatusResponseDTO,
} from '../../application/dtos/security-question.dto';
import { Logger } from '../../../../shared/helpers/logger-helper';
import { MFAAttributes } from '../../domain/entities/mfa-attributes.entity';
import { UserInfosDTO } from '../../application/dtos/user-infos.dto';

@Injectable({
	providedIn: 'root',
})
export class SettingsRepositoryImpl implements ISettingsRepository {
	constructor(private readonly settingsService: SettingsService) {}

	getUserInfos(): Observable<UserInfos> {
		return this.settingsService
			.getUserInfos()
			.pipe(map((response) => {
                console.log("getUserInfos Mapper: ", response);
                return UserInfosMapper.toDomain(response)
            }));
	}

	getUserProfileImage(): Observable<string> {
		return this.settingsService
			.getUserProfileImage()
			.pipe(map((response) => response.userImage));
	}
	updateUserProfileImage(image: string): Observable<string> {
		return this.settingsService
			.updateUserProfileImage(image)
			.pipe(map((response) => response.success));
	}
	deleteUserProfileImage(): Observable<string> {
		return this.settingsService
			.deleteUserProfileImage()
			.pipe(map((response) => response.success));
	}

	getSecurityQuestions(): Observable<SecurityQuestionList> {
		return this.settingsService
			.getSecurityQuestions()
			.pipe(
				map((response) =>
					SecurityQuestionMapper.toSecurityQuestionList(response)
				)
			);
	}

	requestUpdateSecurityQuestionsOTP(
		request: RequestUpdateSecurityQuestionsOTPRequestDTO
	): Observable<MFAAttributes> {
		return this.settingsService
			.requestUpdateSecurityQuestionsOTP(request)
			.pipe(
				map((response) => {
					Logger.debug(
						'SettingsRepositoryImpl requestUpdateSecurityQuestionsOTP MFA response: ',
						response
					);
					const eti = MFAAttributesMapper.toEntity(response);
					Logger.debug(
						'SettingsRepositoryImpl requestUpdateSecurityQuestionsOTP MFAAttributes converted: ',
						eti
					);
					return eti;
				})
			);
	}

	verifyUpdateSecurityQuestionsOTP(
		request: MFAAttributesRequestDTO
	): Observable<{ success: string; isOtpVerified: string }> {
		return this.settingsService
			.verifyUpdateSecurityQuestionsOTP(request)
			.pipe(
				map((response) => {
					return {
						success: response.success,
						isOtpVerified: response.isOtpVerified,
					};
				})
			);
	}

	updateSecurityQuestions(
		request: UpdateSecurityQuestionsRequestDTO
	): Observable<{ questionsMapped: string; status: string }> {
		return this.settingsService.updateSecurityQuestions(request).pipe(
			map((response) => {
				return {
					questionsMapped: response.questionsMapped,
					status: response.status,
				};
			})
		);
	}

    updatePassword(request: UpdatePasswordRequestDTO): Observable<UpdatepasswordResponseDTO> {
        return this.settingsService.updatedPassword(request).pipe(
            map(response => {
                console.log("updatedPassword: ", response);
                return response;
            })
        );
    }

	getUser(): Observable<UserInfosDTO> {
		return this.settingsService.getUser().pipe(map((response) => response));
	}

	updateUserStatus(
		request: UpdateUserStatusRequestDTO
	): Observable<UpdateUserStatusResponseDTO> {
		return this.settingsService
			.updateUserStatus(request)
			.pipe(map((response) => response));
	}
}
