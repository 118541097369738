import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ValidateTransferOtpUseCase } from '../../application/use-cases/validate-transfer-otp-use-case.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as transferActions from '../actions/transfer.actions';
import { GetTransferHistoryUseCase } from '../../application/use-cases/get-transfer-history.use-case';
import { TransferToIntraAccountUseCase } from '../../application/use-cases/transfer-to-intra-account.use-case';
import { TransferToOwnerAccountUseCase } from '../../application/use-cases/transfer-to-owner-account.use-case';
import { TransferToExternalAccountUseCase } from '../../application/use-cases/transfer-to-external-account.use-case';
import { TransferToInternationalAccountUseCase } from '../../application/use-cases/transfer-to-international-account.use-case';
import { TransferSicaToExternalAccountUseCase } from '../../application/use-cases/transfer-sica-to-external-account.use-case';
import { RecurringTransferUseCase } from '../../application/use-cases/recurring-transfer.use-case';
import { Injectable } from '@angular/core';
import { GetBankDateUseCase } from '../../application/use-cases/get-bank-date.use-case';
import { GetUemoaBanksUseCase } from '../../application/use-cases/get-uemoa-bank.use-case';
import { RtgsTransferUseCase } from '../../application/use-cases/rtgs-transfer.use-case';
import { TransferToOwnerAccountStandingOrderUseCase } from '../../application/use-cases/transfer-to-owner-account-standing-order.use-case';
import { TransferToIntraAccountStandingOrderUseCase } from '../../application/use-cases/transfer-to-intra-account-standing-order.use-case';
import { TransferSicaToExternalAccountStandingOrderUseCase } from '../../application/use-cases/transfer-sica-to-external-account-standing-order.use-case';
import { TransferToInternationalAccountStandingOrderUseCase } from '../../application/use-cases/transfer-to-international-account-standing-order.use-case';
import { GetTransferInstructionsUseCase } from '../../application/use-cases/get-transfer-instructions.use-case';
import { InterBankFundTransferDeleteUseCase } from '../../application/use-cases/inter-bank-fund-transfer-delete.use-case';
import { InternationalFundTransferDeleteUseCase } from '../../application/use-cases/international-fund-transfer-delete.use-case';
import { IntraBankAccFundTransferDeleteUseCase } from '../../application/use-cases/intra-bank-acc-fund-transfer-delete.use-case';
import { TransferToOwnAccountsDeleteUseCase } from '../../application/use-cases/transfer-to-own-accounts-delete.use-case';
import { FetchBaseCurrencyUseCase } from '../../application/use-cases/fetch-base-currency.use-case';
import { FetchCurrencyRatesUseCase } from '../../application/use-cases/fetch-currency-rates.use-case';
import { FetchDashboardCurrencyListUseCase } from '../../application/use-cases/fetch-dashboard-currency-list.use-case';

@Injectable()
export class TransferEffects {
	constructor(
		private readonly actions$: Actions,
		private readonly validateTransferOtpUseCase: ValidateTransferOtpUseCase,
		private readonly getTransferHistory: GetTransferHistoryUseCase,
		private readonly transferToIntraAccountUseCase: TransferToIntraAccountUseCase,
		private readonly transferToAnotherAccountUseCase: TransferToOwnerAccountUseCase,
		private readonly transferToExternalAccountUseCase: TransferToExternalAccountUseCase,
		private readonly transferToInternalAccountUseCase: TransferToInternationalAccountUseCase,
		private readonly RecurringTransferUseCase: RecurringTransferUseCase,
		private readonly getBankDateUseCase: GetBankDateUseCase,
		private readonly getUemoaBanksUseCase: GetUemoaBanksUseCase,
		private readonly rtgsTransferUseCase: RtgsTransferUseCase,
		private readonly transferSicaToExternalAccountUseCase: TransferSicaToExternalAccountUseCase,
		private readonly transferToOwnerAccountStandingOrderUseCase: TransferToOwnerAccountStandingOrderUseCase,
		private readonly transferToIntraAccountStandingOrderUseCase: TransferToIntraAccountStandingOrderUseCase,
		private readonly transferSicaToExternalAccountStandingOrderUseCase: TransferSicaToExternalAccountStandingOrderUseCase,
		private readonly transferToInternationalAccountStandingOrderUseCase: TransferToInternationalAccountStandingOrderUseCase,
		private readonly getTransferInstructionsUseCase: GetTransferInstructionsUseCase,
		private readonly internationalFundTransferDeleteUseCase: InternationalFundTransferDeleteUseCase,
		private readonly interBankFundTransferDeleteUseCase: InterBankFundTransferDeleteUseCase,
		private readonly intraBankAccFundTransferDeleteUseCase: IntraBankAccFundTransferDeleteUseCase,
		private readonly transferToOwnAccountsDeleteUseCase: TransferToOwnAccountsDeleteUseCase,
		private readonly fetchBaseCurrencyUseCase: FetchBaseCurrencyUseCase,
		private readonly fetchCurrencyRatesUseCase: FetchCurrencyRatesUseCase,
		private readonly fetchDashboardCurrencyListUseCase: FetchDashboardCurrencyListUseCase
	) {}

	recurringTransfer$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.recurringTransferAction),
			switchMap(({ amount, receiverAccountNumber }) =>
				this.RecurringTransferUseCase.execute().pipe(
					map((success) =>
						transferActions.recurringTransferSuccessAction()
					),
					catchError((error) =>
						of(transferActions.recurringTransferFailureAction({ error }))
					)
				)
			)
		)
	);

	transferToInternationalAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToInternationalAccountAction),
			switchMap(({ transfer }) =>
				this.transferToInternalAccountUseCase.execute(transfer).pipe(
					// map((success) =>transferActions.transferToInternationalAccountSuccessAction({ transfer: success}),
					map((transfer) =>
						transferActions.transferToInternationalAccountSuccessAction({
							transfer,
						})
					),
					catchError((error) =>
						of(
							transferActions.transferToInternationalAccountFailureAction(
								{ error }
							)
						)
					)
				)
			)
		)
	);

	transferToExternalAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transfertToExternalAccountAction),
			switchMap(({ amount, receiverAccountNumber }) =>
				this.transferToExternalAccountUseCase.execute().pipe(
					map((success) =>
						transferActions.transfertToExternalAccountSuccessAction()
					),
					catchError((error) =>
						of(
							transferActions.transfertToExternalAccountFailureAction({
								error,
							})
						)
					)
				)
			)
		)
	);

	transferToAnotherAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToAnotherAccountAction),
			switchMap(({ transfer }) =>
				this.transferToAnotherAccountUseCase.execute(transfer).pipe(
					map((transfer) =>
						transferActions.transferToAnotherAccountSuccessAction({
							transfer,
						})
					),
					catchError((error) => {
						console.log('error: effect ', error);
						return of(
							transferActions.transferToAnotherAccountFailureAction({
								error,
							})
						);
					})
				)
			)
		)
	);

	transferToIntraAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToIntraAccountAction),
			switchMap(({ transfer }) =>
				this.transferToIntraAccountUseCase.execute(transfer).pipe(
					map((transfer) =>
						transferActions.transferToIntraAccountSuccessAction({
							transfer,
						})
					),
					catchError((error) =>
						of(
							transferActions.transferToIntraAccountFailureAction({
								error,
							})
						)
					)
				)
			)
		)
	);

	validateTransferOtp$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.validateTransferByOtpAction),
			switchMap(({ otp }) =>
				this.validateTransferOtpUseCase.execute(otp).pipe(
					map((success) =>
						transferActions.validateTransferByOtpSuccessAction({
							success,
						})
					),
					catchError((error) =>
						of(
							transferActions.validateTransferByOtpFailureAction({
								error,
							})
						)
					)
				)
			)
		)
	);

	transferHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferHistoryAction),
			switchMap(() => {
				return this.getTransferHistory.execute().pipe(
					map((transfers) => {
						return transferActions.transferHistorySuccessAction({
							transfers,
						});
					}),
					catchError((error) =>
						of(
							transferActions.transferHistoryFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);

	bankDateAction$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(transferActions.bankDateAction),
			switchMap(() =>
				this.getBankDateUseCase.execute().pipe(
					map((bankDate) => {
						return transferActions.bankDateSuccessAction({ bankDate });
					}),
					catchError((error) =>
						of(transferActions.bankDateFailureAction({ error }))
					)
				)
			)
		);
	});

	transferSicaToExternalAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferSicaToExternalAccountAction),
			switchMap(({ transfer }) => {
				console.log('Effect transfer : ', transfer);
				return this.transferSicaToExternalAccountUseCase
					.execute(transfer)
					.pipe(
						map((success) =>
							transferActions.transferSicaToExternalAccountSuccessAction(
								{ transfer: success }
							)
						),
						catchError((error) =>
							of(
								transferActions.transferSicaToExternalAccountFailureAction(
									{ error }
								)
							)
						)
					);
			})
		)
	);

	loadUemoaBanks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.loadUemoaBanksAction),
			switchMap(() =>
				this.getUemoaBanksUseCase.execute().pipe(
					map((uemoaBanks) =>
						transferActions.loadUemoaBanksSuccessAction({
							uemoaBanks,
						})
					),
					catchError((error) =>
						of(transferActions.loadUemoaBanksFailureAction({ error }))
					)
				)
			)
		)
	);

	rtgsTransferAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.rtgsTransferAction),
			switchMap(({ transfer }) => {
				return this.rtgsTransferUseCase.execute(transfer).pipe(
					map((response) => {
						return transferActions.rtgsTransferSuccessAction({
							transfer: response,
						});
					}),
					catchError((error) =>
						of(
							transferActions.rtgsTransferFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);

	transferToAnotherAccountStandingOrderAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToAnotherAccountStandingOrderAction),
			switchMap(({ transfer }) => {
				return this.transferToOwnerAccountStandingOrderUseCase
					.execute(transfer)
					.pipe(
						map((response) => {
							return transferActions.transferToAnotherAccountStandingOrderSuccessAction(
								{
									transfer: response,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.transferToAnotherAccountStandingOrderFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);
	transferToIntraAccountStandingOrderAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToIntraAccountStandingOrderAction),
			switchMap(({ transfer }) => {
				return this.transferToIntraAccountStandingOrderUseCase
					.execute(transfer)
					.pipe(
						map((response) => {
							return transferActions.transferToIntraAccountStandingOrderSuccessAction(
								{
									transfer: response,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.transferToIntraAccountStandingOrderFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);
	transferSicaToExternalAccountStandingOrderAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				transferActions.transferSicaToExternalAccountStandingOrderAction
			),
			switchMap(({ transfer }) => {
				return this.transferSicaToExternalAccountStandingOrderUseCase
					.execute(transfer)
					.pipe(
						map((response) => {
							return transferActions.transferSicaToExternalAccountStandingOrderSuccessAction(
								{
									transfer: response,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.transferSicaToExternalAccountStandingOrderFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);
	transferToInternationalAccountStandingOrderAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				transferActions.transferToInternationalAccountStandingOrderAction
			),
			switchMap(({ transfer }) => {
				return this.transferToInternationalAccountStandingOrderUseCase
					.execute(transfer)
					.pipe(
						map((response) => {
							return transferActions.transferToInternationalAccountStandingOrderSuccessAction(
								{
									transfer: response,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.transferToInternationalAccountStandingOrderFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);

	transferInstructionsAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferInstructionsAction),
			switchMap(() => {
				return this.getTransferInstructionsUseCase.execute().pipe(
					map((transfers) => {
						return transferActions.transferInstructionsSuccessAction({
							transfers,
						});
					}),
					catchError((error) =>
						of(
							transferActions.transferInstructionsFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);

	internationalFundTransferDeleteAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.internationalFundTransferDeleteAction),
			switchMap(({ data }) => {
				return this.internationalFundTransferDeleteUseCase
					.execute(data)
					.pipe(
						map((transfer) => {
							return transferActions.internationalFundTransferDeleteSuccessAction(
								{
									transfer,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.internationalFundTransferDeleteFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);

	interBankFundTransferDeleteAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.interBankFundTransferDeleteAction),
			switchMap(({ data }) => {
				return this.interBankFundTransferDeleteUseCase.execute(data).pipe(
					map((transfer) => {
						return transferActions.interBankFundTransferDeleteSuccessAction(
							{
								transfer,
							}
						);
					}),
					catchError((error) =>
						of(
							transferActions.interBankFundTransferDeleteFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);
	intraBankAccFundTransferDeleteAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.intraBankAccFundTransferDeleteAction),
			switchMap(({ data }) => {
				return this.intraBankAccFundTransferDeleteUseCase
					.execute(data)
					.pipe(
						map((transfer) => {
							return transferActions.intraBankAccFundTransferDeleteSuccessAction(
								{
									transfer,
								}
							);
						}),
						catchError((error) =>
							of(
								transferActions.intraBankAccFundTransferDeleteFailureAction(
									{
										error,
									}
								)
							)
						)
					);
			})
		)
	);
	transferToOwnAccountsDeleteAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.transferToOwnAccountsDeleteAction),
			switchMap(({ data }) => {
				return this.transferToOwnAccountsDeleteUseCase.execute(data).pipe(
					map((transfer) => {
						return transferActions.transferToOwnAccountsDeleteSuccessAction(
							{
								transfer,
							}
						);
					}),
					catchError((error) =>
						of(
							transferActions.transferToOwnAccountsDeleteFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);

	fetchBaseCurrencyAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.fetchBaseCurrencyAction),
			switchMap(({ data }) => {
				return this.fetchBaseCurrencyUseCase.execute(data).pipe(
					map((response) => {
						return transferActions.fetchBaseCurrencySuccessAction({
							response,
						});
					}),
					catchError((error) =>
						of(
							transferActions.fetchBaseCurrencyFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);
	fetchDashboardCurrencyListAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.fetchDashboardCurrencyListAction),
			switchMap(({ data }) => {
				return this.fetchDashboardCurrencyListUseCase.execute(data).pipe(
					map((response) => {
						return transferActions.fetchDashboardCurrencyListSuccessAction(
							{
								response,
							}
						);
					}),
					catchError((error) =>
						of(
							transferActions.fetchDashboardCurrencyListFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);
	fetchCurrencyRatesAction$ = createEffect(() =>
		this.actions$.pipe(
			ofType(transferActions.fetchCurrencyRatesAction),
			switchMap(({ data }) => {
				return this.fetchCurrencyRatesUseCase.execute(data).pipe(
					map((response) => {
						return transferActions.fetchCurrencyRatesSuccessAction({
							response,
						});
					}),
					catchError((error) =>
						of(
							transferActions.fetchCurrencyRatesFailureAction({
								error,
							})
						)
					)
				);
			})
		)
	);
}
