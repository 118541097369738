export class TransferStandingOrder {
	constructor(
		public id: string,
		public frequency: string,
		public startDate: string,
		public endDate: string,
		public transactionAmount: string,
		public paymentDetails: string, 
		public status: string
	) {}
}
