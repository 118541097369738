import { Injectable } from '@angular/core';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { AuthService } from '../../../core-banking/application/services/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { AuthSession } from '../../domain/entities/auth-session.entity';
import { AuthMapper } from '../../application/mappers/auth.mapper';
import { CompanyService } from '../../../core-banking/application/services/company/company.service';
import {
	ActivateAccountDTO,
	CreatePasswordDTO,
	LoginCredentialsDTO,
	RegisterUserDTO,
	ResendOTPDTO,
	PasswordForgottenDTO,
	ResetPasswordDTO,
	VerifyOTPDTO,
	ResendActivateDTO,
	LanguageResponsePDO,
	LanguageRequestDTO,
} from '../../application/dtos/authentication.dto';
import { catchError, map } from 'rxjs/operators';
import {
	ContractCustomer,
	UserContract,
} from '../../domain/entities/user-contract.entity';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';
import { CoreBankingError } from '../../../core-banking/domain/errors/core-banking-error';
import { AuthError } from '../../domain/entities/error.entity';
import { adaptCoreBankingError } from '../adapters/error-adapter';
import { TermsAndConditions } from '../../domain/entities/terms-and-conditions.entity';
import {
	VerifyUserRequestDTO,
	VerifyUserResponseDTO,
} from '../../../core-banking/application/dtos/auth/user-login.dto';
import { TrackDevicePDO } from '../../../core-banking/application/dtos/auth/track-device.dto';
import { Store } from '@ngrx/store';
import { getPostLoginAction } from '../../../terms-condition/state/actions/terms-condition.actions';

@Injectable({
	providedIn: 'root',
})
export class AuthRepositoryImpl implements IAuthRepository {
	constructor(
		private readonly authService: AuthService,
		private readonly comanyService: CompanyService,
		private readonly store: Store
	) {}

	login(credentials: LoginCredentialsDTO): Observable<AuthSession> {
		const dto = AuthMapper.toLoginCredentialsDTO(credentials);
		return this.authService.login(dto).pipe(
			map((response) => {
				const legalEntityId =
					response?.profile?.user_attributes?.legalEntityId;
				const data = {
					languageCode: 'en-US',
					termsAndConditionsCode: 'Login_TnC',
					legalEntityId: legalEntityId ?? '',
					lastLoginTime: '',
					tNCRefreshPeriod: '30',
					lastModifiedTSforTNCRefresh: this.getCurrentDate(),
					token: response?.claims_token?.value,
				};
				this.store.dispatch(
					getPostLoginAction({
						request: data,
					})
				);
				return AuthMapper.toAuthSessionDomain(response);
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}
				// Si l'erreur n'est pas une CoreBankingError, on la traite comme une erreur inconnue
				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	register(user: RegisterUserDTO): Observable<any> {
		const dto = AuthMapper.toRegisterUserDTO(user);
		return this.authService.register(dto).pipe(
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}
				// Si l'erreur n'est pas une CoreBankingError, on la traite comme une erreur inconnue
				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	getLegalEntity(): Observable<LegalEntity[]> {
		return this.comanyService
			.getLegalEntities()
			.pipe(
				map((response) =>
					response.companyLegalUnits.map((dto) =>
						AuthMapper.toLegalEntityDomain(dto)
					)
				)
			);
	}

	activateAccount(activationData: ActivateAccountDTO): Observable<string> {
		const dto = AuthMapper.toActivateAccountDTO(activationData);
		return this.authService.activateAccount(dto).pipe(
			map((response) => {
				if (
					response.isActivationCodeValid === 'true' &&
					'serviceKey' in response
				) {
					return response.serviceKey;
				}
				return '';
			})
		);
	}

	createPassword(data: CreatePasswordDTO): Observable<boolean> {
		const dto = AuthMapper.toCreatePasswordDTO(data);
		return this.authService
			.createPassword(dto)
			.pipe(map((response) => response.Status === 'Success'));
	}

	logout(): Observable<void> {
		return this.authService.logout();
	}

	getUserContracts(): Observable<UserContract[]> {
		return this.authService.getUserContracts().pipe(
			map((response) => {
				if (
					response.contracts &&
					response.contracts.length > 0 &&
					response.opstatus === 0
				) {
					return response.contracts.map(
						(contract) =>
							new UserContract(
								contract.contractId,
								contract.contractName,
								contract.contractCustomers.map(this.mapContractCustomer)
							)
					);
				} else {
					throw new Error('No contracts found');
				}
			}),
			catchError((error: CoreBankingError) => {
				return throwError(() => console.error(error));
			})
		);
	}

	private mapContractCustomer(customer: any): ContractCustomer {
		return new ContractCustomer(
			customer.coreCustomerId,
			customer.isPrimary === 'true',
			customer.isBusiness === 'true',
			customer.coreCustomerName,
			customer.userRole,
			customer.actions
				// .replace(/^\[|\]$/g, '')
				.replace(/(^\[|\]$)/g, '')
				.split(', ')
				.map((item: any) => item.trim())
				.filter(Boolean)
		);
	}

	passwordForgotten(credentials: PasswordForgottenDTO): Observable<any> {
		const dto = AuthMapper.toPasswordForgottenDTO(credentials);
		return this.authService.postVerifyCoreUser(dto).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	resendOTP(credentials: ResendOTPDTO): Observable<any> {
		const dto = AuthMapper.toResendOTPDTO(credentials);
		return this.authService.requestResetPasswordOTP(dto).pipe(
			map((response) => {
				return response;
			}),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	resetPassword(credentials: ResetPasswordDTO): Observable<any> {
		const dto = AuthMapper.toResetPassword(credentials);
		return this.authService.resetPassword(dto).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	verifyOTP(credentials: VerifyOTPDTO): Observable<any> {
		const dto = AuthMapper.toVerifyOTP(credentials);
		return this.authService.verifyOTP(dto).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	termsAndConditions(): Observable<TermsAndConditions> {
		return this.authService.getTermsAndConditions().pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	resendActivateCode(data: ResendActivateDTO): Observable<any> {
		return this.authService.resendActivateCode(data).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}

				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	verifyUser(
		credentials: VerifyUserRequestDTO
	): Observable<VerifyUserResponseDTO> {
		const dto = { password: credentials.password };
		return this.authService.verifyUser(dto).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}
				// Si l'erreur n'est pas une CoreBankingError, on la traite comme une erreur inconnue
				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	setDefaultLanguage(
		data: LanguageRequestDTO
	): Observable<LanguageResponsePDO> {
		return this.authService.setDefaultLanguage(data).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}
				// Si l'erreur n'est pas une CoreBankingError, on la traite comme une erreur inconnue
				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	trackDeviceRegistration(): Observable<TrackDevicePDO> {
		return this.authService.trackDeviceRegistration().pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => AuthError.fromError(adaptedError));
				}
				// Si l'erreur n'est pas une CoreBankingError, on la traite comme une erreur inconnue
				return throwError(() => AuthError.UNKNOWN_ERROR);
			})
		);
	}

	private getCurrentDate() {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, '0'); // Mois indexé à partir de 0
		const day = String(today.getDate()).padStart(2, '0');

		return `${year}-${month}-${day}`;
	}
}
