import {ITransferRepository} from "../../domain/repositories/transfer.repository";
import {inject, Injectable} from "@angular/core";

@Injectable({ providedIn: 'root'})
export class TransferToExternalAccountUseCase {
    transferService = inject(ITransferRepository);

    execute() {
        return this.transferService.transferToExternalBank();
    }
}