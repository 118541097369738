export class LegalEntity {
  constructor(
    public id: string,
    public companyName: string,
    public typeId: string,
    public language: string,
    public shortName: string,
    public parentId: string,
    public baseCurrency: string,
    public countryCode: string,
    public effectiveDate: string,
    public closeDate: string
  ) {}
}
