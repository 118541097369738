import { inject, Injectable } from '@angular/core';
import { ITransactionRepository } from '../../domain/repositories/transaction.repository';
import { Observable } from 'rxjs';
import { SearchTransactionsRequestDTO } from '../dtos/transaction.dto';
import { SearchTransaction } from '../../domain/entities/search-transaction.entity';

@Injectable({ providedIn: 'root' })
export class SearchTransactionsUseCase {
	transactionService = inject(ITransactionRepository);

	execute(
		request: SearchTransactionsRequestDTO
	): Observable<SearchTransaction[]> {
		return this.transactionService.searchTransactions(request);
	}
}
