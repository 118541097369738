import { Inject, Injectable } from '@angular/core';
import { IApprovalRequestRepository} from '../../../domain/repositories/approval-request.repository';
import { GetAllPendingApprovalRequestDTO } from '../../dtos/approval-request.dto';

@Injectable({ providedIn: 'root' })
export class GetRequestHistoryUseCase {
	constructor(@Inject('IApprovalRequestRepository') private readonly approvalRequestRepository: IApprovalRequestRepository) {}

	execute(request: GetAllPendingApprovalRequestDTO) {
		return this.approvalRequestRepository.getRequestHistory(request);
	}
}
