import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISettingsRepository } from "../../domain/repositories/settings.repository";
import { UserInfos } from "../../domain/entities/user-infos.entity";

@Injectable({
    providedIn: 'root'
})
export class GetUserInfosUseCase {
    constructor(@Inject('ISettingsRepository') private readonly settingsRepository: ISettingsRepository) {}

    execute(): Observable<UserInfos | null> {
        return this.settingsRepository.getUserInfos();
    }
}
