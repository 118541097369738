import { Injectable } from '@angular/core';
import { PermissionService } from '../services/permission..service';
import { FeatureCodes } from './codes/features.codes';
import { PermissionCodes } from './codes/permissions.code';

@Injectable({providedIn: 'root'})
export class SettingsPermissions {
    
    constructor(private readonly permissionService: PermissionService) {}

    get canManageSettings(): boolean {
        return (
            this.canManageProfileSettings ||
            this.canManageSecreteQuestions ||
            this.canManageAlert
        );
    }
    get canManageProfileSettings(): boolean {
        return this.permissionService.hasFeature(FeatureCodes.PROFILE_SETTINGS);
    }
    get canManageSecreteQuestions(): boolean {
        return this.permissionService.hasFeature(FeatureCodes.RESET_SECURITY_QUESTIONS);
    }
    get canManageAlert(): boolean {
        return this.permissionService.hasFeature(FeatureCodes.ALERT_MANAGEMENT);
    }

    // Profile Settings Permissions
    get canUpdateProfileSettings(): boolean {
        return (
            this.canManageProfileSettings &&
            this.permissionService.hasPermission(PermissionCodes.PROFILE_SETTINGS_UPDATE)
        );
    }
    get canViewUpdateProfileSettings(): boolean {
        return (
            this.canManageProfileSettings &&
            this.permissionService.hasPermission(PermissionCodes.PROFILE_SETTINGS_VIEW)
        );
    }


    // Reset Security Questions Permissions
    get canResetSecurityQuestions(): boolean {
        return (
            this.canManageSecreteQuestions &&
            this.permissionService.hasPermission(PermissionCodes.RESET_SECURITY_QUESTIONS)
        );
    }
}