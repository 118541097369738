import { createAction, props } from '@ngrx/store';
import { RequestUpdateSecurityQuestionsOTPRequestDTO, UpdatePasswordRequestDTO, UpdatepasswordResponseDTO } from '../../core-banking/application/dtos/settings/security-question.dto';
import { MFAAttributesRequestDTO } from '../application/dtos/mfa-attributes.dto';
import { UserInfosDTO } from '../application/dtos/user-infos.dto';
import {
	UpdateUserStatusRequestDTO,
	UpdateUserStatusResponseDTO,
} from '../application/dtos/security-question.dto';

export const getUserInfos = createAction('[Settings] Get User Infos');
export const getUserInfosSuccess = createAction(
	'[Settings] Get User Infos Success',
	props<{ userInfos: any }>()
);
export const getUserInfosFailure = createAction(
	'[Settings] Get User Infos Failure',
	props<{ error: any }>()
);

export const getUserProfileImage = createAction(
	'[Settings] Get User Profile Image'
);
export const getUserProfileImageSuccess = createAction(
	'[Settings] Get User Profile Image Success',
	props<{ userProfileImage: string }>()
);
export const getUserProfileImageFailure = createAction(
	'[Settings] Get User Profile Image Failure',
	props<{ error: any }>()
);

export const updateUserProfileImage = createAction(
	'[Settings] Update User Profile Image',
	props<{ userProfileImage: string }>()
);
export const updateUserProfileImageSuccess = createAction(
	'[Settings] Update User Profile Image Success'
);
export const updateUserProfileImageFailure = createAction(
	'[Settings] Update User Profile Image Failure',
	props<{ error: any }>()
);

export const deleteUserProfileImage = createAction(
	'[Settings] Delete User Profile Image'
);
export const deleteUserProfileImageSuccess = createAction(
	'[Settings] Delete User Profile Image Success'
);
export const deleteUserProfileImageFailure = createAction(
	'[Settings] Delete User Profile Image Failure',
	props<{ error: any }>()
);

export const getSecurityQuestions = createAction(
	'[Settings] Get Security Questions'
);
export const getSecurityQuestionsSuccess = createAction(
	'[Settings] Get Security Questions Success',
	props<{ securityQuestions: any }>()
);
export const getSecurityQuestionsFailure = createAction(
	'[Settings] Get Security Questions Failure',
	props<{ error: any }>()
);

export const requestUpdateSecurityQuestionsOTP = createAction(
	'[Settings] Request Update Security Questions',
	props<{ request: RequestUpdateSecurityQuestionsOTPRequestDTO }>()
);
export const requestUpdateSecurityQuestionsOTPSuccess = createAction(
	'[Settings] Request Update Security Questions Success',
	props<{ mfaAttributes: any }>()
);
export const requestUpdateSecurityQuestionsOTPFailure = createAction(
	'[Settings] Request Update Security Questions Failure',
	props<{ error: any }>()
);

export const verifyUpdateSecurityQuestionsOTP = createAction(
	'[Settings] Verify Update Security Questions OTP',
	props<{ request: MFAAttributesRequestDTO }>()
);
export const verifyUpdateSecurityQuestionsOTPSuccess = createAction(
	'[Settings] Verify Update Security Questions OTP Success',
	props<{ isOtpVerified: string }>()
);
export const verifyUpdateSecurityQuestionsOTPFailure = createAction(
	'[Settings] Verify Update Security Questions OTP Failure',
	props<{ error: any }>()
);

export const updateSecurityQuestions = createAction(
	'[Settings] Update Security Questions',
	props<{ request: any }>()
);
export const updateSecurityQuestionsSuccess = createAction(
	'[Settings] Update Security Questions Success'
);
export const updateSecurityQuestionsFailure = createAction(
	'[Settings] Update Security Questions Failure',
	props<{ error: any }>()
);

export const getUser = createAction('[Settings] Get User');
export const getUserSuccess = createAction(
	'[Settings] Get User Success',
	props<{ user: UserInfosDTO }>()
);
export const getUserFailure = createAction(
	'[Settings] Get User Failure',
	props<{ error: any }>()
);

export const updateUserStatus = createAction(
	'[Settings] Update User Status',
	props<{ request: UpdateUserStatusRequestDTO }>()
);
export const updateUserStatusSuccess = createAction(
	'[Settings] Update User Status Success',
	props<{ update: UpdateUserStatusResponseDTO }>()
);
export const updateUserStatusFailure = createAction(
	'[Settings] Update User Status Failure',
	props<{ error: any }>()
);

export const updatePassword = createAction('[Settings] Update Password', props<{ request: UpdatePasswordRequestDTO }>());
export const updatePasswordSuccess = createAction('[Settings] Update Password Success', props<{ reponse: UpdatepasswordResponseDTO }>());
export const updatePasswordFailure = createAction('[Settings] Update Password Failure', props<{ error: any }>());

export const clearSettingsError = createAction('[Settings] Clear Error');
