import { DiscussionCategory } from "../../domain/entities/discussion-category.entity";
import { DiscussionCategoryDTO } from "../dtos/discussion-category.dto";


export class DiscussionCategoryMapper {
    static toDTO(category: DiscussionCategory): DiscussionCategoryDTO {
        return {
            id: category.id,
            lastmodifiedts: category.lastmodifiedts,
            createdby: category.createdby,
            modifiedby: category.modifiedby,
            synctimestamp: category.synctimestamp,
            createdts: category.createdts,
            softdeleteflag: category. softdeleteflag,
            Name: category.Name,
        };
    }

    static toDomain(dto: DiscussionCategoryDTO): DiscussionCategory {
        return new DiscussionCategory(
            dto.id,
            dto.lastmodifiedts,
            dto.createdby,
            dto.modifiedby,
            dto.synctimestamp,
            dto.createdts,
            dto.softdeleteflag,
            dto.Name
        )
    }
}

