<div class="relative mt-5">
	<!-- Label non flottant -->
	<label
		[for]="name"
		*ngIf="label"
		class="block mb-2 text-sm font-medium text-gray-700"
	>
		{{ label }}
		<span *ngIf="isRequired" class="text-primary">*</span>
	</label>

	<textarea
		#textareaElement
		[attr.id]="id"
		[attr.name]="name"
		[attr.placeholder]="placeholder"
		[attr.rows]="rows"
		[attr.maxlength]="maxLength"
		[disabled]="disabled"
		[value]="value"
		(input)="onInput($event)"
		(blur)="onBlur()"
		[ngClass]="{
			'resize-none': !autoResize,
			'bg-gray-100': variant === 'filled',
			'border-red-500': error,
			'pr-10': showCharCount,
			'bg-zinc-100': disabled,
		}"
		class="block w-full p-3 text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary transition duration-150 ease-in-out text-sm"
	></textarea>
	<div
		*ngIf="showCharCount"
		class="absolute bottom-3 right-3 text-sm text-gray-500"
	>
		{{ (value &&value.trim()) ? value.length : 0 }}/{{ maxLength }}
	</div>
</div>
<div *ngIf="error" class="text-red-500 text-sm mt-1">{{ error }}</div>
