import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InfoState } from '../reducers/info.reducer';

export const selectInfoState = createFeatureSelector<InfoState>('info');

export const selectContactUs = createSelector(
	selectInfoState,
	(state: InfoState) => state.contactUs
);

export const selectInfosLoading = createSelector(
	selectInfoState,
	(state: InfoState) => state.loading
);

export const selectInfosError = createSelector(
	selectInfoState,
	(state: InfoState) => state.error
);

export const selectAgences = createSelector(
	selectInfoState,
	(state: InfoState) => state.agences
);
