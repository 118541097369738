import { ApprovalRequest, ApprovalRequestAction } from "../../domain/entities/approval-request.entity";
import { ApprovalRequestActionResponseDTO, ApprovalRequestDTO } from "../dtos/approval-request.dto";

export class ApprovalRequestMapper {
	static toApprovalRequestEntity(dto: ApprovalRequestDTO): ApprovalRequest {
		return {
			id: dto.requestId,
			assocRequestId: dto.assocRequestId,
			additionalMeta: dto.additionalMeta,
			companyLegalUnit: dto.companyLegalUnit,
			featureName: dto.featureName,
			featureActionId: dto.featureActionId,
			featureActionName: dto.featureActionName,
			limitGroupId: dto.limitGroupId,
			limitGroupName: dto.limitGroupName,
			transactionId: dto.transactionId,
			contractId: dto.contractId,
			companyId: dto.companyId,
			accountId: dto.accountId,
			status: dto.status,
			sentBy: dto.sentBy,
			amIApprover: dto.amIApprover,
			amICreator: dto.amICreator,
			receivedApprovals: dto.receivedApprovals,
			actedByMeAlready: dto.actedByMeAlready,
			processingDate: dto.processingDate,
			amount: dto.amount,
			payee: dto.payee,
			frequency: dto.frequency,
			customerName: dto.customerName,
			serviceCharge: dto.serviceCharge,
			transactionAmount: dto.transactionAmount,
			transactionCurrency: dto.transactionCurrency,
			customerId: dto.customerId,
			confirmationNumber: dto.confirmationNumber,
			fromAccountCurrency: dto.fromAccountCurrency,
			isGroupMatrix: dto.isGroupMatrix,
			lcAmount: dto.lcAmount,
			beneficiaryName: dto.beneficiaryName,
			beneficiaryCity: dto.beneficiaryCity,
			accountType: dto.accountType,
			firstName: dto.firstName,
			lastName: dto.lastName,
			createdTimestamp: dto.createdts,
			oldInfo: dto.oldInfo,
			sentByName: dto.sentByName,
			sentByUserName: dto.sentByUserName,
			iban: dto.iban,
			country: dto.country,
			bankName: dto.bankName,
			payeeId: dto.payeeId,
			isSameBankAccount: dto.isSameBankAccount,
			isInternationalAccount: dto.isInternationalAccount,
			isVerified: dto.isVerified,
			nickName: dto.nickName,
			swiftCode: dto.swiftCode,
			approvalDate: dto.approvalDate
		};
	}

	static toApprovalRequestActionEntity(dto: ApprovalRequestActionResponseDTO): ApprovalRequestAction {
		return {
			id: dto.requestId,
			transactionId: dto.transactionId,
			status: dto.status
		};
	}
  
}
  