import { Injectable } from '@angular/core';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { Observable, throwError } from 'rxjs';
import {
	AccountDetailsResponseDTO,
	AccountResponseDTO,
	GenerateAccountStatementRequestDTO,
	GenerateReleveRibRequestDTO,
	GenerateReleveRibResponseDTO,
} from '../../dtos/account/account.dto';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import { catchError, map } from 'rxjs/operators';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { saveAs } from 'file-saver';
import {
	AccountException,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService,
		private readonly translate: TranslateService
	) {}


	getAccounts(): Observable<AccountResponseDTO> {
		return this.api
			.post<AccountResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.ACCOUNT.GET_ACCOUNTS
			)
			.pipe(
				map((response) => {
					return response;
				}),
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_ACCOUNTS_FAILED',
						'Failed to get accounts'
					)
				)
			);
	}
	getDetails(accountID: string): Observable<AccountDetailsResponseDTO> {
		return this.api
			.post<AccountDetailsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.ACCOUNT.GET_DETAILS,
				{ accountID }
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_ACCOUNTS_FAILED',
						'Failed to get accounts'
					)
				)
			);
	}
	generateReleveRib(
		data: GenerateReleveRibRequestDTO
	): Observable<GenerateReleveRibResponseDTO> {
		return this.api
			.post<GenerateReleveRibResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.ACCOUNT.GENERATE_RELEVE_RIB,
				data
			)
			.pipe(
				map((response) => {
					const accountFileDetails = response?.Account?.[0];
					if (
						!accountFileDetails.dbpErrMsg &&
						!accountFileDetails.dbpErrCode
					) {
						return response;
					}

					const errorMessage = accountFileDetails?.dbpErrMsg;
					if (
						accountFileDetails?.dbpErrCode &&
						accountFileDetails?.dbpErrMsg
					) {
						this.handleSpecificErrors(
							accountFileDetails?.dbpErrCode,
							accountFileDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof AccountException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'GENERATE_RELEVE_RIB',
								'Failed to generate releve rib'
						  )
				)
			);
	}

	generateReleveRibAccount(data: {
		fileId: string;
		fileName: string;
	}): Observable<any> {
		const { fileId, fileName } = data;
		return this.api
			.generateFile(
				`${CORE_BANKING_API_ENDPOINTS.ACCOUNT.GENERATE_RELEVE_RIB_ACCOUNT}`,
				{
					fileId,
					fileName,
				},
				{ 'Content-Type': 'application/pdf' }
			)
			.pipe(
				map((response) => {
					if (response instanceof Blob) {
						const blob = response;
						if (blob.type === 'application/pdf') {
							saveAs(blob, fileName);
							return 'success';
						} else {
							this.handleSpecificErrors(
								'19100',
								"La réponse n'est pas un fichier PDF valide"
							);
							throw new Error(
								"La réponse n'est pas un fichier PDF valide"
							);
						}
					} else {
						this.handleSpecificErrors(
							'19100',
							"La réponse n'est pas un fichier PDF valide"
						);
						throw new Error("La réponse n'est pas un Blob");
					}
				}),
				catchError((error) =>
					error instanceof AccountException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'GENERATE_RELEVE_RIB_ACCOUNT',
								'Failed to generate releve rib account'
						  )
				)
			);
	}

	generateAccountStatement(
		data: GenerateAccountStatementRequestDTO
	): Observable<GenerateReleveRibResponseDTO> {
		return this.api
			.post<GenerateReleveRibResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.ACCOUNT.GENERATE_ACCOUNT_STATEMENT,
				data
			)
			.pipe(
				map((response) => {
					const accountFileDetails = response?.Account?.[0];
					if (
						!accountFileDetails.dbpErrMsg &&
						!accountFileDetails.dbpErrCode
					) {
						return response;
					}

					const errorMessage = accountFileDetails?.dbpErrMsg;
					if (
						accountFileDetails?.dbpErrCode &&
						accountFileDetails?.dbpErrMsg
					) {
						this.handleSpecificErrors(
							accountFileDetails?.dbpErrCode,
							accountFileDetails?.dbpErrMsg
						);
					}
					throw new Error(errorMessage ?? 'Transfer failed');
				}),
				catchError((error) =>
					error instanceof AccountException
						? throwError(() => error)
						: this.errorHandler.handleError(
								error,
								'GENERATE_ACCOUNT_STATEMENT',
								'Failed to generate account statement'
						  )
				)
			);
	}

	private handleSpecificErrors(status: string, errorMessage: string): void {
		switch (status) {
			case '12001':
				throw new UnauthorizedException();
			case '19100':
			case '19102':
				throw new AccountException(
					this.translate.instant('TRANSFER.ERRORS.INTERNAL_SERVICE_ERROR')
				);
			default:
				throw new Error(errorMessage);
		}
	}
}
