import { Transfer } from '../../domain/entities/transfer.entity';
import { createReducer, on } from '@ngrx/store';
import {
	bankDateAction,
	bankDateFailureAction,
	bankDateSuccessAction,
	loadUemoaBanksAction,
	loadUemoaBanksFailureAction,
	loadUemoaBanksSuccessAction,
	transferHistoryAction,
	transferHistoryFailureAction,
	transferHistorySuccessAction,
	validateTransferByOtpAction,
	validateTransferByOtpFailureAction,
	validateTransferByOtpSuccessAction,
	transferToAnotherAccountAction,
	transferToAnotherAccountSuccessAction,
	transferToAnotherAccountFailureAction,
	transferToIntraAccountAction,
	transferToIntraAccountSuccessAction,
	transferToIntraAccountFailureAction,
	rtgsTransferAction,
	rtgsTransferSuccessAction,
	rtgsTransferFailureAction,
	transferSicaToExternalAccountSuccessAction,
	transferSicaToExternalAccountAction,
	transferSicaToExternalAccountFailureAction,
	transferToInternationalAccountAction,
	transferToInternationalAccountSuccessAction,
	transferToInternationalAccountFailureAction,
	transferToAnotherAccountStandingOrderAction,
	transferToAnotherAccountStandingOrderFailureAction,
	transferToAnotherAccountStandingOrderSuccessAction,
	transferToIntraAccountStandingOrderAction,
	transferToIntraAccountStandingOrderFailureAction,
	transferToIntraAccountStandingOrderSuccessAction,
	transferToInternationalAccountStandingOrderAction,
	transferToInternationalAccountStandingOrderFailureAction,
	transferToInternationalAccountStandingOrderSuccessAction,
	transferSicaToExternalAccountStandingOrderAction,
	transferSicaToExternalAccountStandingOrderSuccessAction,
	transferSicaToExternalAccountStandingOrderFailureAction,
	transferInstructionsAction,
	transferInstructionsSuccessAction,
	transferInstructionsFailureAction,
	internationalFundTransferDeleteAction,
	internationalFundTransferDeleteSuccessAction,
	internationalFundTransferDeleteFailureAction,
	interBankFundTransferDeleteAction,
	interBankFundTransferDeleteSuccessAction,
	interBankFundTransferDeleteFailureAction,
	intraBankAccFundTransferDeleteSuccessAction,
	intraBankAccFundTransferDeleteFailureAction,
	intraBankAccFundTransferDeleteAction,
	transferToOwnAccountsDeleteAction,
	transferToOwnAccountsDeleteSuccessAction,
	transferToOwnAccountsDeleteFailureAction,
	fetchBaseCurrencyAction,
	fetchBaseCurrencySuccessAction,
	fetchBaseCurrencyFailureAction,
	fetchDashboardCurrencyListAction,
	fetchDashboardCurrencyListSuccessAction,
	fetchDashboardCurrencyListFailureAction,
	fetchCurrencyRatesAction,
	fetchCurrencyRatesSuccessAction,
	fetchCurrencyRatesFailureAction,
} from '../actions/transfer.actions';
import { BankDate } from '../../domain/entities/bank-date.entity';
import { UemoaBank } from '../../domain/entities/uemoa-bank.entity';
import { TransferHistory } from '../../domain/entities/transfer-history.entity';
import { TransferInstructions } from '../../domain/entities/transfer-instructions.entity';
import {
	TransferDeleteResponseDTO,
	BaseCurrencyResponseDTO,
	DashboardCurrencyListResponseDTO,
	CurrencyRatesResponseDTO,
} from '../../application/dtos/transfer.dto';

export enum SubmissionStatus {
	idle = 'idle',
	loading = 'loading',
	success = 'success',
	error = 'error',
}

export interface TransferState {
	transfers: Transfer[];
	transferSelected: Transfer | null;
	loading: boolean;
	error: string | null;
	bankDate: BankDate[];
	uemoaBanks: UemoaBank[];
	transfersHistory: TransferHistory[];
	transferInstructions: TransferInstructions[];
	submissionStatus: SubmissionStatus;
	response: any;
	transferDeleted: TransferDeleteResponseDTO | null;
	baseCurrency: BaseCurrencyResponseDTO | null;
	currencyList: DashboardCurrencyListResponseDTO | null;
	currencyRates: CurrencyRatesResponseDTO | null;
}

export const initialState: TransferState = {
	transfers: [],
	transferSelected: null,
	loading: false,
	error: null,
	bankDate: [],
	uemoaBanks: [],
	transfersHistory: [],
	transferInstructions: [],
	submissionStatus: SubmissionStatus.idle,
	response: null,
	transferDeleted: null,
	baseCurrency: null,
	currencyList: null,
	currencyRates: null,
};

export const transferReducer = createReducer(
	initialState,

	on(transferHistoryAction, (state) => ({
		...state,
		loading: true,
	})),
	on(fetchBaseCurrencyAction, (state) => ({
		...state,
		baseCurrency: null,
		loading: true,
	})),
	on(fetchDashboardCurrencyListAction, (state) => ({
		...state,
		currencyList: null,
		loading: true,
	})),
	on(fetchCurrencyRatesAction, (state) => ({
		...state,
		currencyRates: null,
		loading: true,
	})),
	on(transferHistorySuccessAction, (state, { transfers }) => ({
		...state,
		transfersHistory: transfers,
		loading: false,
		error: null,
	})),
	on(transferHistoryFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(validateTransferByOtpAction, (state) => ({ ...state, loading: true })),
	on(validateTransferByOtpSuccessAction, (state, { success }) => ({
		...state,
		loading: false,
		error: null,
	})),
	on(validateTransferByOtpFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(bankDateAction, (state) => ({ ...state, loading: true })),
	on(bankDateSuccessAction, (state, { bankDate }) => ({
		...state,
		bankDate,
		submissionStatus: SubmissionStatus.success,
		loading: false,
		error: null,
	})),
	on(bankDateFailureAction, (state, { error }) => ({
		...state,
		submissionStatus: SubmissionStatus.error,
		error,
		loading: false,
	})),

	on(loadUemoaBanksAction, (state) => ({ ...state, loading: true })),
	on(loadUemoaBanksSuccessAction, (state, { uemoaBanks }) => ({
		...state,
		uemoaBanks,
		loading: false,
		error: null,
	})),
	on(loadUemoaBanksFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(transferSicaToExternalAccountAction, (state) => ({
		...state,
		loading: true,
	})),
	on(transferSicaToExternalAccountSuccessAction, (state, response) => ({
		...state,
		response: response.transfer,
		loading: false,
		error: null,
	})),
	on(transferSicaToExternalAccountFailureAction, (state, { error }) => ({
		...state,
		loading: false,
		error: error,
	})),

	on(transferToIntraAccountAction, (state) => ({
		...state,
		loading: true,
	})),
	on(transferToIntraAccountSuccessAction, (state, { transfer }) => ({
		...state,
		response: transfer,
		loading: false,
		error: null,
	})),
	on(transferToIntraAccountFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(transferToAnotherAccountAction, (state) => ({
		...state,
		loading: true,
	})),
	on(transferToAnotherAccountSuccessAction, (state, { transfer }) => ({
		...state,
		response: transfer,
		loading: false,
		error: null,
	})),
	on(transferToAnotherAccountFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(rtgsTransferAction, (state) => ({
		...state,
		loading: true,
	})),
	on(rtgsTransferSuccessAction, (state, { transfer }) => ({
		...state,
		response: transfer,
		loading: false,
		error: null,
	})),
	on(rtgsTransferFailureAction, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(transferToInternationalAccountAction, (state) => ({
		...state,
		loading: true,
	})),
	on(transferToInternationalAccountSuccessAction, (state, { transfer }) => ({
		...state,
		response: transfer,
		loading: false,
		error: null,
	})),
	on(transferToInternationalAccountFailureAction, (state, { error }) => ({
		...state,
		loading: false,
		error: error,
	})),

	on(transferToAnotherAccountStandingOrderAction, (state) => ({
		...state,
		loading: true,
	})),
	on(
		transferToAnotherAccountStandingOrderSuccessAction,
		(state, { transfer }) => ({
			...state,
			response: transfer,
			loading: false,
			error: null,
		})
	),
	on(
		transferToAnotherAccountStandingOrderFailureAction,
		(state, { error }) => ({
			...state,
			loading: false,
			error: error,
		})
	),

	on(transferToIntraAccountStandingOrderAction, (state) => ({
		...state,
		loading: true,
	})),
	on(
		transferToIntraAccountStandingOrderSuccessAction,
		(state, { transfer }) => ({
			...state,
			response: transfer,
			loading: false,
			error: null,
		})
	),
	on(transferToIntraAccountStandingOrderFailureAction, (state, { error }) => ({
		...state,
		loading: false,
		error: error,
	})),

	on(transferToInternationalAccountStandingOrderAction, (state) => ({
		...state,
		loading: true,
	})),
	on(
		transferToInternationalAccountStandingOrderSuccessAction,
		(state, { transfer }) => ({
			...state,
			response: transfer,
			loading: false,
			error: null,
		})
	),
	on(
		transferToInternationalAccountStandingOrderFailureAction,
		(state, { error }) => ({
			...state,
			loading: false,
			error: error,
		})
	),

	on(transferSicaToExternalAccountStandingOrderAction, (state) => ({
		...state,
		loading: true,
	})),
	on(
		transferSicaToExternalAccountStandingOrderSuccessAction,
		(state, { transfer }) => ({
			...state,
			response: transfer,
			loading: false,
			error: null,
		})
	),
	on(
		transferSicaToExternalAccountStandingOrderFailureAction,
		(state, { error }) => ({
			...state,
			loading: false,
			error: error,
		})
	),

	on(transferInstructionsAction, (state) => ({
		...state,
		loading: true,
	})),
	on(transferInstructionsSuccessAction, (state, { transfers }) => ({
		...state,
		transferInstructions: transfers,
		loading: false,
		error: null,
	})),
	on(transferInstructionsFailureAction, (state, { error }) => ({
		...state,
		loading: false,
		error: error,
	})),
	on(
		internationalFundTransferDeleteSuccessAction,
		interBankFundTransferDeleteSuccessAction,
		intraBankAccFundTransferDeleteSuccessAction,
		transferToOwnAccountsDeleteSuccessAction,
		(state, { transfer }) => ({
			...state,
			transferDeleted: transfer,
			loading: false,
			error: null,
		})
	),
	on(
		transferInstructionsFailureAction,
		internationalFundTransferDeleteFailureAction,
		interBankFundTransferDeleteFailureAction,
		intraBankAccFundTransferDeleteFailureAction,
		transferToOwnAccountsDeleteFailureAction,
		fetchBaseCurrencyFailureAction,
		fetchDashboardCurrencyListFailureAction,
		fetchCurrencyRatesFailureAction,
		(state, { error }) => ({
			...state,
			loading: false,
			error,
		})
	),
	on(
		internationalFundTransferDeleteAction,
		interBankFundTransferDeleteAction,
		intraBankAccFundTransferDeleteAction,
		transferToOwnAccountsDeleteAction,
		(state) => ({
			...state,
			loading: true,
			transferDeleted: null,
		})
	),
	on(fetchBaseCurrencySuccessAction, (state, { response }) => ({
		...state,
		baseCurrency: response,
		loading: false,
		error: null,
	})),
	on(fetchDashboardCurrencyListSuccessAction, (state, { response }) => ({
		...state,
		currencyList: response,
		loading: false,
		error: null,
	})),
	on(fetchCurrencyRatesSuccessAction, (state, { response }) => ({
		...state,
		currencyRates: response,
		loading: false,
		error: null,
	}))
);
