<div class="relative bg-white p-8 rounded-lg shadow-md w-full">
	<img
		class="absolute right-0 bottom-0 w-[30%] h-[50%] lg:w-[50%] lg:h-[80%] z-0"
		src="assets/images/Maskgroup.svg"
		alt=""
	/>
	<img
		class="absolute right-0 bottom-0 w-[30%] h-[50%] lg:w-[50%] lg:h-[80%]"
		src="assets/images/PHONES.svg"
		alt=""
	/>
	<div class="relative z-20 mb-8">
		<div class="flex items-center mb-4">
			<img
				src="assets/images/210719_BRIDGE_BANK_LOGO_RGB 2.svg"
				alt="Bridge Bank Logo"
				class="h-10"
			/>
		</div>
		<p class="text-black font-bold text-xl mb-2 w-full md:w-[50%] lg:w-[50%]">
			Bridge Bank Group Côte d'Ivoire Mobile Banking
		</p>
		<p class="text-gray-600 text-sm mb-4 w-full md:w-[50%] lg:w-[50%]">
			{{ 'MORE_INFORMATION.INFORMATION' | translate }}
		</p>
		<a
			href="#"
			class="text-primary font-semibold inline-flex items-center pb-8"
		>
			{{ 'MORE_INFORMATION.LEARN_MORE' | translate }}
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				class="w-4 h-4 ml-1"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17.25 6.75L21 12m0 0l-3.75 5.25M21 12H3"
				/>
			</svg>
		</a>
	</div>
</div>
