import {
	Component,
	Input,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
	selector: 'app-textarea',
	standalone: true,
	imports: [NgClass, NgIf],
	templateUrl: './textarea.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextareaComponent,
			multi: true,
		},
	],
})
export class TextareaComponent
	implements ControlValueAccessor, AfterViewInit, OnDestroy
{
	@ViewChild('textareaElement') textareaElement!: ElementRef;

	@Input() id: string = '';
	@Input() name: string = '';
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() rows: number = 3;
	@Input() maxLength: number | null = null;
	@Input() disabled: boolean = false;
	@Input() autoResize: boolean = false;
	@Input() variant: 'outlined' | 'filled' = 'outlined';
	@Input() error: string | null = null;
	@Input() showCharCount: boolean = false;

	@Input() readonly: boolean = false;
	@Input() isRequired: boolean = false;

	@Output() resize: EventEmitter<void> = new EventEmitter<void>();

	value: string = '';
	private onChange: (value: string) => void = () => {};
	private onTouched: () => void = () => {};

	ngAfterViewInit() {
		if (this.autoResize) {
			this.adjustTextareaHeight();
		}
	}

	onInput(event: Event) {
		const textarea = event.target as HTMLTextAreaElement;
		this.value = textarea.value;
		this.value = textarea.value.trim() === '' ? '' : textarea.value;
		this.onChange(this.value);

		if (this.autoResize) {
			this.adjustTextareaHeight();
		}
	}

	onBlur() {
		this.onTouched();
	}

	writeValue(value: string): void {
		this.value = value;
		if (this.autoResize) {
			setTimeout(() => this.adjustTextareaHeight());
		}
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	private adjustTextareaHeight() {
		const textarea = this.textareaElement.nativeElement;
		textarea.style.height = 'auto';
		textarea.style.height = `${textarea.scrollHeight}px`;
		this.resize.emit();
	}

	ngOnDestroy() {
		// Cleanup if needed
	}
}
