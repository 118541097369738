export class TermsAndCondition {
	constructor(
		public companyLegalUnit: string,
		public versionId: string,
		public appId: string,
		public channel: string,
		public customerId: string,
		public termsAndConditionsCode: string,
		public id: string,
		public languageCode: string
	) {}
}