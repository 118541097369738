import { Injectable } from '@angular/core';
import { IHubPaymentService } from '../../domain/interfaces/hub-payment-service.interface';
import { catchError, map, Observable } from 'rxjs';
import { ThirdPartyFieldResponseDTO } from '../dtos/third-party-field.dto';
import { ThirdPartyServiceResponseDTO } from '../dtos/thrid-party-service.dto';
import { HubPaymentApiService } from '../../infrastructure/api/hub-payment-api.service';
import { ThirdPartyRequestDTO } from '../dtos/third-party-request.dto';
import { ThirdPartyResponseDTO } from '../dtos/third-party.dto';
import { Logger } from '../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class HubPaymentService implements IHubPaymentService {
	constructor(private readonly api: HubPaymentApiService) {}

	getThirdParties(): Observable<ThirdPartyResponseDTO> {
		return this.api.get<ThirdPartyResponseDTO>('/getBBGthirdParties').pipe(
			map((response) => {
				Logger.info('getBBGthirdParties : ', response);
				return response;
			}),
			catchError((error) => {
				console.error(error);
				throw error;
			})
		);
	}

	getThirdPartiesServices(
		entityId: number
	): Observable<ThirdPartyServiceResponseDTO> {
		return this.api
			.get<ThirdPartyServiceResponseDTO>(
				`/getBBGthirdPartiesServices/${entityId}`
			)
			.pipe(
				map((response) => {
					Logger.info('getBBGthirdPartiesServices : ', response);
					return response;
				}),
				catchError((error) => {
					console.error(error);
					throw error;
				})
			);
	}

	getBillDetailsBeforePay(
		request: ThirdPartyRequestDTO
	): Observable<ThirdPartyFieldResponseDTO> {
		const { serviceId, stepNum, dataRequest } = request;
		return this.api
			.post<ThirdPartyFieldResponseDTO>(
				`/getBillDetailsBeforePay/${serviceId}/${stepNum}/?`,
				dataRequest
			)
			.pipe(
				map((response) => {
					Logger.info('getBillDetailsBeforePay : ', response);
					return response;
				}),
				catchError((error) => {
					console.error(error);
					throw error;
				})
			);
	}

	setPaiementThirdParties(
		request: ThirdPartyRequestDTO
	): Observable<ThirdPartyResponseDTO> {
		const { entityId, serviceId, dataRequest } = request;

		return this.api
			.post<ThirdPartyResponseDTO>(
				`/setPaiementThirdParties/${entityId}/${serviceId}`,
				dataRequest
			)
			.pipe(
				map((response) => {
					Logger.info('setPaiementThirdParties: ', response);
					return response;
				}),
				catchError((error) => {
					console.error(error);
					throw error;
				})
			);
	}

	getThirdPartiesFields(
		entityId: number,
		entityIdService: number
	): Observable<ThirdPartyFieldResponseDTO> {
		return this.api
			.get<ThirdPartyFieldResponseDTO>(
				`/getBBGthirdPartiesFields/${entityId}/${entityIdService}`
			)
			.pipe(
				map((response) => {
					Logger.info('getBBGthirdPartiesFields : ', response);
					return response;
				}),
				catchError((error) => {
					console.error(error);
					throw error;
				})
			);
	}
}
