import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { AuthSession } from '../../domain/entities/auth-session.entity';

@Injectable({
	providedIn: 'root',
})
export class LoginUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(credentials: {
		userid: string;
		password: string;
	}): Observable<AuthSession> {
		return this.authRepository.login(credentials);
	}
}
