import { createReducer, on } from "@ngrx/store";
import { Partner } from "../../domain/entities/partner.entity";
import * as BillActions from "../actions/bill.actions"
import { PartnerService } from "../../domain/entities/partner-service.entity";
import { PartnerServiceField } from "../../domain/entities/partner-service-field.entity";

export interface BillState {
    partners: Partner[];
    partnerServices: PartnerService[];
    partnerServiceFields: PartnerServiceField[],
    loading: boolean;
    error: any;
    response: any;
}

export const initialState: BillState = {
    partners: [],
    partnerServices: [],
    partnerServiceFields: [],
    loading: false,
    error: null,
    response: null
};

export const billReducer = createReducer(
    initialState,

    on(BillActions.loadPartners, (state) => ({
        ...state,
        loading: true,
    })),
    on(BillActions.loadPartnersSuccess,
    (state, { partners }) => ({
        ...state,
        partners,
        loading: false,
        error: null,
    })
    ),
    on(BillActions.loadPartnersFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),

    on(BillActions.loadServicesByPartnerId, (state) => ({
        ...state,
        loading: true,
    })),
    on(BillActions.loadServicesByPartnerIdSuccess,
    (state, { partnerServices }) => ({
        ...state,
        partnerServices,
        loading: false,
        error: null,
    })
    ),
    on(BillActions.loadServicesByPartnerIdFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
   
    on(BillActions.initiatePartnerService, (state) => ({
        ...state,
        loading: true,
    })),
    on(BillActions.initiatePartnerServiceSuccess,
    (state, { partnerServiceFields }) => ({
        ...state,
        partnerServiceFields,
        loading: false,
        error: null,
    })
    ),
    on(BillActions.initiatePartnerServiceFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
)  