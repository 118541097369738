import { createReducer, on } from '@ngrx/store';
import * as termsConditionActions from '../actions/terms-condition.actions';
import { TermsAndCondition } from '../../domain/entities/terms-and-condition.entity';

export interface TermsConditionState {
	dbpErrMsg: string | null;
	dbpErrCode: number | null;
	customertermsandconditions: TermsAndCondition[];
	alreadySigned: string | null;
	loading: boolean;
	error: any;
}

export const initialState: TermsConditionState = {
	dbpErrCode: null,
	customertermsandconditions: [],
	alreadySigned: null,
	dbpErrMsg: null,
	loading: false,
	error: null,
};

export const termsConditionReducer = createReducer(
	initialState,
	on(
		termsConditionActions.createCustomerTNCAction,
		(state: TermsConditionState): TermsConditionState => ({
			...state,
			loading: true,
		})
	),
	on(
		termsConditionActions.createCustomerTNCActionSuccess,
		(state: TermsConditionState, { response }): TermsConditionState => {
			if (Array.isArray(response)) {
				return {
					...state,
					customertermsandconditions: response,
					dbpErrMsg: null,
					dbpErrCode: null,
					alreadySigned: 'User not already signed',
					loading: false,
					error: null,
				};
			} else {
				// Cas où `response` est de type `AlreadySigned`
				const alreadySignedResponse = response;
				return {
					...state,
					dbpErrMsg: alreadySignedResponse.dbpErrMsg,
					dbpErrCode: alreadySignedResponse.dbpErrCode,
					customertermsandconditions: [],
					alreadySigned: 'User already signed',
					loading: false,
					error: null,
				};
			}
		}
	),

	on(
		termsConditionActions.createCustomerTNCActionFailure,
		(state: TermsConditionState, { error }): TermsConditionState => ({
			...state,
			loading: false,
			error: error,
			alreadySigned: 'User not already signed',
		})
	),
	on(
		termsConditionActions.getPostLoginAction,
		(state: TermsConditionState): TermsConditionState => ({
			...state,
			loading: true,
			alreadySigned: 'User not already signed',
		})
	),
	on(
		termsConditionActions.getPostLoginActionSuccess,
		(state: TermsConditionState, { response }): TermsConditionState => ({
			...state,
			alreadySigned: response.alreadySigned=="true"?"true":'User not already signed',
			loading: false,
			error: null,
		})
	),
	on(
		termsConditionActions.getPostLoginActionFailure,
		(state: TermsConditionState, { error }): TermsConditionState => ({
			...state,
			loading: false,
			alreadySigned: 'User not already signed',
			error: error,
		})
	)
);
