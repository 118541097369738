import { Inject, Injectable } from '@angular/core';
import { IDiscussionRepository } from '../../domain/repositories/discussion.repository';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GetUnreadDiscussionCountUseCase {
	constructor(
		@Inject('IDiscussionRepository')
		private readonly discussionRepository: IDiscussionRepository
	) {}

	execute(): Observable<number> {
		return this.discussionRepository.getUnreadDiscussionCount();
	}
}
