export class RtgsTransfer {
	constructor(
		public id: string,
		public transactionReference: string,
		public debitAccount: string,
		public beneficiaryAccount: string,
		public beneficiaryName: string,
		public transactionAmount: string,
		public motifEconomique: string,
		public paymentDetails: string,
		public processingDate: string,
		public debitAmount: string,
		public creditAmount: string,
		public status: string
	) {}
}
