import { createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';
import { UserInfosDTO } from '../application/dtos/user-infos.dto';
import { UpdateUserStatusResponseDTO } from '../application/dtos/security-question.dto';

export interface SettingsState {
	userProfileImage: string;
	userInfos: any;
	user: UserInfosDTO | null;
	update: UpdateUserStatusResponseDTO | null;
	securityQuestions: any;
	mfaAttributes: any;
	isOtpVerified: string;
	isSecurityQuestionsUpdated: boolean;
    isPassordUpdated: boolean;
	success: string;
	loading: boolean;
	error: any;
}

export const initialState: SettingsState = {
	userProfileImage: '',
	userInfos: null,
	user: null,
	update: null,
	securityQuestions: null,
	mfaAttributes: null,
	isOtpVerified: 'false',
	isSecurityQuestionsUpdated: false,
    isPassordUpdated: false,
	success: '',
	loading: false,
	error: null,
};

export const settingsReducer = createReducer(
	initialState,

	on(SettingsActions.getUserInfos, (state) => ({
		...state,
		loading: true,
	})),
	on(SettingsActions.getUserInfosSuccess, (state, { userInfos }) => ({
		...state,
		userInfos,
		loading: false,
		error: null,
	})),
	on(SettingsActions.getUserInfosFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.getUserProfileImage, (state) => ({
		...state,
		loading: true,
	})),
	on(
		SettingsActions.getUserProfileImageSuccess,
		(state, { userProfileImage }) => ({
			...state,
			userProfileImage,
			loading: false,
			error: null,
		})
	),
	on(SettingsActions.getUserProfileImageFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.updateUserProfileImage, (state) => ({
		...state,
		loading: true,
	})),
	on(SettingsActions.updateUserProfileImageSuccess, (state) => ({
		...state,
		success: 'Updated Successfully',
		loading: false,
		error: null,
	})),
	on(SettingsActions.updateUserProfileImageFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.deleteUserProfileImage, (state) => ({
		...state,
		loading: true,
	})),
	on(SettingsActions.deleteUserProfileImageSuccess, (state) => ({
		...state,
		userProfileImage: '',
		success: 'Deleted Successfully',
		loading: false,
		error: null,
	})),
	on(SettingsActions.deleteUserProfileImageFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.getSecurityQuestions, (state) => ({
		...state,
		loading: true,
	})),
	on(
		SettingsActions.getSecurityQuestionsSuccess,
		(state, { securityQuestions }) => ({
			...state,
			securityQuestions,
			loading: false,
			error: null,
		})
	),
	on(SettingsActions.getSecurityQuestionsFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.requestUpdateSecurityQuestionsOTP, (state) => ({
		...state,
		loading: true,
	})),
	on(
		SettingsActions.requestUpdateSecurityQuestionsOTPSuccess,
		(state, { mfaAttributes }) => ({
			...state,
			mfaAttributes,
			loading: false,
			error: null,
		})
	),
	on(
		SettingsActions.requestUpdateSecurityQuestionsOTPFailure,
		(state, { error }) => ({
			...state,
			error,
			loading: false,
		})
	),

	on(SettingsActions.verifyUpdateSecurityQuestionsOTP, (state) => ({
		...state,
		loading: true,
	})),
	on(
		SettingsActions.verifyUpdateSecurityQuestionsOTPSuccess,
		(state, { isOtpVerified }) => ({
			...state,
			isOtpVerified,
			loading: false,
			error: null,
		})
	),
	on(
		SettingsActions.verifyUpdateSecurityQuestionsOTPFailure,
		(state, { error }) => ({
			...state,
			error,
			loading: false,
		})
	),

    on(SettingsActions.updateSecurityQuestions, (state) => ({
        ...state,   
        loading: true,
    })),
    on(SettingsActions.updateSecurityQuestionsSuccess, (state) => ({
        ...state,
        isSecurityQuestionsUpdated: true,
        loading: false,
        error: null,
    })),
    on(SettingsActions.updateSecurityQuestionsFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),

	on(SettingsActions.getUser, (state) => ({
		...state,
		user: null,
		loading: true,
	})),
	on(SettingsActions.getUserSuccess, (state, { user }) => ({
		...state,
		user,
		loading: false,
		error: null,
	})),
	on(SettingsActions.getUserFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.updateUserStatusSuccess, (state, { update }) => ({
		...state,
		update,
		loading: false,
		error: null,
	})),
	on(SettingsActions.updateUserStatusFailure, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),

	on(SettingsActions.updatePassword, (state) => ({
        ...state,
        isPassordUpdated: false,   
        loading: true,
    })),
    on(SettingsActions.updatePasswordSuccess, (state, { reponse }) =>{
        if(reponse?.dbpErrMsg == null){
            return ({ ...state,
                isPassordUpdated: true,
                loading: false,
                error: null,
            })
        }else if(reponse?.dbpErrMsg && reponse.dbpErrCode   == 10145){
            return ({ ...state,
                isPassordUpdated: false,
                loading: false,
                error: "SETTINGS.PASSWORD.ERROR_CODE_10145",
            })
        }else{
            return ({ ...state,
                isPassordUpdated: false,
                loading: false,
                error: reponse.dbpErrMsg,
            })
        }
    }),

    on(SettingsActions.updatePasswordFailure, (state, { error }) => ({
        ...state,
        error,
        isPassordUpdated: false,
        loading: false,
    })),

    on(SettingsActions.clearSettingsError, (state) => ({
        ...state,
        error: null,
        isPassordUpdated: false,
        loading: false,
    })),
)