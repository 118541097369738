import { createReducer, on } from "@ngrx/store";
import { ApprovalRequest, ApprovalRequestAction } from "../../domain/entities/approval-request.entity";
import * as ApprovalRequestActions from "../actions/approval-request.action";

export interface ApprovalRequestState {
    approvals: ApprovalRequest[];
    approvalHistory: ApprovalRequest[];
    requests: ApprovalRequest[];
    requestHistory: ApprovalRequest[];
    actionResponse: ApprovalRequestAction | null;
    loading: boolean;
    error: any;
}

const initialState: ApprovalRequestState = {
    approvals: [],
    approvalHistory: [],
    requests: [],
    requestHistory: [],
    actionResponse: null,
    loading: false,
    error: null,
};

export const approvalRequestReducer = createReducer(
    initialState,

    on(ApprovalRequestActions.getAllPendingApprovalsAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.getAllPendingApprovalsActionSuccess, (state, { approvals }) => ({ ...state, approvals, loading: false, error: null })),
    on(ApprovalRequestActions.getAllPendingApprovalsActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.getAllPendingRequestsAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.getAllPendingRequestsActionSuccess, (state, { requests }) => ({ ...state, requests, loading: false, error: null })),
    on(ApprovalRequestActions.getAllPendingRequestsActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.getApprovalHistoryAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.getApprovalHistoryActionSuccess, (state, { approvalHistory }) => ({ ...state, approvalHistory, loading: false, error: null })),
    on(ApprovalRequestActions.getApprovalHistoryActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.getRequestHistoryAction, state => ({ ...state, loading: true })),    
    on(ApprovalRequestActions.getRequestHistoryActionSuccess, (state, { requestHistory }) => ({ ...state, requestHistory, loading: false, error: null })),
    on(ApprovalRequestActions.getRequestHistoryActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.approveRequestAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.approveRequestActionSuccess, (state, { actionResponse }) => ({ ...state, actionResponse, loading: false, error: null })),
    on(ApprovalRequestActions.approveRequestActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.rejectRequestAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.rejectRequestActionSuccess, (state, { actionResponse }) => ({ ...state, actionResponse, loading: false, error: null })),
    on(ApprovalRequestActions.rejectRequestActionFailure, (state, { error }) => ({ ...state, error, loading: false })),

    on(ApprovalRequestActions.withdrawRequestAction, state => ({ ...state, loading: true })),
    on(ApprovalRequestActions.withdrawRequestActionSuccess, (state, { actionResponse }) => ({ ...state, actionResponse, loading: false, error: null })),
    on(ApprovalRequestActions.withdrawRequestActionFailure, (state, { error }) => ({ ...state, error, loading: false }))
)