import { Observable } from 'rxjs';
import {
	CustomerTNCRequestPDO,
	GetPostLoginRequestPDO,
	GetPostLoginResponsePDO,
} from '../../application/dtos/terms-condition.dto';
import { AlreadySigned } from '../entities/already-signed.entity';
import { TermsAndCondition } from '../entities/terms-and-condition.entity';

export abstract class ITermsConditionRepository {
	abstract getPostLogin(
		request: GetPostLoginRequestPDO
	): Observable<GetPostLoginResponsePDO>;

	abstract createCustomerTNCForLogin(
		request: CustomerTNCRequestPDO
	): Observable<AlreadySigned | TermsAndCondition[]>;
}
