import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TermsConditionState } from '../reducers/terms-condition.reducers';

export const selectTermsConditionState =
	createFeatureSelector<TermsConditionState>('termsConduction');

export const selectAlreadySigned = createSelector(
	selectTermsConditionState,
	(state: TermsConditionState) => state.alreadySigned
);

export const selectTermsConditionLoading = createSelector(
	selectTermsConditionState,
	(state: TermsConditionState) => state.loading
);

export const selectTermsConditionError = createSelector(
	selectTermsConditionState,
	(state: TermsConditionState) => state.error
);

export const selectDbpErrMsgSelected = createSelector(
	selectTermsConditionState,
	(state: TermsConditionState) => state.dbpErrMsg
);
