import {
	Address,
	ContactNumber,
	CoreCustomer,
	EmailId,
	UserInfos,
} from '../../domain/entities/user-infos.entity';
import {
	AddressDTO,
	ContactNumberDTO,
	CoreCustomerDTO,
	EmailIdDTO,
	RecordDTO,
	UserInfosDTO,
} from '../dtos/user-infos.dto';

export class UserInfosMapper {
	static toDTO(userInfos: UserInfos): UserInfosDTO {
		const records: RecordDTO[] = userInfos.Addresses.map((address, index) => {
			return {
				addressLine1: userInfos.addressLine1,
				alertsTurnedOn: userInfos.alertsTurnedOn,
				bankName: userInfos.bankName,
				dateOfBirth: userInfos.dateOfBirth,
				phone: userInfos.phone,
				email: userInfos.email,
				city: userInfos.city,
				zipcode: userInfos.zipcode,
				isPinSet: userInfos.isPinSet,
				lastlogintime: userInfos.lastlogintime,
				userfirstname: userInfos.userfirstname,
				userId: userInfos.userId,
				userlastname: userInfos.userlastname,
				userName: userInfos.userName,
				LastName: userInfos.LastName,
				isWireTransferActivated: userInfos.isWireTransferActivated,
				isWireTransferEligible: userInfos.isWireTransferEligible,
				showBillPayFromAccPopup: userInfos.showBillPayFromAccPopup,
				isP2PActivated: userInfos.isP2PActivated,
				isBillPaySupported: userInfos.isBillPaySupported,
				isBillPayActivated: userInfos.isBillPayActivated,
				feedbackUserId: userInfos.feedbackUserId,
				isActivationLinkSent: userInfos.isActivationLinkSent,
				customerStatus: userInfos.customerStatus,
				isCustomerEnrolled: userInfos.isCustomerEnrolled,
				isAssociated: userInfos.isAssociated,
				legalEntityId: userInfos.legalEntityId,
				Addresses: userInfos.Addresses?.map((address) =>
					this.toDTOAddress(address)
				),
				ContactNumbers: userInfos.ContactNumbers?.map((contact) =>
					this.toDTOContactNumber(contact)
				),
				EmailIds: userInfos.EmailIds?.map((email) =>
					this.toDTOEmailId(email)
				),
				CoreCustomers: userInfos.CoreCustomers?.map((coreCustomer) =>
					this.toDTOCoreCustomer(coreCustomer)
				),
			};
		});

		return {
			records: records,
			opstatus: 0,
			httpStatusCode: 0,
		};
	}

	static toDomain(dto: UserInfosDTO): UserInfos {
		const record = dto.records[0]; // Assuming the first record is the primary one for this example.

		return new UserInfos(
			record.addressLine1,
			record.alertsTurnedOn,
			record.bankName,
			record.dateOfBirth,
			record.phone,
			record.email,
			record.city,
			record.zipcode,
			record.isPinSet,
			record.lastlogintime,
			record.userfirstname,
			record.userId,
			record.userlastname,
			record.userName,
			record.LastName,
			record.isWireTransferActivated,
			record.isWireTransferEligible,
			record.showBillPayFromAccPopup,
			record.isP2PActivated,
			record.isBillPaySupported,
			record.isBillPayActivated,
			record.feedbackUserId,
			record.isActivationLinkSent,
			record.customerStatus,
			record.isCustomerEnrolled,
			record.isAssociated,
			record.legalEntityId,
			record.Addresses?.map((address) => this.toDomainAddress(address)),
			record.ContactNumbers?.map((contact) =>
				this.toDomainContactNumber(contact)
			),
			record.EmailIds?.map((email) => this.toDomainEmailId(email)),
			record.CoreCustomers?.map((coreCustomer) =>
				this.toDomainCoreCustomer(coreCustomer)
			)
		);
	}

	static toDTOAddress(address: Address): AddressDTO {
		return {
			Address_id: address.Address_id,
			AddressLine1: address.AddressLine1,
			ZipCode: address.ZipCode,
			AddressType: address.AddressType,
			CityName: address.CityName,
			City_id: address.City_id,
			isPrimary: address.isPrimary,
		};
	}

	static toDomainAddress(dto: Address): Address {
		return new Address(
			dto.Address_id,
			dto.AddressLine1,
			dto.ZipCode,
			dto.AddressType,
			dto.CityName,
			dto.City_id,
			dto.isPrimary
		);
	}

	static toDTOContactNumber(contactNumber: ContactNumber): ContactNumberDTO {
		return {
			id: contactNumber.id,
			Type_id: contactNumber.Type_id,
			isPrimary: contactNumber.isPrimary,
			Value: contactNumber.Value,
			phoneNumber: contactNumber.phoneNumber,
			Extension: contactNumber.Extension,
		};
	}

	static toDomainContactNumber(dto: ContactNumberDTO): ContactNumber {
		return new ContactNumber(
			dto.id,
			dto.Type_id,
			dto.isPrimary,
			dto.Value,
			dto.phoneNumber,
			dto.Extension
		);
	}

	static toDTOEmailId(emailId: EmailId): EmailIdDTO {
		return {
			id: emailId.id,
			Type_id: emailId.Type_id,
			isPrimary: emailId.isPrimary,
			Value: emailId.Value,
		};
	}

	static toDomainEmailId(dto: EmailIdDTO): EmailId {
		return new EmailId(dto.id, dto.Type_id, dto.isPrimary, dto.Value);
	}

	static toDTOCoreCustomer(coreCustomer: CoreCustomer): CoreCustomerDTO {
		return {
			coreCustomerID: coreCustomer.coreCustomerID,
			coreCustomerName: coreCustomer.coreCustomerName,
			isBusiness: coreCustomer.isBusiness,
			isPrimary: coreCustomer.isPrimary,
			contractId: coreCustomer.contractId,
			contractName: coreCustomer.contractName,
		};
	}

	static toDomainCoreCustomer(dto: CoreCustomerDTO): CoreCustomer {
		return new CoreCustomer(
			dto.coreCustomerID,
			dto.coreCustomerName,
			dto.isBusiness,
			dto.isPrimary,
			dto.contractId,
			dto.contractName
		);
	}
}
