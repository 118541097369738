export class SearchTransaction {
	constructor(
		public id: string,
		public amount: string,
		public description: string,
		public fromAccountBalance: string,
		public scheduledDate: string,
		public transactionDate: string,
		public transactionType: string,
		public transactionCurrency: string,
		public valueDateTime: string,
		public transactionsNotes?: string
	) {}
}
