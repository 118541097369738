<div class="min-h-screen no-overflow flex flex-col bg-gray-100">
	<!-- Header -->
	<app-header (menuClick)="toggleSidebar()"></app-header>

	<div class="flex flex-1">
		<app-sidebar
			[visible]="sidebarVisible"
			(clickOutside)="handleClickOutside()"
		></app-sidebar>
		<!-- Main Content -->
		<main class="w-full">
			@if (this.breadcrumbItems.length > 1) {
			<div
				class="flex flex-row space-x-6 lg:py-8 lg:px-8 lg:px-10 xl:px-28 2xl:px-36"
			>
				<!-- Section gauche -->
				<div class="w-full lg:w-3/4">
					<div
						class="hidden lg:flex bg-white mb-1 py-4 ps-6 xl:ps-16 rounded-t-lg"
					>
						<app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
					</div>
					<div
						class="flex flex-col bg-white p-4 lg:px-6 xl:px-16 xl:py-8 lg:rounded-b-lg"
					>
						<router-outlet></router-outlet>
					</div>
				</div>
				<!-- Section droite : Illustration -->
				<div class="hidden lg:flex lg:w-1/4 w-">
					<div class="flex flex-col w-full gap-3">
						<app-action-card [items]="menuItems"></app-action-card>
						<app-carousel
							[autoPlay]="true"
							[interval]="5000"
							[showArrows]="false"
							[showDots]="true"
						>
						</app-carousel>
						<app-annonce-savoir-plus></app-annonce-savoir-plus>
					</div>
				</div>
			</div>
			}@else {
			<div class="lg:p-20">
				<div class="bg-white shadow-md rounded-lg lg:py-12 lg:px-10">
					<router-outlet></router-outlet>
				</div>
			</div>
			}
		</main>
	</div>
	<!-- Footer -->
	<app-footer></app-footer>
</div>
