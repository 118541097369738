import { Transaction } from '../../domain/entities/transaction.entity';
import { TransactionDTO } from '../dtos/transaction.dto';

export class TransactionMapper {
	static toDomain(dto: TransactionDTO): Transaction {
		return new Transaction(
			dto.Id,
			dto.amount,
			dto.description ?? null,
			dto.frequencyStartDate ?? null,
			dto.frequencyType ?? null,
			dto.fromAccountName ?? null,
			dto.fromAccountNumber,
			dto.scheduledDate,
			dto.toAccountName ?? null,
			dto.toAccountNumber ?? null,
			dto.transactionType,
			dto.payeeCurrency ?? null,
			dto.transactionId,
			dto.postedDate,
			dto.statusDescription ?? null,
			dto.valueDateTime ?? null,
			dto.transactionsNotes,
			dto.transactionDate ?? null,
			dto.transactionCurrency ?? null
		);
	}

	toDTO(transaction: Transaction): TransactionDTO {
		return <TransactionDTO>{
			Id: transaction.id,
			amount: transaction.amount,
			description: transaction.description,
			frequencyStartDate: transaction.frequencyStartDate,
			frequencyType: transaction.frequencyType,
			fromAccountName: transaction.fromAccountName,
			fromAccountNumber: transaction.fromAccountNumber,
			scheduledDate: transaction.scheduledDate,
			toAccountName: transaction.toAccountName,
			toAccountNumber: transaction.toAccountNumber,
			transactionType: transaction.transactionType,
			payeeCurrency: transaction.payeeCurrency,
			postedDate: transaction.postedDate,
			statusDescription: transaction.statusDescription,
			transactionId: transaction.transactionId,
			transactionsNotes: transaction.transactionsNotes,
			valueDateTime: transaction.valueDateTime,
		};
	}
}
