import { createReducer, on } from "@ngrx/store";
import * as CampaignActions from "./campaign.actions";

export interface CampaignState {
    preloginCampaigns: any;
    postLoginCampaigns: any;
    loading: boolean;
    error: any;
}

export const initialState: CampaignState = {    
    preloginCampaigns: [],
    postLoginCampaigns: [],
    loading: false,
    error: null,
};

export const campaignReducer = createReducer(
    initialState,

    on(CampaignActions.getPreLoginCampaignsAction, state => ({ ...state, loading: true })),
    on(CampaignActions.getPreLoginCampaignsActionSuccess, (state, { campaigns }) => ({ ...state, preloginCampaigns: campaigns, loading: false, error: null })),
    on(CampaignActions.getPreLoginCampaignsActionFailure, (state, { error }) => ({ ...state, error, loading: false })),
)