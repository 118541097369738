import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationState } from '../reducers/notification.reducer';

export const selectNotificationState =
	createFeatureSelector<NotificationState>('notification');

export const selectAllNotifications = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.notifications
);

export const selectNotificationList = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.list
);

export const selectNotificationCategories = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.categories
);

export const selectNotificationCounts = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.counts
);

export const selectReadNotification = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.readNotification
);

export const selectNotificationsLoading = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.loading
);

export const selectNotificationsError = createSelector(
	selectNotificationState,
	(state: NotificationState) => state.error
);
