import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IInfoRepository } from '../../domain/repositories/info.repository';
import { Agence } from '../../domain/entities/agence.entity';
import { AgenceRequestDTO } from '../dto/info.dto';

@Injectable({
	providedIn: 'root',
})
export class GetAgenceUseCase {
	constructor(
		@Inject('IInfoRepository')
		private readonly infoRepository: IInfoRepository
	) {}

	execute(data: AgenceRequestDTO): Observable<Agence[]> {
		return this.infoRepository.getAgence(data);
	}
}
