import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingApiService } from '../../../infrastructure/api/core-banking-api.service';
import { ErrorHandlingService } from '../../../infrastructure/services/error-handling.service';
import {
	LoanTransactionResponseDTO,
	TransactionRequestDTO,
	TransactionResponseDTO,
	SearchTransactionsRequestDTO,
	SearchTransactionsResponseDTO,
} from '../../dtos/transaction/transaction.dto';
import { ITransactionService } from '../../../domain/interfaces/transaction-service.interface';
import {
	InvalidParametersException,
	UnauthorizedException,
} from '../../../domain/errors/core-banking-error';
import { CORE_BANKING_API_ENDPOINTS } from '../../../infrastructure/constants/api-endpoints';
import { Logger } from '../../../../../shared/helpers/logger-helper';

@Injectable({
	providedIn: 'root',
})
export class TransactionService implements ITransactionService {
	constructor(
		private readonly api: CoreBankingApiService,
		private readonly errorHandler: ErrorHandlingService
	) {}

	getUserScheduledTransactions(): Observable<TransactionResponseDTO> {
		return this.api
			.post<TransactionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSACTIONS.GET_SCHEDULED_TRANSACTIONS,
				{}
			)
			.pipe(
				catchError((error) =>
					this.errorHandler.handleError(
						error,
						'GET_USER_SCHEDULED_TRANSACTIONS_FAILED',
						'Failed to get user scheduled transactions'
					)
				)
			);
	}

	getRecentTransactions(
		request: TransactionRequestDTO
	): Observable<TransactionResponseDTO> {
		return this.api
			.post<TransactionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSACTIONS.BBG_GET_RECENT_TRANSACTIONS,
				request
			)
			.pipe(
				map((response) => {
					Logger.info('getRecentTransactions response', response);
					if (response.Transactions) {
						return response;
					} else if (response.dbpErrCode && response.dbpErrMsg) {
						console.log(response);
						this.handleSpecificErrors(
							response.dbpErrCode,
							response.dbpErrMsg
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_RECENT_TRANSACTIONS_FAILED',
						'Failed to get recent transactions'
					);
				})
			);
	}

	getLoanTransactions(
		request: TransactionRequestDTO
	): Observable<LoanTransactionResponseDTO> {
		return this.api
			.post<LoanTransactionResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSACTIONS.GET_RECENT_TRANSACTIONS,
				request
			)
			.pipe(
				map((response) => {
					if (response.Transactions) {
						return response;
					} else if (response.dbpErrCode && response.dbpErrMsg) {
						this.handleSpecificErrors(
							response.dbpErrCode,
							response.dbpErrMsg
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_RECENT_TRANSACTIONS_FAILED',
						'Failed to get recent transactions'
					);
				})
			);
	}

	searchTransactions(
		request: SearchTransactionsRequestDTO
	): Observable<SearchTransactionsResponseDTO> {
		return this.api
			.post<SearchTransactionsResponseDTO>(
				CORE_BANKING_API_ENDPOINTS.TRANSACTIONS.BBG_SEARCH_TRANSACTIONS,
				request
			)
			.pipe(
				map((response) => {
					if (response?.Transactions) {
						return response;
					} else if (response.dbpErrCode && response.dbpErrMsg) {
						this.handleSpecificErrors(
							response.dbpErrCode,
							response.dbpErrMsg
						);
					}
					throw new Error('Something went wrong');
				}),
				catchError((error) => {
					if (error instanceof UnauthorizedException) {
						return throwError(() => error);
					}
					if (
						error.httpStatusCode === 400 ||
						error.dbpErrCode === '12001'
					) {
						return throwError(() => new UnauthorizedException());
					}
					return this.errorHandler.handleError(
						error,
						'GET_RECENT_TRANSACTIONS_FAILED',
						'Failed to get recent transactions'
					);
				})
			);
	}

	private handleSpecificErrors(
		errorCode: string,
		errorMessage: string
	): never {
		switch (errorCode) {
			case '20041':
				throw new InvalidParametersException(errorMessage);
			case '10742':
				throw new InvalidParametersException(errorMessage);
			default:
				throw new Error(errorMessage);
		}
	}
}
