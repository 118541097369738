import { TransferStandingOrderDTO } from '../dtos/transfer.dto';
import { TransferStandingOrder } from '../../domain/entities/transfer-standing-order.entity';

export class TransferStandingOrderMapper {
	static toDomain(transfer: TransferStandingOrderDTO): TransferStandingOrder {
		return new TransferStandingOrder(
			transfer.transactionId ?? '',
			transfer.frequency ?? '',
			transfer.startDate ?? '',
			transfer.endDate ?? '',
			transfer.transactionAmount ?? '',
			transfer.paymentDetails ?? '',
			transfer.status ?? ''
		);
	}
}
