export enum BeneficiaryType {
	international = 'international',
	external = 'external',
	internal = 'internal',
}

export interface CifInfo {
	contractId: string;
	coreCustomerId: string;
}

export interface BeneficiaryRequestDTO {
	authToken: string;
	legalEntityId: string;
	beneficiaryType: BeneficiaryType;
	payload: BeneficiaryDTO;
}

export interface BeneficiaryDTO {
	Id: string;
	accountNumber: string;
	IBAN: string;
	beneficiaryName: string;
	bankName: string;
	nickName: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	zipcode: string;
	email: string;
	state: string;
	country: string;
	phoneNumber: string;
	swiftCode: string;
	sameBank: string;
	otherBank: string;
	isVerified: string;
	isSameBankAccount: string;
	isInternationalAccount: string;
	feature: string;
	singleCustomer: string;
	cif: CifInfo[];
	payeeStatus: string;
	payeeId: string;
}

export interface BeneficiaryListResponseDTO {
	ExternalAccounts: BeneficiaryDTO[];
	opstatus?: number;
	httpStatusCode?: number;
}

export interface BeneficiaryResponseDTO {
	Id?: string;
	payeeVerificationName?: string;
	transactionStatus: string;
	referenceId: string;
	isSelfApprovalFlag: string;
	opstatus?: number;
	httpStatusCode?: number;
}

export interface DeleteBeneficiaryRequestDTO {
	payeeId: string;
	accountNumber: string;
	isSameBankAccount: string;
	isInternationalAccount: string;
}

export interface GetBeneficiaryResponseDTO {
	beneficiaryName: string;
	currency: string;
	arrangementId: string;
	lockedAmount: string;
	productGroupId: string;
	language: string;
	categoryName: string;
	productName: string;
	availableBalance: string;
	workingBalance: string;
	customerId: string;
	sms: string;
	openingDate: string;
	IBAN: string;
	clearedBalance: string;
	productId: string;
	sortCode: string;
	accountId: string;
	availableLimit: string;
	availableFunds: string;
	accountOfficer: string;
	onlineActualBalance: string;
	categoryId: string;
	opstatus: number;
	httpStatusCode: number;
	dbpErrMsg?: string;
	dbpErrCode?: string;
}
