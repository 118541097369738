export class CoreBankingError extends Error {
	constructor(
		public code: string,
		message: string,
		public opstatus: number,
		public httpStatusCode: number,
		public originalError?: unknown
	) {
		super(message);
		this.name = 'CoreBankingError';
	}
}

export class UnauthorizedException extends Error {
	constructor() {
		super('Unauthorized');
		this.name = 'UnauthorizedException';
	}
}

export class InvalidParametersException extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'InvalidParametersException';
	}
}

export class InternalServerErrorException extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'InternalServerErrorException';
	}
}

export class BeneficiaryAlreadyExistsException extends Error {
	constructor(message: string) {
		super(message);
		this.name = '';
	}
}

export class TransfersException extends Error {
	constructor(message: string) {
		super(message);
		this.name = '';
	}
}

export class AccountException extends Error {
	constructor(message: string) {
		super(message);
		this.name = '';
	}
}
