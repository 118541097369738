import { DomainError } from '../../../../shared/errors/DomainError';

export class DiscussionError extends DomainError {
	static readonly CREATE_DISCUSSION_ERROR = new DiscussionError(
		'DISCUSSION.ERRORS.CREATE_DISCUSSION_ERROR',
		'An error occurred while creating the message',
		400
	);
	static readonly GET_DISCUSSIONS_ERROR = new DiscussionError(
		'DISCUSSION.ERRORS.GET_DISCUSSIONS_ERROR',
		'An error occurred while fetching messages',
		400
	);
	static readonly GET_CATEGORIES_ERROR = new DiscussionError(
		'DISCUSSION.ERRORS.GET_CATEGORIES_ERROR',
		'An error occurred while fetching message categories',
		400
	);
	static readonly UNKNOWN_ERROR = new DiscussionError(
		'DISCUSSION.ERRORS.UNKNOWN_ERROR',
		'An unknown error occurred',
		500
	);

	constructor(
		code: string,
		message: string,
		status?: number,
		originalError?: any
	) {
		super(code, message, status, originalError);
	}

	static fromError(error: {
		code: string;
		message: string;
		httpStatusCode?: number;
	}): DiscussionError {
		switch (error.code) {
			case 'CREATE_DISCUSSION_ERROR':
				return DiscussionError.CREATE_DISCUSSION_ERROR;
			case 'GET_DISCUSSIONS_ERROR':
				return DiscussionError.GET_DISCUSSIONS_ERROR;
			case 'GET_CATEGORIES_ERROR':
				return DiscussionError.GET_CATEGORIES_ERROR;
			case 'UNKNOWN_ERROR':
				return DiscussionError.UNKNOWN_ERROR;
			default:
				return new DiscussionError(
					'AUTHENTICATION.ERRORS.EXTERNAL_ERROR',
					error.message,
					error.httpStatusCode ?? 500,
					error
				);
		}
	}
}

export class MessageError extends DomainError {
	static readonly UNKNOWN_ERROR = new MessageError(
		'DISCUSSION.ERRORS.UNKNOWN_ERROR',
		'An unknown error occurred',
		500
	);

	constructor(
		code: string,
		message: string,
		status?: number,
		originalError?: any
	) {
		super(code, message, status, originalError);
	}

	static fromError(error: {
		code: string;
		message: string;
		httpStatusCode?: number;
	}): MessageError {
		if (error.code === 'UNKNOWN_ERROR') {
			return MessageError.UNKNOWN_ERROR;
		} else {
			return new MessageError(
				'AUTHENTICATION.ERRORS.EXTERNAL_ERROR',
				error.message,
				error.httpStatusCode ?? 500,
				error
			);
		}
	}
}
