import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PrivacyPolicy } from '../../domain/entities/privacy-policy.entity';

export const privacypolicyActions = createActionGroup({
	source: 'PrivacyPolicy',
	events: {
		'Load PrivacyPolicies': emptyProps(),
		'Load PrivacyPolicy Success': props<{ policies: PrivacyPolicy[] }>(),
		'Load PrivacyPolicy Failure': props<{ error: any }>(),
	},
});
