import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoreBankingError } from '../../../core-banking/domain/errors/core-banking-error';
import { InfoError } from '../../domain/entities/error.entity';
import { adaptCoreBankingError } from '../adapters/error-adapter';
import { IInfoRepository } from '../../domain/repositories/info.repository';
import { ContactUs } from '../../domain/entities/contact-us.entity';
import { InfoMapper } from '../../application/mappers/info.mapper';
import { InfoService } from '../../../core-banking/application/services/info/info.service';
import { Agence } from '../../domain/entities/agence.entity';
import {
	AgenceRequestDTO,
	CreateFeedbackDTO,
	FeedbackResponseDTO,
} from '../../application/dto/info.dto';

@Injectable({
	providedIn: 'root',
})
export class InfoRepositoryImpl implements IInfoRepository {
	constructor(private readonly infoService: InfoService) {}

	getContactUs(): Observable<ContactUs> {
		return this.infoService.getContactUs().pipe(
			map((response) => InfoMapper.toDomain(response)),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => InfoError.fromError(adaptedError));
				}
				return throwError(() => InfoError.UNKNOWN_ERROR);
			})
		);
	}

	getAgence(data: AgenceRequestDTO): Observable<Agence[]> {
		return this.infoService.getAgence(data).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => InfoError.fromError(adaptedError));
				}
				return throwError(() => InfoError.UNKNOWN_ERROR);
			})
		);
	}

	createFeedback(data: CreateFeedbackDTO): Observable<FeedbackResponseDTO[]> {
		return this.infoService.createFeedback(data).pipe(
			map((response) => response),
			catchError((error: unknown) => {
				if (error instanceof CoreBankingError) {
					const adaptedError = adaptCoreBankingError(error);
					return throwError(() => InfoError.fromError(adaptedError));
				}
				return throwError(() => InfoError.UNKNOWN_ERROR);
			})
		);
	}
}
