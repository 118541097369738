import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IAccountRepository } from '../../domain/repositories/account.repository';
import { AccountDetail } from '../../domain/entities/account-details.entity';

@Injectable({
	providedIn: 'root',
})
export class GetAccountDetailsUseCase {
	private readonly accountRepository = inject(IAccountRepository);

	execute(accountID: string): Observable<AccountDetail[]> {
		return this.accountRepository.getDetails(accountID);
	}
}
