import { IBeneficiaryRepository } from '../../domain/repositories/beneficiary.repository';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class GetPayeeNameUseCase {
	constructor(
		@Inject('IBeneficiaryRepository')
		private readonly beneficiaryRepository: IBeneficiaryRepository
	) {}

	execute(accountNumber: string): Observable<string> {
		return this.beneficiaryRepository.getPayeeName(accountNumber);
	}
}
