import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthRepository } from '../../domain/repositories/auth.repository';
import { LegalEntity } from '../../domain/entities/legal-entity.entity';

@Injectable({
	providedIn: 'root',
})
export class GetLegalEntityUseCase {
	constructor(
		@Inject('IAuthRepository')
		private readonly authRepository: IAuthRepository
	) {}

	execute(): Observable<LegalEntity[]> {
		return this.authRepository.getLegalEntity();
	}
}
