import { Component } from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import { InactivityMessageComponent } from "../../shared/components/inactivity-message/inactivity-message.component";

@Component({
	selector: 'app-auth-layout',
	standalone: true,
    imports: [RouterOutlet, RouterLink, InactivityMessageComponent],
	templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent {
	user = { username: 'Kouassi Serge', picture: '' };
	sidebarVisible: boolean = false;

	toggleSidebar() {
		this.sidebarVisible = !this.sidebarVisible;
	}
}
