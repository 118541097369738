export class TransferHistory {
	constructor(
		public id: string,
		public transactionReference: string,
		public debitAccount: string,
		public debitAccountName: string,
		public transactionAmount: string,
		public transactionCurrency: string,
		public executionDate: string,
		public beneficiaryName: string,
		public beneficiaryAccount: string,
		public orderingCustomerId: string,
		public orderingCustomerName: string,
		public paymentTypeId: string,
		public paymentType: string,
		public paymentDetails: string,
		public currentStatus: string
	) {}
}
