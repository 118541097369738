import { TermsAndCondition } from "../../domain/entities/terms-and-condition.entity";
import { TermsAndConditionPDO } from "../dtos/terms-condition.dto";

export class TermsAndConditionMapper {
    static toDomain(response: TermsAndConditionPDO): TermsAndCondition {
        return new TermsAndCondition(
            response.companyLegalUnit,
            response.versionId,
            response.appId,
            response.channel,
            response.customerId,
            response.termsAndConditionsCode,
            response.id,
            response.languageCode
        );
            
            
    }

    toDTO(response: TermsAndCondition): TermsAndConditionPDO {
        return <TermsAndConditionPDO>{
            id : response.id,
            companyLegalUnit: response.companyLegalUnit,
            versionId: response.versionId,
            appId: response.appId,
            channel: response.channel,
            customerId: response.customerId ,
            termsAndConditionsCode: response.termsAndConditionsCode,
            languageCode :response.languageCode
        };
    }
}
