import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-unauthorized',
	standalone: true,
	templateUrl: './page-unauthorized.component.html',
})
export class PageUnauthorizedComponent {
	constructor(private readonly router: Router) {}

	navigateHome(): void {
		this.router.navigate(['/']);
	}
}
