import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { IAccountRepository } from '../../domain/repositories/account.repository';
import {
	AccountGenerate,
	GenerateAccountStatementRequestDTO,
} from '../dtos/account-details.dto';

@Injectable({
	providedIn: 'root',
})
export class GenerateAccountStatementUseCase {
	private readonly accountRepository = inject(IAccountRepository);

	execute(
		data: GenerateAccountStatementRequestDTO
	): Observable<AccountGenerate> {
		return this.accountRepository.generateAccountStatement(data);
	}
}
