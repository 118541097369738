import { inject, Injectable } from '@angular/core';
import { ITransferRepository } from '../../domain/repositories/transfer.repository';
import { TransferStandingOrderRequestDTO } from '../dtos/transfer.dto';

@Injectable({ providedIn: 'root' })
export class TransferToIntraAccountStandingOrderUseCase {
	transferService = inject(ITransferRepository);

	execute(request: TransferStandingOrderRequestDTO) {
		return this.transferService.createIntraBankStandingOrderTransfer(request);
	}
}
