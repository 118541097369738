import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AccountActions } from './account.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { GetAccountUseCase } from '../application/use-cases/get-account.use-case';
import { GetAccountDetailsUseCase } from '../application/use-cases/get-account-details.use-case';
import { GenerateReleveRibUseCase } from '../application/use-cases/generate-releve-rib.use-case';
import { GenerateReleveRibAccountUseCase } from '../application/use-cases/generate-releve-rib-account.use-case';
import { GenerateAccountStatementUseCase } from '../application/use-cases/generate-account-statement.use-case';

@Injectable()
export class AccountEffects {
	loadAccounts = createEffect(() => {
		return this.actions$.pipe(
			ofType(AccountActions.loadAccounts),
			switchMap(() => {
				return this.getAccountUseCase.execute().pipe(
					map((response) =>AccountActions.loadAccountSuccess({ accounts: response })),
					catchError((error) => {
						return of(AccountActions.loadAccountFailure({ error }));
					})
				);
			})
		);
	});

	loadAccountsDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.loadAccountsDetails),
			mergeMap((action) => {
				return this.getAccountDetailsUseCase.execute(action.accountID).pipe(
					map((account) =>
						AccountActions.loadAccountDetailsSuccess({
							account,
						})
					),
					catchError((error) =>
						of(AccountActions.loadAccountDetailsFailure({ error }))
					)
				);
			})
		)
	);
	generateReleveRibAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateReleveRibAccount),
			mergeMap((action) => {
				return this.generateReleveRibAccountUseCase
					.execute({ ...action })
					.pipe(
						map((response) =>
							AccountActions.generateReleveRibAccountSuccess({
								response,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateReleveRibAccountFailure({
									error,
								})
							)
						)
					);
			})
		)
	);

	generateReleveRib$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateReleveRib),
			mergeMap((action) => {
				return this.generateReleveRibUseCase
					.execute({ ...action.data })
					.pipe(
						map((response) =>
							AccountActions.generateReleveRibSuccess({
								fileId: response.fileId,
								fileName: response.fileName,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateReleveRibFailure({
									error,
								})
							)
						)
					);
			})
		)
	);

	generateAccountStatement$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.generateAccountStatement),
			mergeMap((action) => {
				return this.generateAccountStatementUseCase
					.execute({ ...action.data })
					.pipe(
						map((response) =>
							AccountActions.generateAccountStatementSuccess({
								fileId: response.fileId,
								fileName: response.fileName,
							})
						),
						catchError((error) =>
							of(
								AccountActions.generateAccountStatementFailure({
									error,
								})
							)
						)
					);
			})
		)
	);
	constructor(
		private readonly actions$: Actions,
		private readonly getAccountUseCase: GetAccountUseCase,
		private readonly getAccountDetailsUseCase: GetAccountDetailsUseCase,
		private readonly generateReleveRibUseCase: GenerateReleveRibUseCase,
		private readonly generateReleveRibAccountUseCase: GenerateReleveRibAccountUseCase,
		private readonly generateAccountStatementUseCase: GenerateAccountStatementUseCase
	) {}
}
